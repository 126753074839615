<template>
  <div class="topMenu">
    <div class="content">


      <div style="display: flex;align-items: center;">
        <div>
          <img src="https://chat.gdpaimaihui.com/img/Logo.png" class="logo" draggable="false" @click="toHome">
        </div>
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect"
          background-color="#212121" text-color="#fff" active-text-color="#fff">
          <el-menu-item index="1">众筹团购</el-menu-item>
          <el-menu-item index="2">拍卖活动</el-menu-item>
          <el-menu-item index="3">线下沙龙</el-menu-item>
          <el-menu-item index="4">关于我们</el-menu-item>
          <el-menu-item index="5">支持与帮助</el-menu-item>
        </el-menu>
      </div>
      <div class="login" @click="ShowLogin" v-if="userInfo == ''">
        登录/注册
      </div>
      <div v-else>
        <div style="position: relative;">
          <img :src="userInfo.avatar_cdn" class="avatar" @click="MyList">
          <div class="myList" v-if="showMyList">
            <div class="myListItem" @click="toMy">个人中心</div>
            <div class="myListItem" @click="logout">退出登录</div>
          </div>
        </div>
      </div>
    </div>

    <div class="sidebar">
      <div class="center">
        <div class="downAppCode center" v-if="downAppShow">
          敬请期待
        </div>
        <div class="sidebar-item center downApp" @mouseenter="showDown" @mouseleave="hideDown">
          <img :src="downAppIcon" alt="" srcset="">
          <div style="width: 100%;text-align: center;font-size: 10px;margin-top: 8px;" :style="downStyle">下载APP</div>
        </div>
      </div>

      <div class="center">
        <div class="downAppCode center" v-if="chatShow" style="color:#999;">
          <img src="https://chat.gdpaimaihui.com/img/客服二维码.png" style="width:100px;height:100px;">
          <div style="margin-top:3px;">
            18580082693
          </div>
          
        </div>
        
        <div class="sidebar-item center" @mouseenter="showChat" @mouseleave="hideChat">
          <img :src="chatIcon" alt="" srcset="">
          <div style="width: 100%;text-align: center;font-size: 10px;margin-top: 8px;" :style="chatStyle">客服</div>
        </div>
      </div>

      <div class="sidebar-item center" @mouseenter="enterTop" @mouseleave="leaveTop" @click="toTop">
        <img :src="backTopIcon" alt="" srcset="">
        <div style="width: 100%;text-align: center;font-size: 10px;" :style="topStyle">返回顶部</div>
      </div>
    </div>


    <el-dialog :title="DialogTitle" :visible.sync="DialogVisible" width="620px" center @close="closeDialog">
      <!-- 登录 -->
      <div class="DialogContent" v-if="Type == 'login'">
        <div id="loginType" class="loginType center">
          <div class="loginType-list">
            <div class="loginType-item" v-for="(item, index) in loginTypeList" :key="index"
              @click="handleLoginType(index)" :class="{ 'loginType-active': loginTypeCurrent === index }">
              {{ item.name }}
            </div>
          </div>
        </div>

        <div class="inputContent">
          <el-form :model="form" :rules="rules" ref="form" label-width="100px" class="demo-ruleForm">

            <div v-if="loginType == 'code'">
              <el-form-item prop="phone" label-width="0">
                <el-input placeholder="请输入手机号" v-model="form.phone">
                  <template slot="prepend" style="background-color: #fff;">
                    <img src="https://chat.gdpaimaihui.com/img/手机登录图标.png" style="width: 26px;">
                  </template>
                </el-input>
              </el-form-item>

              <el-form-item prop="code" label-width="0">
                <div class="formCode">
                  <div style="width: 70%;">
                    <el-input placeholder="请输入验证码" v-model="form.code"></el-input>
                  </div>

                  <div class="getCode" @click="getCode('login')">
                    {{ getCodeText }}
                  </div>

                </div>

              </el-form-item>

            </div>


            <div v-if="loginType == 'password'">
              <el-form-item prop="phone" label-width="0">
                <el-input placeholder="请输入账号" v-model="form.phone">
                  <template slot="prepend" style="background-color: #fff;">
                    <img src="https://chat.gdpaimaihui.com/img/账号登录图标.png" style="width: 26px;">
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item prop="password" label-width="0">
                <el-input placeholder="请输入密码" v-model="form.password" type="password" show-password>
                  <template slot="prepend" style="background-color: #fff;">
                    <img src="https://chat.gdpaimaihui.com/img/密码图标.png" style="width: 26px;">
                  </template>
                </el-input>
              </el-form-item>
            </div>



          </el-form>
        </div>

        <div class="loginBtn" @click="login">
          登录
        </div>

        <div class="otherBtn">
          <div class="otherBtnItem" @click="forget">忘记密码</div>
          <div class="otherBtnItem" @click="register">免费注册</div>
        </div>



      </div>
      <!-- 忘记密码 -->
      <div class="DialogContent" v-if="Type == 'forget'">
        <el-steps :active="active" finish-status="process" align-center>
          <el-step title="验证手机号"></el-step>
          <el-step title="设置新密码"></el-step>
          <el-step title="完成"></el-step>
        </el-steps>
        <el-form :model="forGetForm" :rules="forGetrules" ref="forGetForm" label-width="100px" class="demo-ruleForm"
          style="margin-top: 60px;">
          <!-- 验证手机号 -->
          <div v-if="forGetType == 'validate'">
            <el-form-item prop="phone" label-width="0">
              <el-input placeholder="请输入手机号" v-model="forGetForm.phone">
                <template slot="prepend" style="background-color: #fff;">
                  <img src="https://chat.gdpaimaihui.com/img/手机登录图标.png" style="width: 26px;">
                </template>
              </el-input>
            </el-form-item>

            <el-form-item prop="code" label-width="0">
              <div class="formCode">
                <div style="width: 70%;">
                  <el-input placeholder="请输入验证码" v-model="forGetForm.code"></el-input>
                </div>

                <div class="getCode" @click="getCode('forget')">
                  {{ getCodeText }}
                </div>

              </div>

            </el-form-item>
            <div class="loginBtn" @click="validateCode('forget')" v-if="forGetForm.code != '' && forGetForm.phone != ''">
              下一步
            </div>
            <div class="loginBtn2" v-else>
              下一步
            </div>
          </div>
          <!-- 设置新密码 -->
          <div v-if="forGetType == 'setNewPassword'">

            <el-form-item prop="password" label-width="0">
              <el-input placeholder="设置新密码，不少于6位" v-model="forGetForm.password" type="password" show-password>
                <template slot="prepend" style="background-color: #fff;">
                  <img src="https://chat.gdpaimaihui.com/img/密码图标.png" style="width: 26px;">
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop="passwordAgain" label-width="0">
              <el-input placeholder="确认密码" v-model="forGetForm.passwordAgain" type="password" show-password>
                <template slot="prepend" style="background-color: #fff;">
                  <img src="https://chat.gdpaimaihui.com/img/密码图标.png" style="width: 26px;">
                </template>
              </el-input>
            </el-form-item>
            <div class="loginBtn" @click="setNewPassword">
              确定
            </div>
          </div>

          <!-- 完成 -->
          <div v-if="forGetType == 'complete'" class="complete">
            <img src="https://chat.gdpaimaihui.com/img/成功.png" class="completeImg">
            <div class="completeSuccess">
              重置密码成功
            </div>
            <div class="completeBack">
              {{ completeTime }}秒后自动返回登录页面
            </div>
          </div>
        </el-form>
      </div>
      <!-- 注册账号 -->
      <div class="DialogContent" v-if="Type == 'register'">
        <el-steps :active="active" finish-status="process" align-center>
          <el-step title="验证手机号"></el-step>
          <el-step title="设置密码"></el-step>
          <el-step title="完成"></el-step>
        </el-steps>
        <el-form :model="registerForm" :rules="registerRules" ref="registerForm" label-width="100px" class="demo-ruleForm"
          style="margin-top: 60px;">
          <!-- 验证手机号 -->
          <div v-if="forGetType == 'validate'">
            <el-form-item prop="phone" label-width="0">
              <el-input placeholder="请输入手机号" v-model="registerForm.phone">
                <template slot="prepend" style="background-color: #fff;">
                  <img src="https://chat.gdpaimaihui.com/img/手机登录图标.png" style="width: 26px;">
                </template>
              </el-input>
            </el-form-item>

            <el-form-item prop="code" label-width="0">
              <div class="formCode">
                <div style="width: 70%;">
                  <el-input placeholder="请输入验证码" v-model="registerForm.code"></el-input>
                </div>

                <div class="getCode" @click="getCode('register')">
                  {{ getCodeText }}
                </div>

              </div>

            </el-form-item>
            <div class="loginBtn" @click="validateCode('register')"
              v-if="registerForm.code != '' && registerForm.phone != ''">
              下一步
            </div>
            <div class="loginBtn2" v-else>
              下一步
            </div>
          </div>
          <!-- 设置密码 -->
          <div v-if="forGetType == 'setNewPassword'">

            <el-form-item prop="password" label-width="0">
              <el-input placeholder="设置密码，不少于6位" v-model="registerForm.password" type="password" show-password>
                <template slot="prepend" style="background-color: #fff;">
                  <img src="https://chat.gdpaimaihui.com/img/密码图标.png" style="width: 26px;">
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop="passwordAgain" label-width="0">
              <el-input placeholder="确认密码" v-model="registerForm.passwordAgain" type="password" show-password>
                <template slot="prepend" style="background-color: #fff;">
                  <img src="https://chat.gdpaimaihui.com/img/密码图标.png" style="width: 26px;">
                </template>
              </el-input>
            </el-form-item>
            <div class="loginBtn" @click="setPassWord">
              确定
            </div>
          </div>

          <!-- 完成 -->
          <div v-if="forGetType == 'complete'" class="complete">
            <img src="https://chat.gdpaimaihui.com/img/成功.png" class="completeImg">
            <div class="completeSuccess">
              注册成功
            </div>
            <div class="completeBack">
              {{ completeTime }}秒后自动返回登录页面
            </div>
          </div>
        </el-form>
      </div>

    </el-dialog>

  </div>
</template>

<script>
import request from "../request/request";
export default {
  data() {
    return {
      userInfo: '',
      activeIndex: '1',
      downAppIcon: 'https://chat.gdpaimaihui.com/img/下载app黑.svg',
      chatIcon: 'https://chat.gdpaimaihui.com/img/客服.svg',
      backTopIcon: 'https://chat.gdpaimaihui.com/img/回到顶部.svg',
      downStyle: {
        'color': "#000"
      },
      chatStyle: {
        'color': "#000"
      },
      topStyle: {
        'color': "#000"
      },
      downAppShow: false,
      chatShow: false,
      DialogVisible: false,
      DialogTitle: '欢迎登录拍麦惠',
      loginTypeList: [{
        name: '验证码登录'
      }, {
        name: '账号密码登录'
      }],
      loginTypeCurrent: 0,
      loginType: 'code',
      forGetType: 'validate',
      Type: 'login',
      // 登录表单
      form: {
        phone: '',
        code: '',
        password: ''
      },
      // 忘记密码表单
      forGetForm: {
        phone: '',
        code: '',
        password: '',
        passwordAgain: '',
      },
      // 注册账号表单
      registerForm: {
        phone: '',
        code: '',
        password: '',
        passwordAgain: '',
      },
      getCodeText: '获取验证码',
      time: 0,
      rules: {
        phone: [
          { required: true, message: '请输入账号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ]
      },
      forGetrules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { min: 6, max: 10, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        passwordAgain: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
      },
      registerRules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 10, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        passwordAgain: [
          { required: true, message: '请确认密码', trigger: 'blur' }
        ],
      },
      active: 0,
      completeTime: 3,
      showMyList: false,
      times:''
    }
  },
  created() {
    let userInfo = localStorage.getItem('userInfo')
    if (userInfo) {
      this.userInfo = JSON.parse(userInfo)
      console.log('有数据')
    }
    console.log('userInfo', this.userInfo)
  },
  methods: {
    // 数据初始化
    dataInit() {
      this.time = 0
      clearInterval(this.times)
      this.form = {
        phone: '',
        code: '',
        password: ''
      }
      this.forGetForm = {
        phone: '',
        code: '',
        password: '',
        passwordAgain: '',
      }
      this.registerForm = {
        phone: '',
        code: '',
        password: '',
        passwordAgain: '',
      }
      this.loginType = 'code'
      this.forGetType = 'validate'
      this.Type = 'login'
      this.active = 0
      this.completeTime = 3
      this.DialogTitle = '欢迎登录拍麦惠'
      this.getCodeText = '获取验证码'
      this.loginTypeCurrent = 0
    },
    handleSelect(key, keyPath) {
      console.log('key', key)
      console.log('path', keyPath)
      if (key == 1) {
        this.$router.push({ path: '/' })
      } else if (key == 2) {
        this.$router.push({ path: 'Auction' })
      } else if(key==3){
        this.$router.push({ path: 'privacyPolicy' })
      }
    },
    toHome() {
      this.activeIndex = '1'
      this.$router.push({ path: '/' })
    },
    showDown() {
      this.downAppIcon = 'https://chat.gdpaimaihui.com/img/下载app红.svg'
      this.downStyle = {
        "color": "red"
      }
      this.downAppShow = true
    },
    hideDown() {
      this.downAppIcon = "https://chat.gdpaimaihui.com/img/下载app黑.svg"
      this.downStyle = {
        "color": "#000"
      }
      this.downAppShow = false
    },
    showChat() {
      this.chatIcon = 'https://chat.gdpaimaihui.com/img/客服_1.svg'
      this.chatStyle = {
        "color": "red"
      }
      this.chatShow = true
    },
    hideChat() {
      this.chatIcon = "https://chat.gdpaimaihui.com/img/客服.svg"
      this.chatStyle = {
        "color": "#000"
      }
      this.chatShow = false
    },
    enterTop() {
      this.backTopIcon = "https://chat.gdpaimaihui.com/img/回到顶部_1.svg"
      this.topStyle = {
        "color": "red"
      }
    },
    leaveTop() {
      this.backTopIcon = "https://chat.gdpaimaihui.com/img/回到顶部.svg"
      this.topStyle = {
        "color": "#000"
      }
    },
    toTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
    ShowLogin() {
      this.DialogTitle = '欢迎登录拍麦惠'
      this.Type = 'login'
      this.DialogVisible = true
    },
    handleLoginType(index) {
      this.loginTypeCurrent = index
      if (index == 0) {
        this.loginType = 'code'
      } else if (index == 1) {
        this.loginType = 'password'
      }
    },
    getCode(type) {
      console.log('type', type)
      let phone = '';
      if (type == 'login') {
        if (this.form.phone == '') {
          this.$message({
            message: '请输入手机号！',
            type: 'error',
            offset: '80'
          });
          return
        } else {
          phone = this.form.phone
        }
      }

      if (type == 'forget') {
        if (this.forGetForm.phone == '') {
          this.$message({
            message: '请输入手机号！',
            type: 'error',
            offset: '80'
          });
          return
        } else {
          phone = this.forGetForm.phone
        }
      }

      if (type == 'register') {
        if (this.registerForm.phone == '') {
          this.$message({
            message: '请输入手机号！',
            type: 'error',
            offset: '80'
          });
          return
        } else {
          phone = this.registerForm.phone
        }
      }

      if (this.time == 0) {
        this.time = 60
        this.times = setInterval(() => {
          this.getCodeText = this.time

          if (this.time == 0) {
            this.getCodeText = '重新获取'
            clearInterval(this.times)
            return
          }
          this.time--
        }, 1000)
        // type :login 登录 forget 重置密码 register 注册
        let data = {
          'phone': phone,
          'type': type
        }
        request.post('/api/pc/pc/getCode', data).then(res => {
          console.log('res', res)
        })


      }
    },
    forget() {
      this.dataInit()
      this.DialogTitle = '忘记密码'
      this.Type = 'forget'
    },
    register() {
      this.dataInit()
      this.DialogTitle = '新用户注册'
      this.Type = 'register'
    },
    next() {
      if (this.active == 0) {
        this.active++
        this.forGetType = 'setNewPassword'
      } else if (this.active == 1) {
        this.active++
        this.forGetType = 'complete'
      }
    },
    closeDialog(a) {
      this.DialogVisible = false
      this.dataInit()
    },
    setPassWord() {
      // 设置密码
      if (this.registerForm.password == '' || this.registerForm.passwordAgain == '') {
        this.$message({
          message: '密码不能为空！',
          type: 'error',
          offset: '80'
        });
      } else if (this.registerForm.password != this.registerForm.passwordAgain) {
        this.$message({
          message: '两次密码不一样！',
          type: 'error',
          offset: '80'
        });
      } else {
        let data = this.registerForm
        request.post('/api/pc/pc/register', data).then(res => {
          if (res.code == 1) {
            this.active = 2
            this.forGetType = 'complete'
            this.times = setInterval(() => {
              this.completeTime--
              if (this.completeTime == 0) {
                this.dataInit()
                clearInterval(this.times)
              }
            }, 1000)
          }
        })
      }
    },
    setNewPassword() {
      // 设置新密码
      if (this.forGetForm.password == '' || this.forGetForm.passwordAgain == '') {
        this.$message({
          message: '密码不能为空！',
          type: 'error',
          offset: '80'
        });
      } else if (this.forGetForm.password != this.forGetForm.passwordAgain) {
        this.$message({
          message: '两次密码不一样！',
          type: 'error',
          offset: '80'
        });
      } else {
        let data = this.forGetForm
        request.post('/api/pc/pc/resetPassword', data).then(res => {
          if (res.code == 1) {
            this.active = 2
            this.forGetType = 'complete'
            this.times = setInterval(() => {
              this.completeTime--
              if (this.completeTime == 0) {
                this.dataInit()
                clearInterval(this.times)
              }
            }, 1000)
          }
        })
      }
    },
    validateCode(type) {
      // 验证验证码是否正确
      if (type == 'register') {
        let data = {
          'phone': this.registerForm.phone,
          'code': this.registerForm.code,
          'type': type,
        }
        // 验证注册验证码
        request.post('/api/pc/pc/validateCode', data).then(res => {
          if (res.code == 1) {
            this.active = 1
            this.forGetType = 'setNewPassword'
          } else {
            this.$message({
              message: res.msg,
              type: 'error',
              offset: '80'
            });
          }
        })
      } else if (type == 'forget') {
        // 验证重置密码验证码
        let data = {
          'phone': this.forGetForm.phone,
          'code': this.forGetForm.code,
          'type': type,
        }
        // 验证注册验证码
        request.post('/api/pc/pc/validateCode', data).then(res => {
          if (res.code == 1) {
            this.active = 1
            this.forGetType = 'setNewPassword'
          } else {
            this.$message({
              message: res.msg,
              type: 'error',
              offset: '80'
            });
          }
        })
      }


    },
    login() {
      let data = {
        'phone': this.form.phone,
        'code': this.form.code,
        'password': this.form.password,
        'type': this.loginType,
      }
      request.post('/api/pc/pc/login', data).then(res => {
        console.log('res', res)
        if (res.code == 1) {
          this.$message({
            message: '登录成功！',
            type: 'success',
            offset: '80'
          });
          this.userInfo = res.data
          localStorage.setItem('userInfo', JSON.stringify(res.data))
          this.closeDialog()
        } else {
          this.$message({
            message: res.msg,
            type: 'error',
            offset: '80'
          });
        }
      })
    },
    MyList() {
      this.showMyList = !this.showMyList
    },
    toMy() {
      this.showMyList = false
      this.activeIndex = '0'
      this.$router.push({ path: 'My' })
    },
    logout() {
      console.log('退出登录')

      this.$confirm('是否退出登录？', '提示', {
        confirmButtonText: '退出',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        localStorage.removeItem('userInfo')
        this.userInfo = ''
        this.showMyList = false
        this.$message({
          type: 'success',
          message: '退出成功!'
        });
        this.$router.push({ path: '/' })
      }).catch(() => {
        console.log('取消退出')
      });
    },
    




  }
}
</script>

<style scoped>
/* elementUI */


.el-menu--horizontal>.el-menu-item.is-active {
  background-image: -webkit-linear-gradient(left, #cd9c3f, #ebddc0);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  border-bottom-color: #cd9c3f !important;
}

/* 指针样式 */

.loginType-item,
.login:hover,
.getCode,
.loginBtn,
.otherBtnItem,
.avatar,
.logo,
.myListItem {
  cursor: pointer;
}

.loginBtn2 {
  cursor: not-allowed;
}
</style>

<style>
/* elementUI */
.el-dialog__title {
  font-size: 36px;
  font-family: Source Han Sans SC;
  font-weight: 800;
  color: #333333;
  line-height: 46px;
}

.el-input-group__prepend {
  background-color: #fff;
  border: 1px solid #999999;
  border-right: 0;
}

.el-input__inner {
  border: 1px solid #999999;
  border-color: #999999 !important;
}

.el-menu.el-menu--horizontal {
  border-bottom: none;
}

/* 其他样式 */
.center {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
}

.bannar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding:1px 0 15px 0;
}

.sidebar {
  position: fixed;
  right: 10px;
  bottom: 200px;
  /* border: 1px solid #fff; */
  border-radius: 10px;
}

.sidebar-item {
  width: 70px;
  height: 70px;
  background-color: #f5f5f5;
  border-radius: 10px;
  margin-top: 10px;
  position: relative;

}

.sidebar-item img {
  width: 30px;
}

.downAppCode {
  position: absolute;
  right: 100px;
  width: 130px;
  height: 130px;
  background-color: #f5f5f5;
  border-radius: 10px;
  border: 1px solid #fff;
  
}

.downAppCode::after {
  position: absolute;
  width: 0;
  height: 0;
  right: -10px;
  top: 50px;
  content: '';
  border-left: solid 10px #f5f5f5;
  border-top: solid 10px transparent;
  border-bottom: solid 10px transparent;
}



.logo {
  width: 130px;
  margin-right: 10px;
}

.topMenu {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #212121;
  position: fixed;
  top: 0;
  z-index: 9999;
}

.avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}

.content {
  width: 66%;

  display: flex;
  align-items: center;
  justify-content: space-between;

}

.login {
  font-size: 14px;
  color: #fff;
}





.DialogContent {
  padding: 0 60px 0px 60px;
}

.loginType {
  width: 100%;
  font-size: 14px;

}

.loginType-list {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #fff;
}

.loginType-item {
  width: 50%;
  color: #333333;
  /* padding: 10px 15px; */
  border-bottom: 4px solid #999999;
  text-align: center;
  font-size: 16px;
  font-family: Source Han Sans SC;
  font-weight: 500;
  color: #333333;
  line-height: 64px;
}

.loginType-icon {
  display: flex;
  flex-direction: column;
  margin-left: 5rpx;
}

.loginType-active {
  color: #000;
  border-bottom: 4px solid #000;
}

.inputContent {
  margin-top: 60px;
}

.formCode {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.getCode {
  width: 25%;
  background-color: #999999;
  text-align: center;
  height: 50px;
  line-height: 50px;
  color: #fff;
}

.loginBtn {
  width: 100%;
  background-color: #000;
  color: #fff;
  font-size: 22px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  height: 64px;
  line-height: 64px;
  text-align: center;
  margin-top: 20px;

}

.loginBtn2 {
  width: 100%;
  background-color: #999999;
  color: #fff;
  font-size: 22px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  height: 64px;
  line-height: 64px;
  text-align: center;
  margin-top: 20px;

}

.otherBtn {
  font-size: 18px;
  font-family: Source Han Sans SC;
  font-weight: 500;
  color: #3B66A3;
  line-height: 64px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.otherBtnItem {
  width: 50%;
}

.complete {
  text-align: center;
}

.completeImg {
  width: 100px;
  height: 100px;
}

.completeSuccess {
  font-size: 30px;
  font-family: Source Han Sans SC;
  font-weight: bold;
  color: #333333;
  line-height: 64px;

}

.completeBack {
  font-size: 24px;
  font-family: Source Han Sans SC;
  font-weight: 500;
  color: #333333;
  line-height: 64px;
}


.myList {
  position: absolute;
  width: 100px;
  background-color: #212121;
  color: #fff;
  font-size: 14px;
}

.myListItem {
  width: 100%;
  text-align: center;
  height: 30px;
  line-height: 30px;
}

.myListItem:hover {
  background-color: #fff;
  color: #000;
}

/* 媒体查询 */
@media (max-width:1060px) {

  .el-dropdown-menu__item,
  .el-menu-item {
    font-size: 10px;
    padding: 0 10px;
  }

  .login {
    font-size: 10px;
    color: #fff;
  }

}

@media (max-width:840px) {

  .el-dropdown-menu__item,
  .el-menu-item {
    font-size: 8px;
    padding: 0 5px;
  }

  .login {
    font-size: 8px;
    color: #fff;
  }
}

@media (max-width:746px) {

  .el-dropdown-menu__item,
  .el-menu-item {
    font-size: 6px;
    padding: 0 3px;
  }

  .login {
    font-size: 6px;
    color: #fff;
  }

  .logo {
    width: 80px;
    margin-right: 5px;
  }
}

@media (max-width:640px) {

  .el-dropdown-menu__item,
  .el-menu-item {
    font-size: 6px;
    padding: 0 3px;
  }

  .login {
    font-size: 6px;
    color: #fff;
  }

  .logo {
    width: 80px;
    margin-right: 5px;
  }

  .content {
    width: 80%;

    display: flex;
    align-items: center;
    justify-content: space-between;


  }
}

@media (max-width:530px) {

  .el-dropdown-menu__item,
  .el-menu-item {
    font-size: 6px;
    padding: 0 3px;
  }

  .login {
    font-size: 6px;
    color: #fff;
  }

  .logo {
    width: 80px;
    margin-right: 5px;

  }

  .content {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
