import axios from "axios"
const service = axios.create({
    timeout: 20000, // 超时时间
    baseURL: '' // 接口的baseUrl
})
export function get (url, params) {
    return new Promise((resolve, reject) => {
      service({
        url: url,
        method: 'get',
        params: params
      })
        .then(response => {
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  export function post (url, data) {
    return new Promise((resolve, reject) => {
      service({
        url: url,
        method: 'post',
        data: data
      })
        .then(response => {
          resolve(response.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  export default {
    get,
    post
  }