import Vue from 'vue'
import VueRouter from 'vue-router'
// 导入组件
// 主页
import Home from '@/components/Home'
// 拍卖
import Auction from '@/components/Auction'
// 我的中心
import My from '@/components/My'
import privacyPolicy from '@/components/privacyPolicy'

Vue.use(VueRouter)

const router=new VueRouter({
    routes:[
        {path:'/',component:Home},
        {path:'/home',component:Home},
        {path:'/auction',component:Auction},
        {path:'/privacyPolicy',component:privacyPolicy},
        {path:'/my',component:My,meta:{showFoot:false}},//showFoot是否显示底部导航栏
    ]
})

// 处理重复跳转的错误
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    if (err.name !== 'NavigationDuplicated') {
      throw err;
    }
  });
};




export default router