<template>
   
</template>

<script>
export default {
    data() {
        return {
            width:200
        }
    },
    created() {
        // console.log('相加=', this.add(5, 5));
        this.orangesRotting()

    },
    methods: {
        add(a, b) {
            while (b !== 0) {
                console.log('a=', a, 'b=', b);
                let sum = a ^ b; // 不考虑进位时的相加结果
                let carry = (a & b) << 1; // 进位
                a = sum;
                b = carry;
                console.log('sum=', sum, 'carry=', carry);
            }
            return a;
        },
        orangesRotting() {
            let grid=[[2, 1, 1], [1, 1, 0], [0, 1, 1]];
            
            // 值 0 代表空单元格；
            // 值 1 代表新鲜橘子；
            // 值 2 代表腐烂的橘子。
            let rows = grid.length;
            let cols = grid[0].length;
            // 新鲜橙子
            let freshOranges = 0;
            // 腐烂的橙子
            let rottenOranges = [];
            // 记录初始状态的新鲜橘子和腐烂橘子个数
            for (let i = 0; i < rows; i++) {
                for (let j = 0; j < cols; j++) {
                    if (grid[i][j] === 1) {
                        freshOranges++;
                    } else if (grid[i][j] === 2) {
                        rottenOranges.push([i, j]);
                    }
                }
            }
            
            let minutes = 0;
            while (rottenOranges.length > 0 && freshOranges > 0) {
                let directions = [[-1, 0], [1, 0], [0, -1], [0, 1]];
                let n = rottenOranges.length;

                for (let i = 0; i < n; i++) {
                    let [x, y] = rottenOranges.shift();

                    for (let [dx, dy] of directions) {
                        let newX = x + dx;
                        let newY = y + dy;

                        if (newX < 0 || newX >= rows || newY < 0 || newY >= cols) {
                            continue;
                        }

                        if (grid[newX][newY] === 1) {
                            grid[newX][newY] = 2;
                            freshOranges--;
                            rottenOranges.push([newX, newY]);
                        }
                    }
                }
                minutes++;
            }
            console.log('结果', freshOranges === 0 ? minutes : -1)
        }
        
    }
}
</script>
<style>

</style>