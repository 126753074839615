<template>
    <div class="jucenter" style="background-color: #f5f5f5;padding: 20px 0;min-height: calc(100vh - 100px);">
        <div style="display: flex;width: 1360px;background-color: #fff;">
            <div style="width: 290px;">
                <div class="userInfo">
                    <div class="userAvatar center">
                        <img :src="userInfo.avatar_cdn" class="avatar">
                    </div>
                    <div class="userName">
                        {{ userInfo.nickname }}
                    </div>
                    <div class="userOther center">

                        <div class="userOtherItem center" @click="myCollect"><img
                                src="https://chat.gdpaimaihui.com/img/收藏.png"
                                style="width: 24px;height: 24px;margin-left: 5px;">我的收藏
                        </div>
                        <div class="userOtherItem center" @click="myMessage"><img
                                src="https://chat.gdpaimaihui.com/img/消息.png"
                                style="width: 24px;height: 24px;margin-left: 5px;">消息通知
                        </div>
                    </div>
                </div>
                <div>
                    <el-menu default-active="1" class="el-menu-vertical-demo" active-text-color="#fff" @select="handleOpen">
                        <el-menu-item index="1" :class="{ 'is-active2': cactive == '1' }">
                            <div class="center">
                                <img :src="myInfoIcon" class="icon">
                                我的信息
                            </div>
                        </el-menu-item>
                        <el-menu-item index="2" :class="{ 'is-active2': cactive == '2' }">
                            <div class="center">
                                <img :src="myOrderIcon" class="icon">
                                我的订单
                            </div>
                        </el-menu-item>
                        <el-menu-item index="3" :class="{ 'is-active2': cactive == '3' }">
                            <div class="center">
                                <img :src="myAuctionIcon" class="icon">
                                我的拍卖
                            </div>
                        </el-menu-item>
                    </el-menu>
                </div>

            </div>
            <div class="userRight">
                <!-- 导航栏  默认-->
                <div class="userRightTop" v-if="isMenu">
                    <div class="myInfo" @click="handleFilter('基本信息')">我的信息</div>
                    <div class="line"></div>
                    <div class="toTabs">
                        <div id="filter" class="filter center">
                            <div class="filter-list">
                                <div class="filter-item" v-for="(item, index) in filterList" :key="index"
                                    @click="handleFilter(item.name)" :class="{ 'filter-active': filterName === item.name }">
                                    <img :src="item.icon" style="width: 28px;height: 28px;margin-right: 3px;">
                                    {{ item.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 面包屑 -->
                <div class="userRightTop" v-else>
                    <div style="height: 85px;" class="center">
                        <el-breadcrumb separator="/">
                            <el-breadcrumb-item v-for="(item, index) in mbList" :key="index">
                                <span class="mbItem_noclick" v-if="filterName == item.name">{{ item.name }}</span>
                                <span class="mbItem" @click="handleFilter(item.name)" v-else>{{ item.name }}</span>
                            </el-breadcrumb-item>
                        </el-breadcrumb>
                    </div>

                </div>
                <!-- 小标题 -->
                <div class="userRightTitle alcenter">
                    <div style="width: 4px;height: 22px;background-color: #333333;"></div>
                    <div
                        style="font-size: 22px;font-family: Source Han Sans SC;font-weight: 500;color: #333333;margin-left: 10px;">
                        {{ filterName }}</div>
                </div>
                <div class="userRightBottom">
                    <!-- 基本信息 -->
                    <div class="baseInfo" v-if="filterName == '基本信息'">
                        <div class="baseInfoItem evenlyFlex" v-for="(item, index) in baseInfoList" :key="index">
                            <div class="baseInfoItemLeft">{{ item.name }}</div>
                            <div class="baseInfoItemRight" @click="tobaseInfoBtn(item.name, index)">{{ item.btn }}</div>
                        </div>
                    </div>
                    <!-- 个人资料 -->
                    <div class="userData" v-if="filterName == '个人资料'">
                        <div class="userAvatar center">
                            <img :src="userInfo.avatar_cdn" class="avatar">
                        </div>
                        <div style="margin-top: 30px;">
                            <div class="userDataItem">
                                <div class="userDataTitle">
                                    昵称
                                </div>
                                <div class="userDataRes">
                                    {{ userInfo.nickname }}
                                </div>
                            </div>

                            <div class="userDataItem">
                                <div class="userDataTitle">
                                    生日
                                </div>
                                <div class="userDataRes">
                                    {{ userInfo.birthday }}
                                </div>


                            </div>
                            <div class="userDataItem">
                                <div class="userDataTitle">
                                    性别
                                </div>
                                <div class="userDataRes">
                                    {{ userInfo.gender_text }}
                                </div>
                            </div>

                            <div class="userDataItem">
                                <div class="userDataTitle">
                                    常住城市
                                </div>
                                <div class="userDataRes">
                                    {{ userInfo.address }}
                                </div>
                            </div>

                            <div class="userDataItem">
                                <div class="userDataTitle">
                                    手机
                                </div>
                                <div class="userDataRes">
                                    {{ userInfo.mobile }}
                                </div>
                            </div>

                        </div>
                    </div>
                    <!-- 修改个人资料 -->
                    <div class="editUserInfo" v-if="filterName == '修改资料'">
                        <el-form ref="userInfo" :model="userInfo" label-width="80px">
                            <el-form-item label="头像">
                                <div class="uploadAvatar" @click="changeAvatar" v-if="userInfo.avatar_cdn == ''">
                                    <img @click="changeAvatar"
                                        src="https://chat.gdpaimaihui.com/uploads/20221022/defaultAvatar.png"
                                        class="avatar">
                                </div>
                                <div class="uploadAvatar" v-if="uploadAvatarUrl == '' && userInfo.avatar_cdn != ''">
                                    <img @click="changeAvatar" :src="userInfo.avatar_cdn" class="avatar">
                                </div>
                                <div class="uploadAvatar" v-if="uploadAvatarUrl != ''">
                                    <img @click="changeAvatar" :src="uploadAvatarUrl" class="avatar">
                                </div>
                            </el-form-item>
                            <el-form-item label="昵称">
                                <el-input v-model="userInfo.nickname"></el-input>
                            </el-form-item>

                            <el-form-item label="生日">
                                <el-col :span="11">
                                    <!-- 生日只能设置不能修改 -->
                                    <el-date-picker type="date" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"
                                        placeholder="选择日期" v-model="userInfo.birthday" style="width: 100%;"
                                        v-if="userInfo.birthday == ''"></el-date-picker>
                                    <el-date-picker type="date" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"
                                        placeholder="选择日期" v-model="userInfo.birthday" style="width: 100%;" v-else
                                        disabled></el-date-picker>
                                </el-col>
                            </el-form-item>


                            <el-form-item label="性别">
                                <el-radio-group v-model="userInfo.gender">
                                    <el-radio label="0">保密</el-radio>
                                    <el-radio label="1">男</el-radio>
                                    <el-radio label="2">女</el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="常住城市">
                                <el-cascader v-model="userInfo.city" :options="address" @change="handleChange"
                                    filterable></el-cascader>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" style="background-color: #000 !important;border-color:#000;"
                                    @click="editUserInfo">提交</el-button>
                                <el-button @click="reset">重置</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                    <!-- 已绑手机 -->
                    <div class="MyPhone" v-if="filterName == '已绑手机'">
                        <div class="bandPhone">
                            <div>
                                当前已绑手机：{{ userInfo.mobile }}
                            </div>
                            <div class="changeBandBtn" @click="changeBand">
                                换绑手机
                            </div>
                        </div>
                        <div class="changeBand" v-if="changeBandShow">
                            <el-steps :active="active" finish-status="process" align-center>
                                <el-step title="验证手机号"></el-step>
                                <el-step title="验证新手机"></el-step>
                                <el-step title="完成"></el-step>
                            </el-steps>
                            <div
                                style="display: flex;align-items: center;justify-content: center;width: 100%;margin-top: 60px;">
                                <el-form :model="changePhoneForm" ref="changePhoneForm" label-width="100px"
                                    class="demo-ruleForm" style="width: 50%;text-align: center;">
                                    <!-- 验证手机号 -->
                                    <div v-if="active == 0">

                                        <div style="margin-bottom: 20px;">
                                            验证当前手机： {{ this.userInfo.mobile }}
                                        </div>
                                        <el-form-item prop="code" label-width="0">
                                            <div class="formCode">
                                                <div style="width: 70%;">
                                                    <el-input placeholder="请输入验证码"
                                                        v-model="changePhoneForm.code_old"></el-input>
                                                </div>

                                                <div class="getCode" @click="getCode('changeBand')">
                                                    {{ getCodeText }}
                                                </div>

                                            </div>

                                        </el-form-item>
                                        <div class="loginBtn" @click="validateCode('changeBand')"
                                            v-if="changePhoneForm.code_old != ''">
                                            下一步
                                        </div>
                                        <div class="loginBtn2" v-else>
                                            下一步
                                        </div>
                                    </div>
                                    <!-- 绑定新手机 -->
                                    <div v-if="active == 1">
                                        <el-form-item prop="phone" label-width="0">
                                            <el-input placeholder="请输入手机号" v-model="changePhoneForm.newPhone">
                                                <template slot="prepend" style="background-color: #fff;">
                                                    <img src="https://chat.gdpaimaihui.com/img/手机登录图标.png"
                                                        style="width: 26px;">
                                                </template>
                                            </el-input>
                                        </el-form-item>

                                        <el-form-item prop="code" label-width="0">
                                            <div class="formCode">
                                                <div style="width: 70%;">
                                                    <el-input placeholder="请输入验证码"
                                                        v-model="changePhoneForm.code_new"></el-input>
                                                </div>

                                                <div class="getCode" @click="getCode('bandNewPhone')">
                                                    {{ getCodeText }}
                                                </div>

                                            </div>

                                        </el-form-item>
                                        <div class="loginBtn" @click="validateCode('bandNewPhone')"
                                            v-if="changePhoneForm.code_new != '' && changePhoneForm.newPhone != ''">
                                            确定
                                        </div>
                                        <div class="loginBtn2" v-else>
                                            确定
                                        </div>
                                    </div>

                                    <!-- 完成 -->
                                    <div v-if="active == 2" class="complete">
                                        <img src="https://chat.gdpaimaihui.com/img/成功.png" class="completeImg">
                                        <div class="completeSuccess">
                                            换绑成功，即将重新登录
                                        </div>
                                        <div class="completeBack">
                                            {{ completeTime }}秒后自动返回登录页面
                                        </div>
                                    </div>
                                </el-form>
                            </div>

                        </div>
                    </div>
                    <!-- 实名认证 -->
                    <div class="identify" v-if="filterName == '实名验证'">
                        <div v-if="userInfo.is_identify == 0 || userInfo.is_identify == 3">
                            <div v-if="userInfo.is_identify == 3" style="color:red;">
                                您的申请已被驳回，请重新提交！
                            </div>
                            <el-form :model="identifyForm" ref="identifyForm" label-width="150px" class="demo-ruleForm"
                                style="width: 70%;text-align: center;">
                                <el-form-item prop="phone" label="姓名">
                                    <el-input placeholder="请输入姓名" v-model="identifyForm.name">
                                    </el-input>
                                </el-form-item>
                                <el-form-item prop="phone" label="身份证号">
                                    <el-input placeholder="请输入身份证号" v-model="identifyForm.code">
                                    </el-input>
                                </el-form-item>
                                <!-- <el-form-item prop="phone" label="联系电话">
                                    <el-input placeholder="请输入联系电话" v-model="identifyForm.phone">
                                    </el-input>
                                </el-form-item> -->
                                <el-form-item prop="phone" label="上传身份证正反面">
                                    <div class="alcenter">
                                        <div class="identifyImgUpload" v-if="identifyForm.front_img == ''"
                                            @click="uploadIdentify('front_img')">
                                            <img src="https://chat.gdpaimaihui.com/img/相机.png"
                                                style="width: 45px;height: 45px;">
                                            <div style="width: 100%;">身份证正面</div>
                                        </div>
                                        <div class="identifyImg" v-if="identifyForm.front_img != ''">
                                            <img :src="identifyForm.front_img">
                                            <div class="zzc">
                                                <div class="zzcBtn" @click="uploadIdentify('front_img')">点击更换身份证正面</div>
                                            </div>
                                        </div>
                                        <div class="identifyImgUpload" v-if="identifyForm.opposite_img == ''"
                                            @click="uploadIdentify('opposite_img')">
                                            <img src="https://chat.gdpaimaihui.com/img/相机.png"
                                                style="width: 45px;height: 45px;">
                                            <div style="width: 100%;">身份证反面</div>
                                        </div>
                                        <div class="identifyImg" v-if="identifyForm.opposite_img != ''">
                                            <img :src="identifyForm.opposite_img">
                                            <div class="zzc">
                                                <div class="zzcBtn" @click="uploadIdentify('opposite_img')">点击更换身份证反面</div>
                                            </div>
                                        </div>
                                    </div>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" style="background-color: #000 !important;border-color:#000;"
                                        @click="submitIdentify">提交</el-button>
                                    <el-button @click="resetIdentify">重置</el-button>
                                </el-form-item>



                            </el-form>
                        </div>
                        <div v-if="userInfo.is_identify == 1">
                            正在审核中，请耐心等候
                        </div>
                        <div v-if="userInfo.is_identify == 2">
                            我的认证信息:
                            <el-form :model="identifyForm" ref="identifyForm" label-width="150px" class="demo-ruleForm"
                                style="width: 70%;text-align: center;">
                                <el-form-item prop="phone" label="姓名:">
                                    <div style="text-align: left;padding-left: 10px;">{{ userIdentify.name }}</div>
                                </el-form-item>
                                <el-form-item prop="phone" label="身份证号:">
                                    <div style="text-align: left;padding-left: 10px;">{{ userIdentify.code }}</div>
                                </el-form-item>
                                <!-- <el-form-item prop="phone" label="联系电话">
                                    <div style="text-align: left;padding-left: 10px;">{{ userIdentify.phone }}</div>
                                </el-form-item> -->
                                <el-form-item prop="phone" label="身份证正反面">
                                    <div class="alcenter" style="padding-left: 10px;">
                                        <div class="identifyImg">
                                            <img :src="userIdentify.front_img" draggable="false">
                                            <img src="https://chat.gdpaimaihui.com/img/水印Logo.png" draggable="false"
                                                style="width:100px;height:40px;position: absolute;top: 40%;transform: translate(-50%, -50%);transform:rotate(45deg)">

                                        </div>
                                        <div class="identifyImg">
                                            <img :src="userIdentify.opposite_img" draggable="false">
                                            <img src="https://chat.gdpaimaihui.com/img/水印Logo.png" draggable="false"
                                                style="width:100px;height:40px;position: absolute;top: 40%;transform: translate(-50%, -50%);transform:rotate(45deg)">
                                        </div>
                                    </div>
                                </el-form-item>



                            </el-form>
                        </div>
                    </div>
                    <!-- 会员中心 -->
                    <div class="member" v-if="filterName == '会员中心'">
                        <div class="memberCard">
                            <div class="memberCardLeft">
                                <div class="btcenter menberLevel">
                                    <div class="userLevel" v-if="userInfo.level == 0">普通会员</div>
                                    <div class="userLevel" v-else>付费会员</div>
                                </div>


                                <div class="userExpress">
                                    <!-- 经验条 -->
                                    <!-- <el-progress :percentage="30" :format="format" color="#fff" define-back-color="#000"
                                        :show-text="false"></el-progress>
                                    <div class="btcenter">
                                        <div>成长值{{ userInfo.experience }},还差100升级</div>
                                        <div>普通会员</div>
                                    </div> -->
                                    <!-- 会员开通 -->
                                    <div class="menberList btcenter">
                                        <div v-if="userInfo.level == 0">
                                            <div class="memberItem center" v-for="(item, index) in MemberList" :key="index">
                                                <div class="memberItemName">{{ item.config_name }}</div>
                                                <div class="memberItemPrice"> <span style="font-size: 18px;">￥</span> {{
                                                    item.price }}</div>
                                                <div class="memberItemContent" v-html="item.content"></div>
                                                <div class="memberItemBtn" @click="BuyVip(item)">立即开通</div>
                                            </div>
                                        </div>

                                        <div v-else>
                                            <div v-for="(item, index) in MemberList" :key="index">
                                                会员到期时间：{{ userInfo.level_expiration_time }} <span class="renew"
                                                    @click="BuyVip(item)">续费</span>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="memberCardRight">
                                <div class="memberBtnList center">
                                    <div class="memberBtnItem" v-for="(item, index) in memberBtnList" :key="index"
                                        @click="ability(item.name)">{{
                                            item.name }}</div>
                                </div>
                                <div class="memberInfo center">
                                    <div class="memberInfoItem">
                                        <div class="memberInfoItemNum">{{ userInfo.point }}</div>
                                        <div class="memberInfoItemTitle">当前积分</div>
                                    </div>
                                    <div class="memberInfoItem">
                                        <div class="memberInfoItemNum">{{ userInfo.now_money }}</div>
                                        <div class="memberInfoItemTitle">钱包余额</div>
                                    </div>
                                    <div class="memberInfoItem">
                                        <div class="memberInfoItemNum">{{ userInfo.congeal_money }}</div>
                                        <div class="memberInfoItemTitle">冻结余额</div>
                                    </div>
                                    <div class="memberInfoItem">
                                        <div class="memberInfoItemNum">{{ userInfo.available_money }}</div>
                                        <div class="memberInfoItemTitle">可用余额</div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="center" style="padding: 40px 0;">
                            <div class="VipEquity">尊享会员权益</div>
                        </div>
                        <div class="evenlyFlex">
                            <div class="VipEquityItem">
                                <div class="center">
                                    <img src="https://chat.gdpaimaihui.com/img/获得积分.png" style="width: 50px;height: 50px;">
                                </div>
                                <div style="text-align: center;margin-top: 10px;">
                                    获得2500积分
                                </div>
                            </div>
                            <div class="VipEquityItem">
                                <div class="center">
                                    <img src="https://chat.gdpaimaihui.com/img/查看历史成交价.png"
                                        style="width: 50px;height: 50px;">
                                </div>
                                <div style="text-align: center;margin-top: 10px;">
                                    查询历史成交价
                                </div>
                            </div>
                            <div class="VipEquityItem">
                                <div class="center">
                                    <img src="https://chat.gdpaimaihui.com/img/众筹团购门槛.png"
                                        style="width: 50px;height: 50px;">
                                </div>
                                <div style="text-align: center;margin-top: 10px;">
                                    众筹团购门槛
                                </div>
                            </div>
                            <div class="VipEquityItem">
                                <div class="center">
                                    <img src="https://chat.gdpaimaihui.com/img/积分兑换门槛.png"
                                        style="width: 50px;height: 50px;">
                                </div>
                                <div style="text-align: center;margin-top: 10px;">
                                    积分兑换门槛
                                </div>
                            </div>
                            <div class="VipEquityItem">
                                <div class="center">
                                    <img src="https://chat.gdpaimaihui.com/img/线下沙龙品鉴会.png"
                                        style="width: 50px;height: 50px;">
                                </div>
                                <div style="text-align: center;margin-top: 10px;">
                                    线下沙龙品鉴会
                                </div>
                            </div>
                            <div class="VipEquityItem">
                                <div class="center">
                                    <img src="https://chat.gdpaimaihui.com/img/生日专属礼品.png"
                                        style="width: 50px;height: 50px;">
                                </div>
                                <div style="text-align: center;margin-top: 10px;">
                                    生日专属礼品
                                </div>
                            </div>
                        </div>
                        <div class="task">
                            <div class="taskTitle">做任务赢积分</div>
                            <div class="taskItem btcenter">
                                <div class="alcenter">
                                    <div class="taskItemTitle">每日签到</div>
                                    <div class="taskItemContent">每日签到+2积分</div>
                                </div>
                                <div class="taskItemBtn" @click="sign" v-if="userTakeInfo.sign == 0">
                                    签到
                                </div>
                                <div class="taskItemBtnComp" v-else>
                                    已签到
                                </div>
                            </div>
                            <div class="taskItem btcenter">
                                <div class="alcenter">
                                    <div class="taskItemTitle">购买商品</div>
                                    <div class="taskItemContent">首次购买+100积分，后续每次+20积分</div>
                                </div>
                                <div class="taskItemBtn" @click="goShop" v-if="userTakeInfo.order == 0">
                                    去购买
                                </div>
                                <div class="taskItemBtnComp" v-else>
                                    已购买
                                </div>
                            </div>

                        </div>

                        <div style="padding-bottom:40px;">
                            <div class="taskTitle">积分商城</div>
                            <div class="pointShop btcenter">
                                <div class="alcenter">
                                    <div>
                                        <div class="pointShopTitle">当前积分</div>
                                        <div class="pointShopNum">{{ userInfo.point }}</div>
                                    </div>
                                    <div class="pointShopGo" @click="exchangeShop">前往兑换商品 ></div>
                                </div>
                                <div>
                                    <img src="https://chat.gdpaimaihui.com/img/礼物.png" style="width: 86px;height: 86px;">
                                </div>
                            </div>

                        </div>

                    </div>

                    <div class="PayVip" v-if="filterName == '会员购买'">

                        <div style="padding: 30px;">
                            <div>订单提交成功，请您尽快付款。订单号：<span style="color: red;">{{ orderID }}</span></div>
                            <div>
                                应付金额：<span style="color: red;">RMB {{ buyVipItem.price }}</span>元
                            </div>
                            <div class="btcenter" style="width: 30%;margin-top: 30px;">
                                <div class="changePay" @click="changeNativePay" :class="{ 'changePay_cl': changePay == 0 }">
                                    扫码支付
                                </div>
                                <div class="changePay" @click="changeScanPay" :class="{ 'changePay_cl': changePay == 1 }">
                                    余额支付
                                </div>
                            </div>
                            <div v-if="changePay == 1">
                                <div>当前余额：<span style="color: red;"> {{ userInfo.available_money }}</span></div>
                                <div class="alcenter" style="margin-top: 10px;">
                                    <div style="width: 200px;">
                                        <el-input placeholder="请输入支付密码" type="password" v-model="payPassword">
                                        </el-input>
                                    </div>

                                    <div class="changePay changePay_cl" @click="buyVipBalancePay" v-if="payPassword != ''">
                                        支付
                                    </div>
                                    <div class="changePay_cl2" v-else>
                                        支付
                                    </div>
                                </div>
                            </div>
                            <div>
                                <!-- 支付二维码 -->
                                <canvas id="qrcode" ref="qrcode"></canvas>
                            </div>



                        </div>

                    </div>

                    <div class="recharge" v-if="filterName == '充值'">

                        <div style="padding: 30px;">
                            <div>请输入您充值的金额</div>
                            <div style="margin-top: 20px;">
                                余额： RMB<span style="color: red;"> {{ userInfo.now_money }}</span>
                            </div>
                            <div class="recharge_num">
                                <div>充值金额 </div>
                                <div style="width:180px;"><el-input class="rechargeInput" placeholder="请输入充值金额"
                                        v-model="rechargeNum"></el-input>
                                </div> 元
                            </div>
                            <div class="rechargeBtn" @click="recharge">
                                下一步
                            </div>
                            <!-- 支付二维码 -->
                            <canvas ref="qrcode_recharge"></canvas>


                        </div>

                    </div>
                    <div class="withdrawal" v-if="filterName == '提现'">

                        <div style="padding: 30px;">
                            <div>请输入您提现的金额</div>
                            <div style="color: #999999;margin-top: 20px;">温馨提示:手续费由微信支付收取提现服务费，非平台方收取。</div>
                            <div style="margin-top: 20px;">
                                可用余额： RMB<span style="color: red;"> {{ userInfo.available_money }}</span>
                            </div>
                            <div class="recharge_num">
                                <div>提现金额 </div>
                                <div style="width:180px;"><el-input class="rechargeInput" placeholder="请输入提现金额"
                                        v-model="withdrawalNum"></el-input>
                                </div> 元
                            </div>
                            <div class="change_bank alcenter" style="margin-top: 20px;">
                                <div>提现账户 &nbsp;</div>
                                <div>
                                    <el-select v-model="withdrawalBank" placeholder="请选择">
                                        <el-option v-for="item in BankList" :key="item.id" :label="item.bank_name"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </div>

                            </div>

                            <div style="margin-top: 20px;">服务费：RMB <span style="color: red;">{{ serviceCharge }}</span>
                            </div>
                            <div style="margin-top: 20px;">费率：<span style="color: red;">{{ withdrawal_rate * 100 }}%</span>
                            </div>
                            <div class="rechargeBtn" @click="withdrawal">
                                提交
                            </div>


                        </div>

                    </div>
                    <div class="withdrawal" v-if="filterName == '收货地址'">

                        <div style="padding: 30px;">
                            <div class="alcenter">
                                <div class="addAddress" @click="addAddress">新增收货地址</div>
                                <div style="font-size: 16px;font-family: Source Han Sans SC;color: #999999;">最多可以设置20个地址
                                </div>
                            </div>
                            <!-- <el-cascader :options="addressAll" filterable></el-cascader> -->
                            <div>
                                <el-table :data="addressList" style="width: 100%">
                                    <el-table-column prop="name" label="姓名" width="180">
                                    </el-table-column>
                                    <el-table-column prop="mobile" label="手机号">
                                    </el-table-column>
                                    <el-table-column prop="allAddress" label="所在城市" width="180">
                                    </el-table-column>
                                    <el-table-column prop="detail" label="详细地址">
                                    </el-table-column>
                                    <el-table-column label="操作">
                                        <template slot-scope="scope">
                                            <div class="evenlyFlex">
                                                <div class="editAddress" @click="editAddress(scope.row.id)">修改</div>
                                                <div class="delAddress" @click="delAddress(scope.row.id)">删除</div>
                                                <div class="defaultAddress" v-if="scope.row.status == 1">默认地址</div>
                                                <div class="setDefaultAddress" @click="setDefaultAddress(scope.row.id)"
                                                    v-else>设为默认</div>
                                            </div>
                                        </template>

                                    </el-table-column>


                                </el-table>
                            </div>


                        </div>

                    </div>
                    <div class="address" v-if="filterName == '新增收货地址'">

                        <div style="padding: 30px;">
                            <el-form :model="addressForm" :rules="addressFormRules" ref="ruleForm" label-width="100px"
                                class="demo-ruleForm">
                                <el-form-item label="收货人" prop="name">
                                    <el-input v-model="addressForm.name"></el-input>
                                </el-form-item>
                                <el-form-item label="手机号" prop="mobile">
                                    <el-input v-model="addressForm.mobile"></el-input>
                                </el-form-item>
                                <el-form-item label="所在城市" prop="address">
                                    <el-cascader :options="addressAll" v-model="addressForm.address" @change="changeAddress"
                                        filterable></el-cascader>
                                </el-form-item>
                                <el-form-item label="详细地址" prop="detail">
                                    <el-input v-model="addressForm.detail"></el-input>
                                </el-form-item>
                                <el-form-item label="默认地址" prop="status">
                                    <el-switch v-model="addressForm.status" active-value="1" inactive-value="0"></el-switch>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" style="background-color: #000 !important;border-color:#000;"
                                        @click="submitAddressForm('ruleForm')">提交</el-button>
                                    <el-button @click="resetAddressForm('ruleForm')">重置</el-button>
                                </el-form-item>
                            </el-form>

                        </div>
                    </div>

                    <div class="address" v-if="filterName == '修改收货地址'">

                        <div style="padding: 30px;">
                            <el-form :model="editAddressForm" :rules="editAddressFormRules" ref="ruleForm2"
                                label-width="100px" class="demo-ruleForm">
                                <el-form-item label="收货人" prop="name">
                                    <el-input v-model="editAddressForm.name"></el-input>
                                </el-form-item>
                                <el-form-item label="手机号" prop="mobile">
                                    <el-input v-model="editAddressForm.mobile"></el-input>
                                </el-form-item>
                                <el-form-item label="所在城市" prop="address">
                                    <el-cascader :options="addressAll" v-model="editAddressForm.address"
                                        @change="changeEditAddress" filterable></el-cascader>
                                </el-form-item>
                                <el-form-item label="详细地址" prop="detail">
                                    <el-input v-model="editAddressForm.detail"></el-input>
                                </el-form-item>
                                <el-form-item label="默认地址" prop="status">
                                    <el-switch v-model="editAddressForm.status" active-value="1"
                                        inactive-value="0"></el-switch>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" style="background-color: #000 !important;border-color:#000;"
                                        @click="submitEditAddressForm('ruleForm2')">提交</el-button>
                                    <el-button @click="resetEditAddressForm('ruleForm2')">重置</el-button>
                                </el-form-item>
                            </el-form>

                        </div>

                    </div>

                    <div class="address" v-if="filterName == '银行卡'">

                        <div style="padding: 30px;">
                            <div class="bankList">
                                <div class="bankListItem evenlyFlex" v-for="(item, index) in BankList" :key="index">
                                    <img src="https://chat.gdpaimaihui.com/img/银行卡.png" style="width: 32px;height: 32px;">
                                    <div>{{ item.bank_name }}</div>
                                    <img class="eyes" src="https://chat.gdpaimaihui.com/img/闭眼.png"
                                        style="width: 32px;height: 32px;" v-if="item.eyeshow == 'true'"
                                        @click="switchBankCode(item, index)">
                                    <img class="eyes" src="https://chat.gdpaimaihui.com/img/睁眼.png"
                                        style="width: 32px;height: 32px;" v-else @click="switchBankCode(item, index)">
                                    <div v-if="item.eyeshow == 'true'">{{ item.bank_code }}</div>
                                    <div v-else>{{ item.hide_code }}</div>
                                    <div style="font-size: 20px;color: #999;cursor: pointer;" @click="delBank(item.id)">x
                                    </div>
                                </div>
                                <div class="bankListItem alcenter" style="cursor: pointer;" @click="addBank">

                                    <img src="https://chat.gdpaimaihui.com/img/添加.png" style="width: 32px;height: 32px;">
                                    <div style="margin-left: 45px;">添加银行卡</div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="addBank" v-if="filterName == '添加银行卡'">

                        <div style="padding: 30px;">
                            <el-form :model="addBankForm" :rules="addBankFormRules" ref="addBankForm" label-width="100px"
                                class="demo-ruleForm">
                                <el-form-item label="持卡人" prop="username">
                                    <el-input v-model="addBankForm.username"></el-input>
                                </el-form-item>
                                <el-form-item label="银行" prop="bank_name">
                                    <el-input v-model="addBankForm.bank_name"></el-input>
                                </el-form-item>
                                <el-form-item label="开户行" prop="bank_open">
                                    <el-input v-model="addBankForm.bank_open"></el-input>
                                </el-form-item>
                                <el-form-item label="银行卡号" prop="bank_code">
                                    <el-input v-model="addBankForm.bank_code"></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" style="background-color: #000 !important;border-color:#000;"
                                        @click="submitAddBankForm('addBankForm')">提交</el-button>
                                    <el-button @click="resetAddBankForm('addBankForm')">重置</el-button>
                                </el-form-item>
                            </el-form>

                        </div>

                    </div>

                    <div class="addBank" v-if="filterName == '明细'">

                        <div style="padding: 30px;">
                            <div id="filter" class="filter center"
                                style="border-bottom: 1px solid #000;padding-bottom: 20px;">
                                <div class="filter-list">
                                    <div class="filter-item" v-for="(item, index) in detailList" :key="index"
                                        @click="detailActive(index)" :class="{ 'filter-active': detail_active == index }">
                                        {{ item.name }}
                                    </div>
                                </div>
                            </div>
                            <div style="height: 560px;overflow: auto;">
                                <!-- 提现明细 -->
                                <div class="withdrawalInfo" v-if="detail_active == 0">
                                    <div v-if="withdrawalLog != ''">
                                        <div class="withdrawalInfoItem btcenter" v-for="(item, index) in withdrawalLog"
                                            :key="index">
                                            <div class="withdrawalInfoItemLeft">
                                                <div class="withdrawalInfoItemLeftTitle">提现金额</div>
                                                <div class="withdrawalInfoItemLeftTime">{{ item.createtime }}</div>
                                            </div>
                                            <div class="withdrawalInfoItemRight">
                                                RMB {{ item.withdrawalNum }}
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else style="padding:10px;" class="center">
                                        <div style="color: #999;text-align: center;width: 100%;">暂无数据</div>

                                    </div>
                                </div>
                                <!-- 消费明细 -->
                                <div v-if="detail_active == 1">
                                    <div v-if="paylLog != ''">
                                        <div class="withdrawalInfoItem btcenter" v-for="(item, index) in paylLog"
                                            :key="index">
                                            <div class="withdrawalInfoItemLeft">
                                                <div class="withdrawalInfoItemLeftTitle">{{ item.type }}</div>
                                                <div class="withdrawalInfoItemLeftTime">{{ item.createtime }}</div>
                                            </div>
                                            <div class="withdrawalInfoItemRight">
                                                RMB {{ item.price }}
                                            </div>
                                        </div>
                                    </div>

                                    <div v-else style="padding:10px;" class="center">
                                        <div style="color: #999;text-align: center;width: 100%;">暂无动态，去逛逛吧~</div>
                                        <div class="center"
                                            style="border: 1px solid #000;text-align: center;padding: 0 10px;margin-top: 10px;cursor: pointer;"
                                            @click="goShop">去逛逛</div>
                                    </div>
                                </div>
                                <!-- 收入明细 -->
                                <div v-if="detail_active == 2">
                                    <div v-if="revenuelLog != ''">
                                        收入明细
                                    </div>
                                    <div v-else>
                                        暂无数据
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div class="MyOrder" v-if="filterName == '我的订单'">
                        <div id="filter" class="filter center" style="border-bottom: 1px solid #000;padding-bottom: 20px;">
                            <div class="filter-list">
                                <div class="filter-item" v-for="(item, index) in orderStatus" :key="index"
                                    @click="orderActive(index, item.status)"
                                    :class="{ 'filter-active': order_active == index }">
                                    {{ item.name }}
                                </div>
                            </div>
                        </div>
                        <div v-if="orderList != ''" style="height: 560px;overflow: auto;">
                            <div v-for="(item, index) in orderList" :key="index" class="orderItem">
                                <div style="padding: 10px;border-bottom: 1px solid #999999;">
                                    <span class="orderTime">{{ item.createtime }} </span>
                                    <span class="orderCode" style="margin-left: 20px;">{{ item.code }}</span>
                                </div>
                                <div class="alcenter">
                                    <div>
                                        <img :src="item.product.img_first" style="width: 120px;height: 120px;">
                                    </div>
                                    <div class="orderInfo">
                                        <div class="orderName">{{ item.product.store_name }}</div>
                                        <div>X {{ item.num }} </div>
                                        <div>RMB {{ item.amount }}</div>
                                    </div>
                                    <div class="orderStatus">
                                        {{ item.status | OrderStatus }}
                                    </div>
                                    <div class="evenlyFlex">
                                        <div class="orderBtn" @click.stop="goOrderInfo(item.id)">查看详情</div>
                                        <div class="orderBtn" v-if="item.status == 0" @click.stop="cancelOrder(item)">取消订单
                                        </div>
                                        <div class="orderBtn" v-if="item.status == 2" @click.stop="takeDelivery(item)">确认收货
                                        </div>
                                        <div class="orderBtn" v-if="item.status == 2 || item.status == 3"
                                            @click.stop="express(item)">查看物流</div>
                                        <div class="orderBtn" v-if="item.status == 3" @click.stop="evaluate(item)">评价</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else style="padding:10px;" class="center">
                            <div style="color: #999;text-align: center;width: 100%;">暂无动态，去逛逛吧~</div>
                            <div class="center"
                                style="border: 1px solid #000;text-align: center;padding: 0 10px;margin-top: 10px;cursor: pointer;"
                                @click="goShop">去逛逛</div>
                        </div>
                    </div>
                    <div class="" v-if="filterName == '订单详情'">
                        <div class="orderBaseInfo btcenter">
                            <div>
                                <div>订单编号： {{ orderInfo.code }}</div>
                                <div>下单时间： {{ orderInfo.createtime }}</div>
                                <div v-if="orderInfo.status != 0">付款时间： {{ orderInfo.pay_time }}</div>
                            </div>
                            <div>
                                <div class="orderDetailBtnListAsh" v-if="orderInfo.status == 2 || orderInfo.status == 3"
                                    @click="express(orderInfo)">查看物流</div>
                            </div>
                        </div>

                        <div class="schedule">
                            <div class="orderStatusTitle" v-if="orderInfo.status == 0">等待付款</div>
                            <div class="orderStatusTitle" v-if="orderInfo.status == 1">买家已付款</div>
                            <div class="orderStatusTitle" v-if="orderInfo.status == 2">卖家已发货</div>
                            <div class="orderStatusTitle" v-if="orderInfo.status == 3">交易成功</div>
                            <div class="orderTipText" v-if="orderInfo.status == 0">订单将在 {{ orderInfo.end_pay_time }}
                                后关闭，请及时付款
                            </div>
                            <div class="OrderSteps">
                                <el-steps :active="orderInfo.steps" align-center finish-status="process">
                                    <el-step title="待付款"></el-step>
                                    <el-step title="待发货"></el-step>
                                    <el-step title="待签收"></el-step>
                                    <el-step title="订单完成"></el-step>
                                </el-steps>
                            </div>

                        </div>

                        <div class="OrderAddress btcenter">
                            <div>收货人： {{ orderInfo.address.name }}</div>
                            <div>配送信息</div>
                            <div>联系电话： {{ orderInfo.address.mobile }}</div>
                            <div>配送方式： 快递</div>
                            <div>配送地址： {{ orderInfo.address.allAddress }}{{ orderInfo.address.detail }}</div>
                            <div>运费： RMB 0</div>

                        </div>
                        <div class="orderProductInfo evenlyFlex">
                            <div class="alcenter">
                                <div><img :src="orderInfo.product.img_first" style="width: 170px;height: 170px;"></div>
                                <div style="width:500px;">
                                    <div class="orderInfoName">
                                        <div v-if="orderInfo.product.type == 1">团购</div>
                                        <div v-else>众筹</div>
                                        <div style="width: 2px;height: 28px;background-color: #000;"></div>
                                        <div
                                            style="width: 85%;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;">
                                            {{ orderInfo.product.store_name }}</div>
                                    </div>

                                    <div class="orderInfoDetail">
                                        {{ orderInfo.product.place }} | {{ orderInfo.product.weight }} | {{
                                            orderInfo.product.alcohol }} | {{ orderInfo.product.wine_type }}
                                    </div>


                                </div>
                            </div>
                            <div class="orderNum">x {{ orderInfo.num }}</div>
                            <div class="orderNum"> RMB {{ orderInfo.product.price }} </div>
                        </div>
                        <div class="orderDetail alcenter">
                            <div class="orderDetailRight">
                                <div class="orderDetailItem btcenter">
                                    <div>商品数量：</div>
                                    <div>{{ orderInfo.num }}</div>
                                </div>
                                <div class="orderDetailItem btcenter">
                                    <div>商品总价：</div>
                                    <div>RMB {{ orderInfo.amount }}</div>
                                </div>
                                <div class="orderDetailItem btcenter">
                                    <div>优惠抵扣：</div>
                                    <div>RMB 0</div>
                                </div>
                                <div class="orderDetailItem btcenter">
                                    <div>配送费用：</div>
                                    <div>RMB 0</div>
                                </div>
                                <div class="orderDetailPrice btcenter" v-if="orderInfo.type == 2">
                                    <div>众筹定金：</div>
                                    <div>RMB {{ orderInfo.deposit }}</div>
                                </div>
                                <div class="orderDetailPrice btcenter">
                                    <div>实际付款：</div>
                                    <div v-if="orderInfo.type == 1">RMB {{ orderInfo.amount }}</div>
                                    <div v-if="orderInfo.type == 2">RMB {{ orderInfo.deposit }}</div>
                                </div>
                            </div>

                        </div>
                        <div class="Tips">
                            温馨提示：开发票请在签收后联系客服。
                        </div>
                        <div class="orderDetailBtn alcenter">
                            <!-- 待付款 -->
                            <div class="orderDetailBtnRight" v-if="orderInfo.status == 0">
                                <div v-if="orderInfo.type == 1">应付款：RMB {{ orderInfo.amount }}</div>
                                <div v-if="orderInfo.type == 2">应付款：RMB {{ orderInfo.deposit }}</div>
                                <div class="orderDetailBtnList btcenter">
                                    <div class="orderDetailBtnListAsh" @click="cancelOrder(orderInfo)">取消订单</div>
                                    <div class="orderDetailBtnListBlack" @click="gopay">立即付款</div>
                                </div>
                            </div>
                            <!-- 待发货 -->
                            <div class="orderDetailBtnRight" v-if="orderInfo.status == 1">

                                <div class="orderDetailBtnList alcenter" style="justify-content: flex-end;">
                                    <div class="orderDetailBtnListAsh" @click="editAddress">修改地址</div>
                                </div>
                            </div>
                            <!-- 待收货 -->
                            <div class="orderDetailBtnRight" v-if="orderInfo.status == 2">

                                <div class="orderDetailBtnList alcenter" style="justify-content: flex-end;">
                                    <div class="orderDetailBtnListAsh" @click="takeDelivery2(orderInfo)">确认收货</div>
                                </div>
                            </div>
                            <!-- 待评价 -->
                            <!-- <div class="orderDetailBtnRight" v-if="orderInfo.status == 3">

                                <div class="orderDetailBtnList alcenter" style="justify-content: flex-end;">
                                    <div class="orderDetailBtnListAsh" @click="comment">评价</div>
                                </div>
                            </div> -->
                        </div>

                    </div>
                    <div v-if="filterName == '支付方式'">
                        <div class="submitOrder center">
                            <!-- <div class="submitOrderBtn center" @click="payOrder">
                            支付
                        </div> -->
                            <div class="btcenter" style="width: 100%;margin-top: 20px;">
                                <div class="changePay" @click="changeOrderNativePay"
                                    :class="{ 'changePay_cl': changePay == 0 }">
                                    扫码支付
                                </div>
                                <div class="changePay" @click="changeOrderScanPay"
                                    :class="{ 'changePay_cl': changePay == 1 }">
                                    余额支付
                                </div>
                            </div>
                        </div>
                        <div v-if="changePay == 1">
                            <div>当前余额：<span style="color: red;"> {{ userInfo.available_money }}</span></div>
                            <div class="alcenter" style="margin-top: 10px;">
                                <div style="width: 200px;">
                                    <el-input placeholder="请输入支付密码" type="password" v-model="payPassword">
                                    </el-input>
                                </div>

                                <div class="changePay changePay_cl" @click="balancePay" v-if="payPassword != ''">
                                    支付
                                </div>
                                <div class="changePay_cl2" v-else>
                                    支付
                                </div>
                            </div>
                        </div>
                        <div>
                            <!-- 订单支付二维码 -->
                            <canvas id="qrcode_order" ref="qrcode_order"></canvas>
                        </div>
                    </div>
                    <div v-if="filterName == '切换收货地址'">
                        <div>
                            <el-select v-model="newAddress" placeholder="请选择新的收货地址">
                                <el-option :label="item.completeAddress" :value="item.id"
                                    v-for="(item, index) in addressList" :key="index"></el-option>
                            </el-select>
                        </div>
                        <div style="margin-top: 20px;">
                            <el-button @click="goAddNewAddress">新增收货地址</el-button>
                            <el-button type="primary" style="background-color: #000 !important;border-color:#000;"
                                @click="submitNewAddress">提交</el-button>
                        </div>
                    </div>
                    <div v-if="filterName == '物流信息'">
                        <div v-if="expressList != ''">
                            <el-timeline :reverse="reverse">
                                <el-timeline-item v-for="(item, index) in expressList.data" :key="index"
                                    :timestamp="item.time">
                                    {{ item.context }}
                                </el-timeline-item>
                            </el-timeline>
                        </div>
                        <div v-else>
                            暂无物流信息！
                        </div>
                    </div>

                    <div v-if="filterName == '我的收藏'">
                        <div id="filter" class="filter center">
                            <div class="filter-list">
                                <div class="filter-item" v-for="(item, index) in collectTypeList" :key="index"
                                    @click="changeCollect(item.name)"
                                    :class="{ 'filter-active': collectType == item.name }">
                                    {{ item.name }}
                                </div>
                            </div>
                        </div>
                        <div v-if="myCollectList != ''">
                            <div class="collectList">
                                <div class="collectListItem btcenter" v-for="(item, index) in myCollectList" :key="index"
                                    @click="goProductInfo(item)">
                                    <div>
                                        <img :src="item.product.img_first" style="width: 130px;height: 130px;">
                                    </div>
                                    <div>
                                        <div class="collectName">{{ item.product.store_name }}</div>
                                        <div class="orderInfoDetail">
                                            {{ item.product.place }} | {{ item.product.weight }} | {{ item.product.alcohol
                                            }} | {{ item.product.wine_type }}
                                        </div>
                                    </div>
                                    <div class="collectPrice center">
                                        RMB {{ item.product.price }}
                                    </div>
                                    <div class="center">
                                        <div class="noCollectBtn" @click.stop="NoCollect(item)">
                                            取消收藏
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div v-else style="padding:10px;" class="center">
                            <div style="color: #999;text-align: center;width: 100%;">暂无收藏，去逛逛吧~</div>
                            <div class="center"
                                style="border: 1px solid #000;text-align: center;padding: 0 10px;margin-top: 10px;cursor: pointer;"
                                @click="goShop">去逛逛</div>
                        </div>
                    </div>

                    <div v-if="filterName == '消息通知'">
                        <div id="filter" class="filter center">
                            <div class="filter-list">
                                <div class="filter-item" v-for="(item, index) in notifyList" :key="index"
                                    @click="changeNotify(item.name)" :class="{ 'filter-active': notifyType == item.name }">
                                    {{ item.name }}
                                </div>
                            </div>
                        </div>
                        <div v-if="myNotifyList != ''">
                            <div class="collectList">
                                <div class="collectListItem btcenter" style="padding: 0;"
                                    v-for="(item, index) in myNotifyList" :key="index">
                                    <div class="collectPrice " style="width: 100%;" v-if="notifyType == '交易消息'">
                                        <div class="btcenter" style="border-bottom: 1px solid #999;padding: 10px 40px;">
                                            <div style="font-size: 20px;font-family: Source Han Sans SC;font-weight: 500;">
                                                {{ item.content }}</div>
                                            <div
                                                style="font-size: 16px;font-family: Source Han Sans SC;font-weight: 500;color: #999999;">
                                                {{ item.createtime }}</div>
                                        </div>
                                        <div class="alcenter" style="padding: 10px 40px;">
                                            <div><img :src="item.product.img_first" style="width: 70px;height: 70px;"></div>
                                            <div>{{ item.product.store_name }}</div>


                                        </div>

                                    </div>
                                    <div class="collectPrice btcenter" style="width: 100%;padding:10px;"
                                        v-if="notifyType == '系统消息'">
                                        <div style="font-size: 20px;font-family: Source Han Sans SC;font-weight: 500;">{{
                                            item.content }}</div>
                                        <div
                                            style="font-size: 16px;font-family: Source Han Sans SC;font-weight: 500;color: #999999;">
                                            {{ item.createtime }}</div>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div v-else style="padding:10px;" class="center">
                            <div style="color: #999;text-align: center;width: 100%;">暂无动态，去逛逛吧~</div>
                            <div class="center"
                                style="border: 1px solid #000;text-align: center;padding: 0 10px;margin-top: 10px;cursor: pointer;"
                                @click="goShop">去逛逛</div>
                        </div>
                    </div>
                    <div v-if="filterName == '支付密码'">
                        <div v-if="userInfo.pay_pwd != null">
                            <div class="alcenter">
                                <div>已设置支付密码</div>
                                <div class="renew" @click="editPayPassword">修改</div>
                            </div>
                            <div style="margin-top: 20px;" v-if="isChangePayPassword">
                                <!-- 复用设置支付密码 -->
                                <el-steps :active="setPayPasswordActive" finish-status="process" align-center>
                                    <el-step title="验证手机号"></el-step>
                                    <el-step title="设置支付密码"></el-step>
                                    <el-step title="完成"></el-step>
                                </el-steps>
                                <div
                                    style="display: flex;align-items: center;justify-content: center;width: 100%;margin-top: 60px;">
                                    <el-form :model="setPayPasswordForm" ref="setPayPasswordForm" label-width="100px"
                                        class="demo-ruleForm" style="width: 50%;text-align: center;">
                                        <!-- 验证手机号 -->
                                        <div v-if="setPayPasswordActive == 0">

                                            <div style="margin-bottom: 20px;">
                                                验证当前手机： {{ this.userInfo.mobile }}
                                            </div>
                                            <el-form-item prop="code" label-width="0">
                                                <div class="formCode">
                                                    <div style="width: 70%;">
                                                        <el-input placeholder="请输入验证码"
                                                            v-model="setPayPasswordForm.code"></el-input>
                                                    </div>

                                                    <div class="getCode" @click="getCode('changePayPassword')">
                                                        {{ getCodeText }}
                                                    </div>

                                                </div>

                                            </el-form-item>
                                            <div class="loginBtn" @click="validateCode('changePayPassword')"
                                                v-if="setPayPasswordForm.code != ''">
                                                下一步
                                            </div>
                                            <div class="loginBtn2" v-else>
                                                下一步
                                            </div>
                                        </div>
                                        <!-- 设置新密码 -->
                                        <div v-if="setPayPasswordActive == 1">
                                            <el-form-item prop="phone" label="支付密码">
                                                <el-input placeholder="请输入支付密码" type="password"
                                                    v-model="setPayPasswordForm.password">
                                                </el-input>
                                            </el-form-item>
                                            <el-form-item prop="phone" label="确认支付密码">
                                                <el-input placeholder="请确认支付密码" type="password"
                                                    v-model="setPayPasswordForm.password_again">
                                                </el-input>
                                            </el-form-item>

                                            <div class="loginBtn" @click="setPayPassword"
                                                v-if="setPayPasswordForm.password != '' && setPayPasswordForm.password_again != ''">
                                                确认
                                            </div>
                                            <div class="loginBtn2" v-else>
                                                确认
                                            </div>
                                        </div>

                                        <!-- 完成 -->
                                        <div v-if="setPayPasswordActive == 2" class="complete">
                                            <img src="https://chat.gdpaimaihui.com/img/成功.png" class="completeImg">
                                            <div class="completeSuccess">
                                                设置成功
                                            </div>
                                        </div>
                                    </el-form>
                                </div>

                            </div>
                        </div>
                        <div v-else>
                            <el-steps :active="setPayPasswordActive" finish-status="process" align-center>
                                <el-step title="验证手机号"></el-step>
                                <el-step title="设置支付密码"></el-step>
                                <el-step title="完成"></el-step>
                            </el-steps>
                            <div
                                style="display: flex;align-items: center;justify-content: center;width: 100%;margin-top: 60px;">
                                <el-form :model="setPayPasswordForm" ref="setPayPasswordForm" label-width="100px"
                                    class="demo-ruleForm" style="width: 50%;text-align: center;">
                                    <!-- 验证手机号 -->
                                    <div v-if="setPayPasswordActive == 0">

                                        <div style="margin-bottom: 20px;">
                                            验证当前手机： {{ this.userInfo.mobile }}
                                        </div>
                                        <el-form-item prop="code" label-width="0">
                                            <div class="formCode">
                                                <div style="width: 70%;">
                                                    <el-input placeholder="请输入验证码"
                                                        v-model="setPayPasswordForm.code"></el-input>
                                                </div>

                                                <div class="getCode" @click="getCode('setPayPassword')">
                                                    {{ getCodeText }}
                                                </div>

                                            </div>

                                        </el-form-item>
                                        <div class="loginBtn" @click="validateCode('setPayPassword')"
                                            v-if="setPayPasswordForm.code != ''">
                                            下一步
                                        </div>
                                        <div class="loginBtn2" v-else>
                                            下一步
                                        </div>
                                    </div>
                                    <!-- 设置新密码 -->
                                    <div v-if="setPayPasswordActive == 1">
                                        <el-form-item prop="phone" label="支付密码">
                                            <el-input placeholder="请输入支付密码" type="password"
                                                v-model="setPayPasswordForm.password">
                                            </el-input>
                                        </el-form-item>
                                        <el-form-item prop="phone" label="确认支付密码">
                                            <el-input placeholder="请确认支付密码" type="password"
                                                v-model="setPayPasswordForm.password_again">
                                            </el-input>
                                        </el-form-item>

                                        <div class="loginBtn" @click="setPayPassword"
                                            v-if="setPayPasswordForm.password != '' && setPayPasswordForm.password_again != ''">
                                            确认
                                        </div>
                                        <div class="loginBtn2" v-else>
                                            确认
                                        </div>
                                    </div>

                                    <!-- 完成 -->
                                    <div v-if="setPayPasswordActive == 2" class="complete">
                                        <img src="https://chat.gdpaimaihui.com/img/成功.png" class="completeImg">
                                        <div class="completeSuccess">
                                            设置成功
                                        </div>
                                    </div>
                                </el-form>
                            </div>
                        </div>
                    </div>
                    <div v-if="filterName == '登录密码'">
                        <el-steps :active="changeLoginPasswordActive" finish-status="process" align-center>
                            <el-step title="验证手机号"></el-step>
                            <el-step title="设置登录密码"></el-step>
                            <el-step title="完成"></el-step>
                        </el-steps>
                        <div
                            style="display: flex;align-items: center;justify-content: center;width: 100%;margin-top: 60px;">
                            <el-form :model="changeLoginPasswordForm" ref="changeLoginPasswordForm" label-width="100px"
                                class="demo-ruleForm" style="width: 50%;text-align: center;">
                                <!-- 验证手机号 -->
                                <div v-if="changeLoginPasswordActive == 0">

                                    <div style="margin-bottom: 20px;">
                                        验证当前手机： {{ this.userInfo.mobile }}
                                    </div>
                                    <el-form-item prop="code" label-width="0">
                                        <div class="formCode">
                                            <div style="width: 70%;">
                                                <el-input placeholder="请输入验证码"
                                                    v-model="changeLoginPasswordForm.code"></el-input>
                                            </div>

                                            <div class="getCode" @click="getCode('changeLoginPassword')">
                                                {{ getCodeText }}
                                            </div>

                                        </div>

                                    </el-form-item>
                                    <div class="loginBtn" @click="validateCode('changeLoginPassword')"
                                        v-if="changeLoginPasswordForm.code != ''">
                                        下一步
                                    </div>
                                    <div class="loginBtn2" v-else>
                                        下一步
                                    </div>
                                </div>
                                <!-- 设置新密码 -->
                                <div v-if="changeLoginPasswordActive == 1">
                                    <el-form-item prop="phone" label="登录密码">
                                        <el-input placeholder="请输入登录密码" type="password"
                                            v-model="changeLoginPasswordForm.password">
                                        </el-input>
                                    </el-form-item>
                                    <el-form-item prop="phone" label="确认登录密码">
                                        <el-input placeholder="请确认登录密码" type="password"
                                            v-model="changeLoginPasswordForm.password_again">
                                        </el-input>
                                    </el-form-item>

                                    <div class="loginBtn" @click="changeLoginPassword"
                                        v-if="changeLoginPasswordForm.password != '' && changeLoginPasswordForm.password_again != ''">
                                        确认
                                    </div>
                                    <div class="loginBtn2" v-else>
                                        确认
                                    </div>
                                </div>

                                <!-- 完成 -->
                                <div v-if="changeLoginPasswordActive == 2" class="complete">
                                    <img src="https://chat.gdpaimaihui.com/img/成功.png" class="completeImg">
                                    <div class="completeSuccess">
                                        设置成功
                                    </div>
                                </div>
                            </el-form>
                        </div>

                    </div>
                    <!-- 分节 -->
                </div>
            </div>

        </div>
        <!-- 上传头像 -->
        <input type="file" @change="photoChange('avatar')" class="header_img" style="display: none;" id="upload_avatar">
        <!-- 上传身份证正面 -->
        <input type="file" @change="photoChange('front_img')" class="header_img" style="display: none;"
            id="upload_front_img">
        <!-- 上传身份证反面 -->
        <input type="file" @change="photoChange('opposite_img')" class="header_img" style="display: none;"
            id="upload_opposite_img">
    </div>
</template>
<!-- <script src="https://cdn.jsdelivr.net/npmqrcodejs"></script> -->
<script>
let times;

import request from "../request/request";
import address from '../util/address'
import addressAll from '../util/addressAll'
import QRCode from 'qrcode';
export default {
    data() {
        return {
            reverse: true,
            userInfo: '',
            myInfoIcon: 'https://chat.gdpaimaihui.com/img/个人信息_白.png',
            myOrderIcon: 'https://chat.gdpaimaihui.com/img/我的订单_黑.png',
            myAuctionIcon: 'https://chat.gdpaimaihui.com/img/我的拍卖_黑.png',
            activeName: '',
            filterList: [{
                name: '个人资料',
                icon: 'https://chat.gdpaimaihui.com/img/个人资料.png'
            }, {
                name: '修改资料',
                icon: 'https://chat.gdpaimaihui.com/img/修改资料.png'
            }, {
                name: '已绑手机',
                icon: 'https://chat.gdpaimaihui.com/img/已绑手机.png'
            }, {
                name: '实名验证',
                icon: 'https://chat.gdpaimaihui.com/img/实名认证.png'
            }],
            mbList: [],
            memberBtnList: [
                { name: '兑换' },
                { name: '充值' },
                { name: '提现' },
                { name: '明细' },
            ],
            filterName: '基本信息',
            baseInfoList: [
                { name: '会员中心', btn: '进入中心' },
                { name: '收货地址', btn: '管理地址' },
                { name: '银行卡', btn: '管理银行卡' },
                { name: '支付密码', btn: '设置' },
                { name: '登录密码', btn: '设置' },
            ],
            address: address,
            addressAll: addressAll,
            cactive: '1',
            uploadAvatarUrl: '',
            active: 0,
            changeBandShow: false,
            changePhoneForm: {
                code_old: '',
                newPhone: '',
                code_new: '',
            },
            completeTime: 3,
            getCodeText: '获取验证码',
            time: 0,
            times: '',
            identifyForm: {
                name: '',
                phone: '',
                code: '',
                front_img: '',
                opposite_img: ''
            },
            userIdentify: {},
            isMenu: true,
            MemberList: [],
            payUrl: '',
            payTimes: '',
            orderID: '',
            buyVipShow: false,
            changePay: 0,
            buyVipItem: {},
            rechargeNum: '',
            withdrawalNum: '',
            withdrawalBank: '',
            withdrawal_rate: 0,
            withdrawal_money: 0,
            addressList: [],
            addressForm: {
                name: '',
                mobile: '',
                address: '',
                detail: '',
                province: '',
                city: '',
                district: '',
                status: 0,
                busid: 0,
                allAddress: ''
            },
            addressFormRules: {
                name: [
                    { required: true, message: '请输入联系人', trigger: 'blur' }
                ],
                mobile: [
                    { required: true, message: '请输入联系电话', trigger: 'blur' }
                ],
                address: [
                    { required: true, message: '请选择地址', trigger: 'blur' }
                ],
                detail: [
                    { required: true, message: '请输入详细地址', trigger: 'blur' }
                ],
            },
            editAddressForm: {
                name: '',
                mobile: '',
                address: '',
                detail: '',
                province: '',
                city: '',
                district: '',
                status: 0,
                busid: 0,
                allAddress: ''
            },
            editAddressFormRules: {
                name: [
                    { required: true, message: '请输入联系人', trigger: 'blur' }
                ],
                mobile: [
                    { required: true, message: '请输入联系电话', trigger: 'blur' }
                ],
                address: [
                    { required: true, message: '请选择地址', trigger: 'blur' }
                ],
                detail: [
                    { required: true, message: '请输入详细地址', trigger: 'blur' }
                ],
            },
            addBankForm: {
                username: '',
                bank_name: '',
                bank_code: '',
                bank_open: '',
                busid: 0
            },
            addBankFormRules: {
                username: [
                    { required: true, message: '请输入持卡人', trigger: 'blur' }
                ],
                bank_name: [
                    { required: true, message: '请输入银行', trigger: 'blur' }
                ],
                bank_code: [
                    { required: true, message: '请输入银行卡号', trigger: 'blur' }
                ],
                bank_open: [
                    { required: true, message: '请输入开户行', trigger: 'blur' }
                ],
            },
            BankList: [],
            detailList: [
                {
                    name: '提现明细'
                },
                {
                    name: '消费明细'
                },
                {
                    name: '收入明细'
                },
            ],
            orderStatus: [
                {
                    name: '全部订单',
                    status: ''
                },
                {
                    name: '待付款',
                    status: '0'
                },
                {
                    name: '待发货',
                    status: '1'
                },
                {
                    name: '待收货',
                    status: '2'
                },
                {
                    name: '待评价',
                    status: '3'
                },
                {
                    name: '退货/退款中',
                    status: '-1'
                },
                {
                    name: '已退货/退款',
                    status: '-2'
                },
                {
                    name: '众筹/拼团失败',
                    status: '-4'
                },
            ],
            detail_active: 0,
            order_active: 0,
            withdrawalLog: [],
            paylLog: [],
            paylLog: [],
            revenuelLog: [],
            userTakeInfo: {
                sign: 0,
                order: 0
            },
            orderList: [],
            orderInfo: {},
            changePay: 0,
            newAddress: '',
            expressList: [],
            collectType: '众筹',
            notifyType: '交易消息',
            myCollectList: [],
            myNotifyList: [],
            collectTypeList: [
                {
                    name: '众筹'
                },
                {
                    name: '团购'
                }
            ],
            notifyList: [
                {
                    name: '交易消息'
                },
                {
                    name: '系统消息'
                }
            ],
            setPayPasswordActive: 0,
            setPayPasswordForm: {
                code: '',
                password: '',
                password_again: ''
            },
            isChangePayPassword: false,
            changeLoginPasswordActive: 0,
            changeLoginPasswordForm: {
                code: '',
                password: '',
                password_again: ''
            },
            payPassword: ''

        }
    },
    created() {
        let userInfo = localStorage.getItem('userInfo')

        if (userInfo) {
            let data = JSON.parse(userInfo)
            this.userInfo = data
            console.log('myInfo', data)
            console.log('city', this.userInfo.city)
            // 判断是否为数组
            console.log('is_city', this.userInfo.city instanceof Array)
            // 判断地址是否有地址&地址是否为数组，不为数组证明还未解码
            if (this.userInfo.city != '' && !this.userInfo.city instanceof Array) {

                this.userInfo.city = JSON.parse(this.userInfo.city)
            }
        }
        this.getWithdrawalRate()
        this.getUserAddress()
        this.getUserBankList()
        this.getUserTaskInfo()
        if (this.$route.query.name == 'address') {
            this.tobaseInfoBtn('收货地址', 1)
        }
    },
    filters: {
        OrderStatus(status) {
            switch (status) {
                case '0':
                    return "待付款"
                    break;
                case '1':
                    return "待发货"
                    break;
                case '2':
                    return "待收货"
                    break;
                case '3':
                    return "待评价"
                    break;
                case '-1':
                    return "退货中"
                    break;
                case '-2':
                    return "已退货"
                    break;
                case '-3':
                    return "已过期"
                    break;
                case '-4':
                    return "众筹/团购失败"
                    break;
            }

        }

    },
    computed: {
        // 计算提现服务费
        serviceCharge: function () {
            this.withdrawal_money = this.withdrawalNum * this.withdrawal_rate
            console.log('withdrawal_money', this.withdrawal_money)
            return this.withdrawalNum * this.withdrawal_rate
        }
    },
    methods: {
        // 侧边栏点击事件
        handleOpen(key, keyPath) {
            this.cactive = key
            switch (key) {
                case '1':
                    this.filterName = '基本信息'
                    this.isMenu = true
                    this.myInfoIcon = 'https://chat.gdpaimaihui.com/img/个人信息_白.png'
                    this.myOrderIcon = 'https://chat.gdpaimaihui.com/img/我的订单_黑.png'
                    this.myAuctionIcon = 'https://chat.gdpaimaihui.com/img/我的拍卖_黑.png';
                    break;
                case '2':
                    this.filterName = '我的订单'
                    this.isMenu = false
                    this.mbList = []
                    this.mbList.push({ name: '我的订单' })
                    this.orderActive(0, 'all')
                    this.myInfoIcon = 'https://chat.gdpaimaihui.com/img/个人信息_黑.png'
                    this.myOrderIcon = 'https://chat.gdpaimaihui.com/img/我的订单_白.png'
                    this.myAuctionIcon = 'https://chat.gdpaimaihui.com/img/我的拍卖_黑.png'
                        ; break;
                case '3':
                    this.filterName = '我的拍卖'
                    this.isMenu = false
                    this.mbList = []
                    this.mbList.push({ name: '我的拍卖' })
                    this.myInfoIcon = 'https://chat.gdpaimaihui.com/img/个人信息_黑.png'
                    this.myOrderIcon = 'https://chat.gdpaimaihui.com/img/我的订单_黑.png'
                    this.myAuctionIcon = 'https://chat.gdpaimaihui.com/img/我的拍卖_白.png'
                        ; break;
            }
        },
        // 顶部导航栏点击事件
        handleFilter(name) {
            console.log('handleFilter', name)
            this.filterName = name
            // 判断是否为一级菜单
            if (name == '基本信息' || name == '个人资料' || name == '修改资料' || name == '已绑手机' || name == '实名验证') {
                this.isMenu = true
            } else {

                //生成动态面包屑
                if (this.mbList.length > 1) {
                    if (this.mbList[0]['name'] == '基本信息' && name != '基本信息') {
                        this.mbList = [{ name: '基本信息' }]
                        console.log('清除定时器')
                        clearInterval(this.payTimes)
                        this.payTimes = ''
                    } else if (this.mbList[0]['name'] == '我的订单' && name != '我的订单') {
                        this.mbList = [{ name: '我的订单' }]

                        clearInterval(this.payTimes)
                        this.payTimes = ''
                        if (this.expressList != '') {
                            this.expressList = []
                        }
                    } else if (this.mbList[0]['name'] == '我的拍卖' && name != '我的拍卖') {
                        this.mbList = [{ name: '我的拍卖' }]
                    } else {
                        this.mbList = []
                    }
                } else {
                    this.mbList = []
                }

                this.mbList.push({ name: name })
                // 进入二级菜单，换成面包屑
                this.isMenu = false
            }

            if (name == '实名验证') {
                this.updateUserInfo()
                this.getUserIdentify()
            }
        },
        // 基本信息列表按钮
        tobaseInfoBtn(name, index) {
            // 每点击一次更新一次用户数据
            this.updateUserInfo()
            this.filterName = name
            //生成动态面包屑
            this.mbList = [{
                name: '基本信息',
            }]
            this.mbList.push({ name: name })
            // 进入二级菜单，换成面包屑
            this.isMenu = false
            console.log('index', index)
            //  0进入会员中心  1管理地址  2管理银行卡 3设置支付密码 4设置/更改登录密码
            switch (index) {
                case 0://进入会员中心
                    request.post('/api/pc/pc/getMemberConfig').then(res => {
                        if (res.code == 1) {
                            this.MemberList = res.data
                        }
                    })
                    break;
                case 1: console.log('1管理地址'); break;
                case 2: console.log('2管理银行卡'); break;
                case 3: console.log('3设置支付密码'); break;
                case 4: console.log('4设置/更改登录密码'); break;
            }
        },
        editUserInfo() {
            console.log('user', this.userInfo)
            let data = {
                'id': this.userInfo.id,
                'nickname': this.userInfo.nickname,
                'address': this.userInfo.address,
                'birthday': this.userInfo.birthday,
                'gender': this.userInfo.gender,
                'city': JSON.stringify(this.userInfo.city),
            }
            // 判断是否有上传新头像
            if (this.uploadAvatarUrl != '') {
                data['newAvatar'] = this.uploadAvatarUrl
            } else {
                data['newAvatar'] = ''
            }

            request.post('/api/pc/pc/edit', data).then(res => {
                if (res.code == 1) {
                    this.$message({
                        message: '修改成功!',
                        type: 'success',
                        offset: '80'
                    });
                    // 重新获取用户数据然后更新本地用户数据
                    this.updateUserInfo()
                }
            })
        },
        reset() {
            this.updateUserInfo()
        },
        resetIdentify() {
            this.identifyForm = {
                name: '',
                phone: '',
                code: '',
                front_img: '',
                opposite_img: ''
            }
        },
        updateUserInfo() {
            let data = {
                'phone': this.userInfo.mobile
            }
            request.post('/api/pc/pc/getUserInfo', data).then(res => {
                if (res.code == 1) {
                    this.userInfo = res.data
                    if (this.userInfo.city != '') {
                        this.userInfo.city = JSON.parse(this.userInfo.city)
                    }
                    localStorage.setItem('userInfo', JSON.stringify(res.data))
                }
            })
        },
        handleChange(value) {
            console.log(value);
            this.userInfo.city = value
            this.userInfo.address = value[0] + value[1]
            console.log('city', this.userInfo.city)
        },
        changeAvatar() {
            let upload = document.getElementById('upload_avatar')
            upload.click()

        },
        uploadIdentify(type) {
            if (type == 'front_img') {
                let upload = document.getElementById('upload_front_img')
                upload.click()
            } else if (type == 'opposite_img') {
                let upload = document.getElementById('upload_opposite_img')
                upload.click()
            }

        },
        photoChange(el) {
            var _this = this
            // 选择的文件对象(file里只包含图片的体积，不包含图片的尺寸)
            var file = event.target.files[0]
            console.log(file)

            // 选择的文件是图片
            if (file.type.indexOf('image') === 0) {
                // 压缩图片需要的一些元素和对象
                var reader = new FileReader(),
                    // 创建一个img对象
                    img = new Image()

                reader.readAsDataURL(file)
                // 文件base64化，以便获知图片原始尺寸
                reader.onload = function (e) {
                    img.src = e.target.result
                }
                // base64地址图片加载完毕后执行
                img.onload = function () {
                    // 缩放图片需要的canvas（也可以在DOM中直接定义canvas标签，这样就能把压缩完的图片不转base64也能直接显示出来）
                    var canvas = document.createElement('canvas')
                    var context = canvas.getContext('2d')

                    // 图片原始尺寸
                    var originWidth = this.width
                    var originHeight = this.height

                    // 最大尺寸限制，可通过设置宽高来实现图片压缩程度
                    var maxWidth = 250,
                        maxHeight = 250
                    // 目标尺寸
                    var targetWidth = originWidth,
                        targetHeight = originHeight
                    // 图片尺寸超过300x300的限制
                    if (originWidth > maxWidth || originHeight > maxHeight) {
                        if (originWidth / originHeight > maxWidth / maxHeight) {
                            // 更宽，按照宽度限定尺寸
                            targetWidth = maxWidth
                            targetHeight = Math.round(maxWidth * (originHeight / originWidth))
                        } else {
                            targetHeight = maxHeight
                            targetWidth = Math.round(maxHeight * (originWidth / originHeight))
                        }
                    }
                    // canvas对图片进行缩放
                    canvas.width = targetWidth
                    canvas.height = targetHeight
                    // 清除画布
                    context.clearRect(0, 0, targetWidth, targetHeight)
                    // 图片压缩
                    context.drawImage(img, 0, 0, targetWidth, targetHeight)
                    /* 第一个参数是创建的img对象；第二三个参数是左上角坐标，后面两个是画布区域宽高 */

                    // 压缩后的图片转base64 url
                    /* canvas.toDataURL(mimeType, qualityArgument),mimeType 默认值是'image/png';
                     * qualityArgument表示导出的图片质量，只有导出为jpeg和webp格式的时候此参数才有效，默认值是0.92 */
                    var dataURL = canvas.toDataURL('image/jpeg', 0.92)// base64 格式
                    // console.log(dataURL)

                    if (el == 'avatar') {
                        _this.uploadAvatarUrl = dataURL
                    } else if (el == 'front_img') {
                        _this.identifyForm.front_img = dataURL
                    } else if (el == 'opposite_img') {
                        _this.identifyForm.opposite_img = dataURL
                    }

                }
            }
        },
        changeBand() {
            this.changeBandShow = true
        },
        // 获取验证码
        getCode(type) {
            let _that = this
            let phone = '';
            if (type == 'changeBand') {
                // 发送换绑老手机号码
                phone = this.userInfo.mobile
            } else if (type == 'bandNewPhone') {
                // 发送换绑新手机号码
                phone = this.changePhoneForm.newPhone
            } else if (type == 'setPayPassword') {
                phone = this.userInfo.mobile
            } else if (type == 'changePayPassword') {
                phone = this.userInfo.mobile
            } else if (type == 'changeLoginPassword') {
                phone = this.userInfo.mobile
            }
            if (_that.time == 0) {
                _that.time = 60
                _that.times = setInterval(() => {
                    _that.getCodeText = _that.time

                    if (this.time == 0) {
                        _that.getCodeText = '重新获取'
                        clearInterval(_that.times)
                    }
                    _that.time--
                }, 1000)
                // type :changeBand 验证老手机 bandNewPhone 验证新手机
                let data = {
                    'phone': phone,
                    'type': type
                }
                request.post('/api/pc/pc/getCode', data).then(res => {
                    console.log('res', res)
                })


            }
        },
        // 验证
        validateCode(type) {
            let _that = this
            // 验证验证码是否正确
            if (type == 'changeBand') {
                let data = {
                    'phone': this.userInfo.mobile,
                    'code': this.changePhoneForm.code_old,
                    'type': type,
                }
                // 验证注册验证码
                request.post('/api/pc/pc/validateCode', data).then(res => {
                    if (res.code == 1) {
                        _that.active = 1
                        _that.time = 0
                        clearInterval(_that.times)
                        _that.getCodeText = '获取验证码'
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error',
                            offset: '80'
                        });
                    }
                })
            } else if (type == 'bandNewPhone') {
                // 验证重置密码验证码
                let data = {
                    'phone': this.changePhoneForm.newPhone,
                    'code': this.changePhoneForm.code_new,
                    'type': type,
                }
                // 验证注册验证码
                request.post('/api/pc/pc/validateCode', data).then(res => {
                    if (res.code == 1) {
                        this.active = 2
                        // 将旧手机号码换为新手机号码
                        _that.updateNewPhone()

                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error',
                            offset: '80'
                        });
                    }
                })
            } else if (type == 'setPayPassword') {
                // 验证设置支付密码验证码
                let data = {
                    'phone': this.userInfo.mobile,
                    'code': this.setPayPasswordForm.code,
                    'type': type,
                }
                // 验证注册验证码
                request.post('/api/pc/pc/validateCode', data).then(res => {
                    if (res.code == 1) {
                        this.setPayPasswordActive = 1


                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error',
                            offset: '80'
                        });
                    }
                })
            } else if (type == 'changePayPassword') {
                // 验证设置支付密码验证码
                let data = {
                    'phone': this.userInfo.mobile,
                    'code': this.setPayPasswordForm.code,
                    'type': type,
                }
                // 验证注册验证码
                request.post('/api/pc/pc/validateCode', data).then(res => {
                    if (res.code == 1) {
                        this.setPayPasswordActive = 1


                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error',
                            offset: '80'
                        });
                    }
                })
            } else if (type == 'changeLoginPassword') {
                // 验证设置支付密码验证码
                let data = {
                    'phone': this.userInfo.mobile,
                    'code': this.changeLoginPasswordForm.code,
                    'type': type,
                }
                // 验证注册验证码
                request.post('/api/pc/pc/validateCode', data).then(res => {
                    if (res.code == 1) {
                        this.changeLoginPasswordActive = 1
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error',
                            offset: '80'
                        });
                    }
                })
            }


        },
        updateNewPhone() {
            let data = {
                'oldPhone': this.userInfo.mobile,
                'newPhone': this.changePhoneForm.newPhone,
            }
            request.post('/api/pc/pc/changeBandPhone', data).then(res => {
                if (res.code == 1) {
                    this.$message({
                        message: res.msg,
                        type: 'success',
                        offset: '80'
                    });
                    this.times = setInterval(() => {
                        this.completeTime--
                        if (this.completeTime == 0) {
                            localStorage.removeItem('userInfo')
                            this.userInfo = ''
                            this.$router.push({ path: '/' })
                            clearInterval(this.times)
                            location.reload()
                        }
                    }, 1000);
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'error',
                        offset: '80'
                    });
                }
            })
        },
        // 提交实名认证
        submitIdentify() {
            if (this.identifyForm.name == '') {
                this.$message({
                    message: '姓名不能为空！',
                    type: 'error',
                    offset: '80'
                });
                return
            }
            if (this.identifyForm.code == '') {
                this.$message({
                    message: '身份证号不能为空！',
                    type: 'error',
                    offset: '80'
                });
                return
            }
            // if (this.identifyForm.phone == '') {
            //     this.$message({
            //         message: '联系电话不能为空！',
            //         type: 'error',
            //         offset: '80'
            //     });
            //     return 
            // }
            if (this.identifyForm.front_img == '') {
                this.$message({
                    message: '身份证正面不能为空！',
                    type: 'error',
                    offset: '80'
                });
                return
            }
            if (this.identifyForm.opposite_img == '') {
                this.$message({
                    message: '身份证反面不能为空！',
                    type: 'error',
                    offset: '80'
                });
                return
            }

            let data = {
                'id': this.userInfo.id,
                'name': this.identifyForm.name,
                'code': this.identifyForm.code,
                'phone': this.identifyForm.phone,
                'front_img': this.identifyForm.front_img,
                'opposite_img': this.identifyForm.opposite_img,
            }
            request.post('/api/pc/pc/identify', data).then(res => {
                if (res.code == 1) {
                    this.$message({
                        message: res.msg,
                        type: 'success',
                        offset: '80'
                    });
                    this.updateUserInfo()
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'error',
                        offset: '80'
                    });
                }
            })
        },
        // 获取用户实名认证信息
        getUserIdentify() {
            let data = {
                'id': this.userInfo.id
            }
            request.post('/api/pc/pc/getUserIdentify', data).then(res => {
                if (res.code == 1) {
                    this.userIdentify = res.data
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'error',
                        offset: '80'
                    });
                }
            })

        },
        // 计算用户经验百分比
        format(percentage) {
            return percentage === 100 ? '满' : `${percentage}%`;
        },
        BuyVip(item) {
            this.buyVipItem = item
            this.buyVipShow = true
            this.filterName = '会员购买'
            this.mbList.push({ name: '会员购买' })
            // 进入二级菜单，换成面包屑
            this.isMenu = false
            this.toNativePay(item)
        },
        closeBuyVip() {
            this.buyVipShow = false
        },
        toNativePay(item) {
            console.log('item', item)
            let data = {
                'id': this.userInfo.id,
                'price': item.price
            }

            request.post('/api/pc/pc/buyVip', data).then(res => {

                if (res.code == 1) {
                    this.orderID = res.data.orderID
                    this.payUrl = res.data.code_url
                    let qrcodeUrl = res.data.code_url
                    const canvas = this.$refs.qrcode
                    // const canvas= document.getElementById('qrcode');
                    this.payTimes = setInterval(() => {
                        this.getOrderStatus('buyVip')
                    }, 2000);

                    QRCode.toCanvas(canvas, qrcodeUrl, function (error) {
                        if (error) console.error(error)
                        console.log('QR code generated successfully.')
                    })
                }


            })
        },
        // 轮询
        getOrderStatus(type) {
            let data = {
                'orderID': this.orderID,
                'type': 'log'
            }
            console.log('data', data)
            request.post('/api/pc/pc/getOrderStatus', data).then(res => {
                if (res.data.status == 1) {
                    if (type == 'buyVip') {
                        this.$message({
                            message: '购买会员成功！',
                            type: 'success',
                            offset: '80'
                        });
                    } else if (type == 'recharge') {
                        this.rechargeNum = ''
                        this.$message({
                            message: '余额充值成功！',
                            type: 'success',
                            offset: '80'
                        });
                    }

                    this.updateUserInfo()
                    clearInterval(this.payTimes)
                    this.handleFilter('会员中心')
                } else if (res.data.status == -1) {
                    // 支付过期
                    clearInterval(this.payTimes)
                    this.handleFilter('会员中心')

                }
            })
        },
        changeScanPay() {
            // 获取Canvas元素
            var canvas = document.getElementById('qrcode');

            // 隐藏Canvas
            canvas.style.display = 'none';

            // 选择元素
            this.changePay = 1
        },
        changeNativePay() {
            // 获取Canvas元素
            var canvas = document.getElementById('qrcode');

            // 隐藏Canvas
            canvas.style.display = 'block';

            // 选择元素
            this.changePay = 0
        },
        ability(name) {
            this.filterName = name
            this.mbList.push({ name: name })
            // 进入二级菜单，换成面包屑
            this.isMenu = false
            switch (name) {
                case '明细':
                    this.detailActive('0')
                    break;
            }
        },
        recharge() {
            let data = {
                'id': this.userInfo.id,
                'price': this.rechargeNum
            }
            request.post('/api/pc/pc/recharge', data).then(res => {
                if (res.code == 1) {
                    this.orderID = res.data.orderID
                    let qrcodeUrl = res.data.code_url
                    const canvas = this.$refs.qrcode_recharge
                    this.payTimes = setInterval(() => {
                        this.getOrderStatus('recharge')
                    }, 2000);

                    QRCode.toCanvas(canvas, qrcodeUrl, function (error) {
                        if (error) console.error(error)
                        console.log('QR code generated successfully.')
                    })
                }


            })
        },
        getWithdrawalRate() {
            request.post('/api/pc/pc/getWithdrawalRate').then(res => {
                if (res.code == 1) {
                    this.withdrawal_rate = res.data.price
                }
            })
        },
        getUserAddress() {
            let data = {
                'id': this.userInfo.id
            }
            request.post('/api/pc/pc/getUserAddress', data).then(res => {
                if (res.code == 1) {
                    this.addressList = res.data
                    console.log('address', address)
                }
            })
        },
        withdrawal() {
            if (this.withdrawalNum == 0 || this.withdrawalNum == '') {
                this.$message({
                    message: '请填写提现金额',
                    type: 'error',
                    offset: '80'
                });
                return
            }

            if (this.withdrawalBank == '') {
                this.$message({
                    message: '请选择提现银行',
                    type: 'error',
                    offset: '80'
                });
                return
            }

            if (Number(this.withdrawalNum) > Number(this.userInfo.available_money)) {
                this.$message({
                    message: '余额不足',
                    type: 'error',
                    offset: '80'
                });
                return
            }

            let data = {
                'busid': this.userInfo.id,
                'withdrawalNum': this.withdrawalNum,
                'withdrawalBank': this.withdrawalBank,
                'serviceCharge': this.serviceCharge
            }
            request.post('/api/pc/pc/withdrawal', data).then(res => {
                if (res.code == 1) {
                    this.$message({
                        message: '申请提现成功！',
                        type: 'success',
                        offset: '80'
                    });
                    this.withdrawalBank = ''
                    this.withdrawalNum = ''
                    this.serviceCharge = ''
                    this.updateUserInfo()
                    this.handleFilter('会员中心')
                }
            })


        },
        addAddress() {
            this.filterName = '新增收货地址'
            this.mbList.push({ name: '新增收货地址' })
            // 进入二级菜单，换成面包屑
            this.isMenu = false
        },
        changeAddress(value) {
            console.log(value);
            this.addressForm.address = value
            this.addressForm.province = value[0]
            this.addressForm.city = value[1]
            this.addressForm.district = value[2]
            this.addressForm.allAddress = value[0] + value[1] + value[2]
            console.log('addressForm', this.addressForm)
        },
        changeEditAddress(value) {
            console.log(value);
            this.editAddressForm.address = value
            this.editAddressForm.province = value[0]
            this.editAddressForm.city = value[1]
            this.editAddressForm.district = value[2]
            this.editAddressForm.allAddress = value[0] + value[1] + value[2]
            console.log('editAddressForm', this.editAddressForm)
        },
        submitAddressForm(formName) {
            let _that = this
            // 表单校验
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.addressForm.busid = this.userInfo.id
                    this.addressForm.address = JSON.stringify(this.addressForm.address)
                    request.post('/api/pc/pc/setAddress', this.addressForm).then(res => {
                        if (res.code == 1) {
                            this.$message({
                                message: '添加地址成功！',
                                type: 'success',
                                offset: '80'
                            });
                            _that.handleFilter('收货地址')
                            _that.getUserAddress()
                            _that.resetAddressForm('ruleForm')
                        }
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        resetAddressForm(formName) {
            this.$refs[formName].resetFields();
        },
        // 设为默认地址
        setDefaultAddress(id) {
            let _that = this
            let data = {
                'id': id,
                'busid': this.userInfo.id
            }
            this.$confirm('是否设置该地址为默认地址?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                request.post('/api/pc/pc/setDefaultAddress', data).then(res => {
                    if (res.code == 1) {
                        this.$message({
                            message: '设置默认地址成功！',
                            type: 'success',
                            offset: '80'
                        });
                        this.getUserAddress()
                    }
                })
            }).catch(() => {
                // 取消设置
            });
        },
        // 修改地址
        editAddress(id) {
            let data = {
                'id': id
            }
            request.post('/api/pc/pc/getAddressInfo', data).then(res => {
                if (res.code == 1) {
                    this.editAddressForm = res.data
                    this.editAddressForm.address = JSON.parse(res.data.address)
                    this.filterName = '修改收货地址'
                    this.mbList.push({ name: '修改收货地址' })
                    // 进入二级菜单，换成面包屑
                    this.isMenu = false

                }
            })
        },
        // 删除地址
        delAddress(id) {
            let _that = this
            let data = {
                'id': id
            }
            this.$confirm('是否删除该地址?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                request.post('/api/pc/pc/delAddress', data).then(res => {
                    if (res.code == 1) {
                        this.$message({
                            message: '删除地址成功！',
                            type: 'success',
                            offset: '80'
                        });
                        this.getUserAddress()
                    }
                })
            }).catch(() => {
                // 取消设置
            });
        },
        submitEditAddressForm(formName) {
            let _that = this
            // 表单校验
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.editAddressForm.busid = this.userInfo.id
                    this.editAddressForm.address = JSON.stringify(this.editAddressForm.address)
                    request.post('/api/pc/pc/editAddress', this.editAddressForm).then(res => {
                        if (res.code == 1) {
                            this.$message({
                                message: '添加地址成功！',
                                type: 'success',
                                offset: '80'
                            });
                            _that.handleFilter('收货地址')
                            _that.getUserAddress()
                            _that.resetAddressForm('ruleForm2')
                        }
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        resetEditAddressForm(formName) {
            this.$refs[formName].resetFields();
        },
        getUserBankList() {
            let data = {
                'id': this.userInfo.id
            }
            request.post('/api/pc/pc/getUserBankList', data).then(res => {
                if (res.code == 1) {
                    this.BankList = res.data.map(item => {
                        item.hide_code = item.bank_code.replace(/^(.{4})(?:\d+)(.{4})$/, "$1 **** **** $2")
                        return item
                    })
                }
            })

        },
        switchBankCode(item, index) {
            console.log('点击眼睛item', item)
            console.log('index', index)
            if (item.eyeshow == 'false') {
                this.BankList[index].hide_code = this.BankList[index].bank_code
                this.BankList[index].eyeshow = 'true'
            } else {
                this.BankList[index].hide_code = this.BankList[index].bank_code.replace(/^(.{4})(?:\d+)(.{4})$/, "$1 **** **** $2")
                this.BankList[index].eyeshow = 'false'
            }
        },
        addBank() {
            this.filterName = '添加银行卡'
            this.mbList.push({ name: '添加银行卡' })
            // 进入二级菜单，换成面包屑
            this.isMenu = false
        },
        delBank(id) {
            let _that = this
            let data = {
                'id': id
            }
            this.$confirm('是否删除该银行卡?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                request.post('/api/pc/pc/delbank', data).then(res => {
                    if (res.code == 1) {
                        this.$message({
                            message: '删除银行卡成功！',
                            type: 'success',
                            offset: '80'
                        });
                        this.getUserBankList()
                    }
                })
            }).catch(() => {
                // 取消设置
            });
        },
        submitAddBankForm(formName) {
            let _that = this
            // 表单校验
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.addBankForm.busid = this.userInfo.id
                    request.post('/api/pc/pc/addBank', this.addBankForm).then(res => {
                        if (res.code == 1) {
                            this.$message({
                                message: '添加银行卡成功！',
                                type: 'success',
                                offset: '80'
                            });
                            _that.handleFilter('银行卡')
                            _that.getUserBankList()
                            _that.resetAddressForm('addBankForm')
                        }
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        resetAddBankForm(formName) {
            this.$refs[formName].resetFields();
        },
        detailActive(index) {
            this.detail_active = index
            let data = {
                'busid': this.userInfo.id
            }
            if (index == 0) {
                // 提现明细
                request.post('/api/pc/pc/getWithdrawalLog', data).then(res => {
                    if (res.code == 1) {
                        this.withdrawalLog = res.data
                    }
                })
            } else if (index == 1) {
                // 充值明细
                request.post('/api/pc/pc/getPaylLog', data).then(res => {
                    if (res.code == 1) {
                        this.paylLog = res.data
                    }
                })
            } else if (index == 2) {
                // 收入明细
                // request.post('/api/pc/pc/revenuelLog', data).then(res => {
                //     if (res.code == 1) {
                //         this.revenuelLog = res.data
                //     }
                // })
            }
        },

        exchangeShop() {
            this.$message({
                message: '暂未开放！',
                type: 'warning',
                offset: '80'
            });
        },
        goShop() {
            this.$router.push('/')
        },
        sign() {
            let data = {
                'busid': this.userInfo.id
            }
            request.post('/api/pc/pc/sign', data).then(res => {
                if (res.code == 1) {
                    this.$message({
                        message: '签到成功！',
                        type: 'success',
                        offset: '80'
                    });
                    this.getUserTaskInfo()
                    this.updateUserInfo()
                } else {
                    this.$message({
                        message: '签到失败！',
                        type: 'error',
                        offset: '80'
                    });
                }
            })
        },
        getUserTaskInfo() {
            let data = {
                'busid': this.userInfo.id
            }
            request.post('/api/pc/pc/getUserTaskInfo', data).then(res => {
                if (res.code == 1) {
                    this.userTakeInfo = res.data
                }
            })
        },
        orderActive(index, status) {
            this.order_active = index
            if (status == '') {
                status = 'all'
            }
            let data = {
                'busid': this.userInfo.id,
                'status': status
            }
            request.post('/api/pc/pc/getUserOrder', data).then(res => {
                if (res.code == 1) {
                    this.orderList = res.data
                }
            })
        },
        goOrderInfo(orderID) {
            console.log('OrderID', orderID)
            let data = {
                'orderID': orderID
            }
            request.post('/api/pc/pc/getOrderDetail', data).then(res => {
                if (res.code == 1) {
                    this.orderInfo = res.data

                    this.filterName = '订单详情'
                    this.mbList = [{
                        name: '我的订单',
                    }]
                    this.mbList.push({ name: '订单详情' })
                    // 进入二级菜单，换成面包屑
                    this.isMenu = false
                }
            })
        },
        cancelOrder(item) {
            let data = {
                'orderID': item.id
            }
            this.$confirm('是否取消该订单?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                request.post('/api/pc/pc/cancelOrder', data).then(res => {
                    if (res.code == 1) {
                        this.$message({
                            message: '取消订单成功！',
                            type: 'success',
                            offset: '80'
                        });
                        this.handleFilter('我的订单')
                        let status = this.orderStatus[this.order_active].status
                        this.orderActive(this.order_active, status)
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error',
                            offset: '80'
                        });
                    }
                })
            }).catch(() => {

            });
        },
        takeDelivery(item) {
            let data = {
                'orderID': item.id
            }
            this.$confirm('是否确认收货?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                request.post('/api/pc/pc/takeDelivery', data).then(res => {
                    if (res.code == 1) {
                        this.$message({
                            message: '确认收货成功！',
                            type: 'success',
                            offset: '80'
                        });
                        let status = this.orderStatus[this.order_active].status
                        this.orderActive(this.order_active, status)
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error',
                            offset: '80'
                        });
                    }
                })
            }).catch(() => {

            });
        },
        gopay() {
            this.filterName = '支付方式'
            this.mbList.push({ name: '支付方式' })
            // 进入二级菜单，换成面包屑
            this.isMenu = false
            // 支付
            request.post('/api/pc/pc/payOrder', this.orderInfo).then(res => {
                if (res.code == 1) {
                    let qrcodeUrl = res.data.code_url
                    const canvas = this.$refs.qrcode_order
                    this.payTimes = setInterval(() => {
                        this.getOrderStatus2('order')
                    }, 2000);
                    QRCode.toCanvas(canvas, qrcodeUrl, function (error) {
                        if (error) console.error(error)
                        console.log('QR code generated successfully.')
                    })
                }
            })
        },
        changeOrderScanPay() {
            // 获取Canvas元素
            var canvas = document.getElementById('qrcode_order');

            // 隐藏Canvas
            canvas.style.display = 'none';

            // 选择元素
            this.changePay = 1
        },
        changeOrderNativePay() {
            // 获取Canvas元素
            var canvas = document.getElementById('qrcode_order');

            // 显示Canvas
            canvas.style.display = 'block';

            // 选择元素
            this.changePay = 0
        },
        balancePay() {
            let _that = this
            let data = {
                'orderID': this.orderInfo.code,
                'busid': this.userInfo.id
            }
            this.$confirm('是否使用余额支付?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let payData = {
                    'busid': this.userInfo.id,
                    'pay_pwd': this.payPassword
                }
                // 验证支付密码
                request.post('/api/pc/pc/validatePayPassword', payData).then(res => {
                    if (res.code == 1) {
                        // 验证支付密码正确后进行支付
                        request.post('/api/pc/pc/balancePay', data).then(res => {
                            if (res.code == 1) {
                                console.log('支付成功！')
                                _that.changePay = 0
                                _that.payPassword = ''

                            } else {
                                _that.$message({
                                    message: res.msg,
                                    type: 'error',
                                    offset: '80'
                                });
                            }
                        })
                    } else {
                        _that.$message({
                            message: res.msg,
                            type: 'error',
                            offset: '80'
                        });
                    }
                })

            }).catch(() => {

            });
        },
        // 轮询
        getOrderStatus2(type) {
            let data = {
                'orderID': this.orderInfo.code,
                'type': type
            }
            console.log('data', data)
            request.post('/api/pc/pc/getOrderStatus', data).then(res => {
                if (res.data.status == 1) {
                    this.$message({
                        message: '支付成功！',
                        type: 'success',
                        offset: '80'
                    });

                    let status = this.orderStatus[this.order_active].status
                    this.orderActive(this.order_active, status)
                    this.handleFilter('我的订单')
                    clearInterval(this.payTimes)
                } else if (res.data.status == -1) {
                    // 支付过期
                    clearInterval(this.payTimes)
                    this.handleFilter('会员中心')

                }
            })
        },
        editAddress() {
            this.filterName = '切换收货地址'
            this.mbList.push({ name: '切换收货地址' })
            // 进入二级菜单，换成面包屑
            this.isMenu = false
            this.getUserAddress()
        },
        submitNewAddress() {
            console.log('newaddress', this.newAddress)
            let data = {
                'orderID': this.orderInfo.id,
                'newAddress': this.newAddress
            }
            request.post('/api/pc/pc/editOrderAddress', data).then(res => {
                if (res.code == 1) {
                    this.$message({
                        message: '修改地址成功！',
                        type: 'success',
                        offset: '80'
                    });
                    this.newAddress = ''
                    this.goOrderInfo(this.orderInfo.id)
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'error',
                        offset: '80'
                    });
                }
            })

        },
        goAddNewAddress() {
            this.filterName = '收货地址'
            this.mbList = [{
                name: '基本信息',
            }]
            this.mbList.push({ name: '收货地址' })
            // 进入二级菜单，换成面包屑
            this.isMenu = false
        },
        takeDelivery2(item) {
            let data = {
                'orderID': item.id
            }
            this.$confirm('是否确认收货?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                request.post('/api/pc/pc/takeDelivery', data).then(res => {
                    if (res.code == 1) {
                        this.$message({
                            message: '确认收货成功！',
                            type: 'success',
                            offset: '80'
                        });
                        this.goOrderInfo(this.orderInfo.id)
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error',
                            offset: '80'
                        });
                    }
                })
            }).catch(() => {

            });
        },
        express(item) {
            this.filterName = '物流信息'
            this.mbList.push({ name: '物流信息' })
            // 进入二级菜单，换成面包屑
            this.isMenu = false
            this.getExpress(item)
        },
        getExpress(item) {
            let data = {
                'com': item.express.express,
                'num': item.expresscode,
                'phone': item.address.mobile
            }
            request.post('/api/pc/pc/findExprees', data).then(res => {
                if (res.code == 1) {
                    this.expressList = res.data
                } else {
                    this.$message({
                        message: '暂无物流信息！',
                        type: 'error',
                        offset: '80'
                    });
                }
            })
        },
        myCollect() {
            this.filterName = '我的收藏'
            this.mbList = [{
                name: '基本信息',
            }]
            this.mbList.push({ name: '我的收藏' })
            // 进入二级菜单，换成面包屑
            this.isMenu = false
            this.getMyCollect()
        },
        myMessage() {
            this.filterName = '消息通知'
            this.mbList = [{
                name: '基本信息',
            }]
            this.mbList.push({ name: '消息通知' })
            // 进入二级菜单，换成面包屑
            this.isMenu = false
            this.getMyNotify()
        },
        getMyCollect() {
            let data = {
                'busid': this.userInfo.id,
                'type': this.collectType
            }
            request.post('/api/pc/pc/getMyCollect', data).then(res => {
                if (res.code == 1) {
                    this.myCollectList = res.data
                }
            })
        },
        getMyNotify() {
            let data = {
                'busid': this.userInfo.id,
                'type': this.notifyType
            }
            request.post('/api/pc/pc/getMyNotify', data).then(res => {
                if (res.code == 1) {
                    this.myNotifyList = res.data
                }
            })
        },
        changeCollect(type) {
            this.collectType = type
            this.getMyCollect()
        },
        changeNotify(type) {
            this.myNotifyList = []
            this.notifyType = type
            this.getMyNotify()
        },
        NoCollect(item) {
            if (this.userInfo == '') {
                this.$message({
                    message: '请先登录！',
                    type: 'error',
                    offset: '80'
                });
                return
            }

            let data = {
                'busid': this.userInfo.id,
                'pid': item.proid
            }
            this.$confirm('是否取消收藏该商品?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                request.post('/api/pc/pc/NoCollect', data).then(res => {
                    if (res.code == 1) {
                        this.$message({
                            message: res.msg,
                            type: 'success',
                            offset: '80'
                        });
                        this.getMyCollect()
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error',
                            offset: '80'
                        });
                    }
                })
            }).catch(() => {

            });
        },
        goProductInfo(item) {
            this.$router.push({
                path: '/home',
                query: {
                    pid: item.proid
                }
            })
        },
        setPayPassword() {
            if (this.setPayPasswordForm.password != this.setPayPasswordForm.password_again) {
                this.$message({
                    message: '两次密码不一样！',
                    type: 'error',
                    offset: '80'
                });
                return
            }
            if(this.setPayPasswordForm.password.length != 6){
                this.$message({
                    message: '请输入6位数支付密码！',
                    type: 'error',
                    offset: '80'
                });
                return
            }
            let data = {
                'busid': this.userInfo.id,
                'password': this.setPayPasswordForm.password
            }
            request.post('/api/pc/pc/updatePayPassword', data).then(res => {
                if (res.code == 1) {
                    this.$message({
                        message: '设置支付密码成功！',
                        type: 'success',
                        offset: '80'
                    });
                    this.isChangePayPassword = false
                    // 初始化表单
                    this.setPayPasswordForm = {
                        code: '',
                        password: '',
                        password_again: ''
                    }
                    // 更新用户信息
                    this.updateUserInfo()
                    // 设置支付密码激活状态为0
                    this.setPayPasswordActive = 0
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'error',
                        offset: '80'
                    });
                }
            })

        },
        editPayPassword() {
            this.isChangePayPassword = true
        },
        changeLoginPassword() {
            if (this.changeLoginPasswordForm.password != this.changeLoginPasswordForm.password_again) {
                this.$message({
                    message: '两次密码不一样！',
                    type: 'error',
                    offset: '80'
                });
                return
            }
            let data = {
                'busid': this.userInfo.id,
                'password': this.changeLoginPasswordForm.password
            }
            request.post('/api/pc/pc/updateLoginPassword', data).then(res => {
                if (res.code == 1) {
                    this.$message({
                        message: '更改登录密码成功！',
                        type: 'success',
                        offset: '80'
                    });
                    // 初始化表单
                    this.changeLoginPasswordForm = {
                        code: '',
                        password: '',
                        password_again: ''
                    }
                    // 更新用户信息
                    this.updateUserInfo()
                    // 设置支付密码激活状态为0
                    this.changeLoginPasswordActive = 0
                    localStorage.removeItem('userInfo')
                    this.userInfo = ''
                    this.$router.push({ path: '/' })
                    location.reload()
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'error',
                        offset: '80'
                    });
                }
            })
        },
        buyVipBalancePay() {
            console.log('order', this.orderID)
            let _that = this
            let data = {
                'orderID': this.orderID,
                'busid': this.userInfo.id
            }
            this.$confirm('是否使用余额支付?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let payData = {
                    'busid': this.userInfo.id,
                    'pay_pwd': this.payPassword
                }
                // 验证支付密码
                request.post('/api/pc/pc/validatePayPassword', payData).then(res => {
                    if (res.code == 1) {
                        // 验证支付密码正确后进行支付
                        request.post('/api/pc/pc/buyVipBalancePay', data).then(res => {
                            if (res.code == 1) {
                                console.log('支付成功！')
                                _that.changePay = 0
                            } else {
                                _that.$message({
                                    message: res.msg,
                                    type: 'error',
                                    offset: '80'
                                });
                            }
                        })
                    } else {
                        _that.$message({
                            message: res.msg,
                            type: 'error',
                            offset: '80'
                        });
                    }
                })


            }).catch(() => {

            });
        }
    }
}


</script>
<style>
/* elementUI */
.is-active2 {
    background-color: #000 !important;
}

.el-menu {
    border: none;
}

.el-menu-item:hover {
    background-color: #000 !important;
    color: #fff !important;
}

/* 进度条 */
.el-progress-bar__outer {
    border: 1px solid #999999;
}

/* 页面公共样式 */
.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.alcenter {
    display: flex;
    align-items: center;
}

.jucenter {
    display: flex;
    justify-content: center;
}

.btcenter {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.evenlyFlex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* 页面其他样式 */
.userInfo {
    text-align: center;

}

.userAvatar {
    padding: 60px 0 20px 0;
}

.userAvatar img {
    width: 136px;
    height: 136px;
    border-radius: 50%;
}

.userName {
    font-size: 24px;
    font-family: Source Han Sans SC;
    font-weight: 800;
    color: #333333;
    text-align: center;
}

.userOtherItem {
    width: 50%;
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #333333;
    line-height: 64px;
}

.icon {
    width: 28px;
    height: 28px;
    margin-right: 10px;
}
</style>

<style scoped>
.filter-item,
.baseInfoItemLeft,
.baseInfoItemRight,
.avatar,
.changeBandBtn,
.identifyImgUpload,
.mbItem,
.memberBtnItem,
.memberItemBtn,
.memberInfoItemNum,
.changePay,
.rechargeBtn,
.setDefaultAddress,
.editAddress,
.delAddress,
.eyes,
.taskItemBtn,
.pointShopGo,
.orderBtn,
.orderDetailBtnListAsh,
.orderDetailBtnListBlack,
.userOtherItem,
.noCollectBtn,
.collectListItem,
.getCode,
.loginBtn,
.myInfo {
    cursor: pointer;
}

.loginBtn2,
.mbItem_noclick {
    cursor: not-allowed;
}

.userRight {
    width: 990px;
    padding: 0 40px;
}

.userRightTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #999999;
}

.userRightTitle {
    padding: 20px 0;
}

.line {
    width: 2px;
    height: 24px;
    background-color: #999999;
}

.toTabs {
    width: 80%;
    display: flex;
    align-items: center;
}

.myInfo {
    font-size: 24px;
    font-family: Source Han Sans SC;
    font-weight: bold;
    color: #333333;
    line-height: 64px;
    text-align: left;
}

.filter {
    width: 100%;
    font-size: 14px;

}

.filter-list {
    width: 100%;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    background-color: #fff;
}

.filter-item {
    display: flex;
    align-items: center;
    color: #333333;
    padding: 5px 15px;
    margin-left: 10px;
}

.filter-icon {
    display: flex;
    flex-direction: column;
    margin-left: 5rpx;
}

.filter-active {
    border-bottom: 4px solid #000;
}

.baseInfoItem {
    padding: 30px 0;
    border-bottom: 1px solid #999;
}

.baseInfoItemLeft {
    font-size: 22px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #333333;
}

.baseInfoItemRight {
    font-size: 20px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #2177D3;
}

.userData {
    width: 50%;
    text-align: center;
}

.userDataItem {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    text-align: left;
}

.userDataTitle {
    width: 35%;
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #999999;
}

.userDataRes {
    width: 65%;
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #333333;
}

.editUserInfo {
    padding: 60px 0;
}

.avatar {
    width: 136px;
    height: 136px;
    border-radius: 50%;
    box-shadow: 1px 1px 10px 0px #999;
}

.uploadAvatar {
    padding-left: 60px;
}

.bandPhone {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.changeBandBtn {
    color: #2177D3;
}

.changeBand {
    padding: 30px 0;
}

.loginBtn {
    width: 100%;
    background-color: #000;
    color: #fff;
    font-size: 22px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    height: 64px;
    line-height: 64px;
    text-align: center;
    margin-top: 20px;

}

.loginBtn2 {
    width: 100%;
    background-color: #999999;
    color: #fff;
    font-size: 22px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    height: 64px;
    line-height: 64px;
    text-align: center;
    margin-top: 20px;

}

.identifyImgUpload {
    width: 185px;
    height: 185px;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    background-color: #fff;
    border: 1px solid #999999;
    margin-right: 20px;
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #999999;
    border-radius: 4px;
}

.identifyImg {
    position: relative;
    display: flex;
    align-content: center;
    justify-content: center;
    margin-right: 20px;
    border-radius: 4px;
}

.identifyImg:hover {
    .zzc {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.zzcBtn {
    border: 1px solid #fff;
    padding: 5px 10px;
}

.zzc {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    display: none;
    color: #fff;
}

.mbItem_noclick {
    font-weight: 600;
    color: #000;
}

.memberCard {
    width: 910px;
    height: 280px;
    background-color: #000;
    display: flex;
    color: #fff;
    padding: 0px 40px 40px 40px;
}

.memberCardLeft {
    width: 40%;
    height: 280px;
    display: grid;
    grid-template-rows: 1fr auto 1fr;
}

.memberCardRight {
    width: 60%;
    height: 280px;
    display: grid;
    grid-template-rows: 1fr auto 1fr;
}

.userLevel {
    font-size: 28px;
    font-family: Source Han Sans SC;
    font-weight: bold;
    color: #FFFFFF;

}

.menberLevel {
    grid-row: 1;
}

.userLevelRight {
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #FFFFFF;
}

.userExpress {
    grid-row: 3;
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #FFFFFF;
}

.memberBtnList {
    grid-row: 2;
}

.memberInfo {
    grid-row: 3;
}

.memberBtnItem {
    width: 19%;
    height: 40px;
    line-height: 40px;
    border: 1px solid #999999;
    text-align: center;
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #FFFFFF;
    background-color: #000;
    margin-left: 20px;

}

.memberBtnItem:hover {
    background-color: #fff;
    color: #000;
}

.memberInfoItem {
    width: 19%;
    margin-left: 20px;
    text-align: center;
    margin-top: 40px;
}

.memberInfoItemNum {
    font-size: 30px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #FFFFFF;
}

.memberInfoItemTitle {
    font-size: 20px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #FFFFFF;
}

.memberItem {
    width: 290px;
    height: 190px;
    border: 1px solid #fff;
    text-align: center;
}

.memberItemName {
    font-size: 20px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #fff;
}

.memberItemPrice {
    font-size: 28px;
    font-family: Source Han Sans SC;
    font-weight: bold;
    color: #fff;
}

.memberItemContent {
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #fff;
}

.memberItem div {
    width: 100%;
}

.memberItemBtn {
    width: 50%;
    height: 30px;
    background: #000;
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #fff;
    margin-top: 20px;
}

.memberItemBtn:hover {
    width: 50%;
    background-color: #fff;
    color: #000;
}

.changePay {
    width: 45%;
    border: 1px solid #000;
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #333333;

}

.changePay_cl {
    color: #fff;
    background-color: #000;
}

.changePay_cl2 {
    width: 45%;
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #fff;
    background-color: #999999;
    cursor: not-allowed;
    border: none;
}

.renew {
    margin-left: 20px;
    color: blue;
    cursor: pointer;
    text-decoration: underline;
}

.recharge_num {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.recharge_num div {
    margin-right: 10px;
}

.rechargeBtn {
    width: 150px;
    height: 40px;
    background: #000000;
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #FFFFFF;
    text-align: center;
    line-height: 40px;
    margin-top: 20px;
}

.addAddress {
    width: 130px;
    height: 30px;
    border: 1px solid #000000;
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #333333;
    margin-right: 30px;
    text-align: center;
    line-height: 30px;
    background-color: #fff;
}

.addAddress:hover {
    cursor: pointer;
    background-color: #000;
    color: #fff;
}

.defaultAddress {

    background: #000000;
    border: 1px solid #333333;
    font-size: 12px;
    font-family: Source Han Sans SC;
    color: #fff;
    text-align: center;
    padding: 0 3px;
}

.setDefaultAddress {

    border: 1px solid #999999;
    font-size: 12px;
    font-family: Source Han Sans SC;
    color: #999999;
    text-align: center;
    padding: 0 3px;
}

.bankListItem {
    width: 520px;
    height: 60px;
    background: #FFFFFF;
    border: 1px solid #000000;
    font-size: 20px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #333333;
    text-align: center;
    line-height: 60px;
    padding: 0 30px;
    margin-bottom: 20px;
    position: relative;
}

.withdrawalInfoItem {
    padding: 30px 150px 30px 15px;
    border-bottom: 1px solid #000;
}

.withdrawalInfoItemLeftTitle {
    font-size: 20px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #333333;
}

.withdrawalInfoItemLeftTime {
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #999999;
    margin-top: 20px;
}

.withdrawalInfoItemRight {
    font-size: 20px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #C40303;
}

.VipEquity {
    width: 162px;
    height: 34px;
    background: #F5F5F5;
    font-size: 22px;
    font-family: Source Han Sans SC;
    font-weight: bold;
    color: #333333;
    text-align: center;
    line-height: 34px;
}

.taskTitle {
    font-size: 22px;
    font-family: Source Han Sans SC;
    font-weight: bold;
    color: #333333;
    margin-top: 60px;
}

.taskItem {
    margin-top: 20px;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    padding: 30px 20px;
}

.taskItemTitle {
    font-size: 20px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #333333;
}

.taskItemContent {
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #999999;
    margin-left: 60px;
}

.taskItemBtn {
    width: 80px;
    height: 30px;
    background: #999999;
    text-align: center;
    line-height: 30px;
    font-size: 20px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #FFFFFF;
}

.taskItemBtnComp {
    width: 80px;
    height: 30px;
    background: #000000;
    text-align: center;
    line-height: 30px;
    font-size: 20px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #FFFFFF;
}

.pointShop {
    height: 180px;
    background: #212121;
    text-align: center;
    padding: 0 60px;
    margin-top: 40px;
}

.pointShopTitle {
    font-size: 20px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #FFFFFF;
}

.pointShopNum {
    font-size: 30px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #FFFFFF;
}

.pointShopGo {
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #FFFFFF;
    margin-left: 120px;
}

.orderItem {
    margin-bottom: 10px;
}

.orderTime {
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #999999;
}

.orderCode {
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #999999;
}

.orderInfo {
    width: 380px;
    margin-left: 20px;
    font-size: 20px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #333333;

}

.orderName {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.orderInfo div {
    margin-top: 10px;
}

.orderStatus {
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #333333;
    margin-left: 30px;
}

.orderBtn {
    height: 30px;
    line-height: 30px;
    margin-left: 20px;
    padding: 10px;
    border: 1px solid #000000;
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #333333;
}

.schedule {
    padding: 20px 100px;
    border-bottom: 1px solid #999;
}

.orderBaseInfo {
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #999999;
}

.orderTipText {
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #C40303;
    text-align: center;
}

.orderStatusTitle {
    width: 100%;
    text-align: center;
    font-size: 26px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #333333;
}

.OrderSteps {
    margin-top: 40px;
}

.OrderAddress {
    flex-wrap: wrap;
    padding: 30px;
    border-bottom: 1px solid #999;
}

.OrderAddress div {
    width: 50%;
    text-align: left;
    margin-top: 15px;
}

.orderProductInfo {
    padding: 30px;
    border-bottom: 1px solid #999;
}

.orderInfoName {
    font-size: 20px;
    font-weight: bold;
    color: #333333;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

}

.orderInfoDetail {
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #999999;
    margin-top: 40px;
}

.orderNum {
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #333333;
}

.orderDetail {
    text-align: right;
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #333333;
    justify-content: flex-end;
    padding: 30px;
    border-bottom: 1px solid #999;
}

.orderDetailPrice {
    width: 100%;
    font-size: 22px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #C40303;
    margin-top: 10px;
}

.orderDetailRight {
    width: 40%;

}

.orderDetailItem {
    margin-top: 10px;
}

.Tips {
    text-align: right;
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #999999;
    padding: 30px;
}

.orderDetailBtnRight {
    width: 350px;
    text-align: right;
}

.orderDetailBtn {
    padding: 0 30px 100px 30px;
    justify-content: flex-end;
    font-size: 22px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #C40303;
}

.orderDetailBtnList {
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    margin-top: 10px;
}

.orderDetailBtnListAsh {
    width: 160px;
    height: 48px;
    border: 1px solid #999999;
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #999999;
    text-align: center;
    line-height: 48px;
}

.orderDetailBtnListBlack {
    width: 160px;
    height: 48px;
    border: 1px solid #000000;
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #fff;
    background: #000;
    text-align: center;
    line-height: 48px;
}


.submitOrderBtn {
    width: 100%;
    height: 60px;
    background: #000000;
    font-size: 24px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #FFFFFF;
}

.changePay {
    width: 45%;
    border: 1px solid #000;
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #333333;
}


.changePay_cl {
    color: #fff;
    background-color: #000;
}

.noCollectBtn {
    padding: 0 5px;
    border: 1px solid #999;
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #333333;
}

.collectName {
    font-size: 20px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #333333;
}

.collectPrice {
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #333333;
}

.collectListItem {
    padding: 10px 40px;
    border: 1px solid #999;
    margin-top: 20px;
}
</style>