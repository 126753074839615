<template>
  <div id="app">
    <!-- 顶部公共导航栏 -->
    <Menu />
    <!-- 默认主页 -->
    <router-view></router-view>
    <!-- 底部栏 -->
    <Foot v-show="showFoot" />
  </div>
</template>

<script>
import Menu from './components/Menu.vue'
import Home from './components/Home.vue'
import Foot from './components/Foot.vue'
// 全文禁止鼠标右键
// 防止进行图片盗取
document.oncontextmenu = function (e) {
  return false
}

export default {
  name: 'App',
  data(){
    return {
      showFoot: true // 默认显示底部栏
    }
  },
  watch: {
    $route(to) {
      this.showFoot = to.meta.showFoot !== false; // 默认显示底部栏，如果meta中配置为false，则不显示
    }
  },
  components: {
    Menu,
    Home,
    Foot,
  },
  
}
</script>

<style>
/* 禁止PC端复制操作 */
* {
  -moz-user-select: -moz-none;
  -moz-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#app {
  font-family: Source Han Sans SC;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 60px;
}

body {
  margin: 0;
}
</style>
