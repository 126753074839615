<template>
    <div style="background-color: #fff;">
        <div class="bannar center">
            <img :src="bannar" class="bannarimg" draggable="false">
        </div>
        <div class="center">
            <div class="search">
                <el-input placeholder="搜索商城商品" v-model="searchText" clearable @change="search">
                    <i slot="suffix" class="el-input__icon el-icon-search" style="font-size: 20px;line-height: 20px;"></i>
                </el-input>
            </div>
        </div>
        <div id="filter" class="filter center">
            <div class="filter-list">
                <div class="filter-item" v-for="(item, index) in filterList" :key="index" @click="handleFilter(index)"
                    :class="{ 'filter-active': filterCurrent === index }">
                    {{ item.name }}
                </div>
            </div>
        </div>
        <div class="center" style="padding: 10px 0;">
            <div class="productList evenly">
                <div class="productItem ellipsis_1" v-for="(item, index) in productList" :key="item.id">
                    <div style="position: relative;" class="storeImg">
                        <img :src="item.img_first" style="width: 100%;" draggable="false">
                        <div class="zzc">
                            <div class="zzcBtn" @click="pitch(item.id)">查看详情</div>
                        </div>
                    </div>

                    <div class="storeName ellipsis_1">{{ item.store_name }}</div>

                    <el-col class="times">
                        <!-- type  1:团购 2：众筹 -->
                        <div class="status" v-if="item.type == '1'">{{ item.status | TGStatus }}</div>
                        <div class="status" v-else>{{ item.status | ZCStatus }}</div>
                        <div v-if="item.status == 0" style="color: #888888;font-size: 12px;margin-left: 10px;">
                            距开始：
                            <div style="display: inline-block;">
                                <el-statistic :value="item.start_time" format="DD天HH小时mm分ss秒" time-indices @finish="timeEnd"
                                    :value-style="{ 'fontSize': '10px !important;', 'color': 'red !important;' }">
                                </el-statistic>
                            </div>
                        </div>
                        <div v-if="item.status == 1" style="color: #888888;font-size: 10px;margin-left: 10px;">
                            距结束：
                            <div style="display: inline-block;">
                                <el-statistic :value="item.end_time" format="DD天HH小时mm分ss秒" time-indices @finish="timeEnd"
                                    :value-style="{ 'fontSize': '10px !important;', 'color': 'red !important;' }">
                                </el-statistic>
                            </div>
                        </div>

                    </el-col>
                    <el-col style="margin-top: 10px;">
                        <div class="between">
                            <div class="total center" v-if="item.type == '1'">
                                已团数量：{{ item.orderNum }}
                            </div>
                            <div class="total center" v-else>
                                已筹金额：{{ item.order }}
                            </div>

                        </div>
                    </el-col>

                    <el-col style="margin-top: 10px;">
                        <div class="between">
                            <div class="pitch center" v-if="item.type == '1'" @click="pitch(item.id)">
                                参与团购
                            </div>
                            <div class="pitch center" v-else @click="pitch(item.id)">
                                参与众筹
                            </div>


                            <div class="attention" v-if="item.is_collect == 0" @click="collect(item)">
                                加入收藏
                            </div>
                            <div class="attention" v-if="item.is_collect == 1" @click="NoCollect(item)">
                                取消收藏
                            </div>
                        </div>
                    </el-col>

                </div>

            </div>
        </div>

        <el-drawer title="我是标题" :with-header="false" :visible.sync="drawer" :direction="direction" size="640px"
            :before-close="handleClose">
            <!-- 需求不覆盖导航栏，so要加上导航栏高度的内边距 -->
            <div style="padding-top: 60px;">
                <div v-if="drawerStatus == 'productInfo'">
                    <div class="storeBannar">
                        <el-carousel>
                            <el-carousel-item v-for="item in productInfo.imgList" :key="item">
                                <el-image style="height: 100%;width: 100%;" fit="contain" :src="item"
                                    :preview-src-list="productInfo.imgList" draggable="false"></el-image>
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                    <div class="store">
                        <div class="storeInfo">
                            <div class="storeInfoName">
                                <div v-if="productInfo.type == 1">团购</div>
                                <div v-else>众筹</div>
                                <div style="width: 2px;height: 35px;background-color: #000;"></div>
                                <div
                                    style="width: 85%;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;">
                                    {{ productInfo.store_name }}</div>
                            </div>
                            <div class="condition alcenter" v-if="productInfo.type == 1">
                                <div style="margin-right: 5px;">
                                    <el-tooltip class="item" effect="dark" content="该商品需达指定成团要求金额才支持成功购买"
                                        placement="top-start">
                                        <div
                                            style="width: 15px;height: 15px;border-radius: 50%;border: 1px solid #000;text-align: center;line-height: 15px;font-size: 10px;">
                                            i
                                        </div>
                                    </el-tooltip>
                                </div>
                                <div>
                                    目标金额： {{ productInfo.total_price }} 元
                                </div>

                            </div>
                            <div class="condition alcenter" v-else>
                                <div style="margin-right: 5px;" v-if="productInfo.type == 1">
                                    <el-tooltip class="item" effect="dark" content="该商品需达指定成团要求金额才支持成功购买。"
                                        placement="top-start">
                                        <div
                                            style="width: 15px;height: 15px;border-radius: 50%;border: 1px solid #000;text-align: center;line-height: 15px;font-size: 10px;">
                                            i
                                        </div>
                                    </el-tooltip>
                                </div>
                                <div style="margin-right: 5px;" v-else>
                                    <el-tooltip class="item" effect="dark" content="众筹未达金额要求视为募资无效。" placement="top-start">
                                        <div
                                            style="width: 15px;height: 15px;border-radius: 50%;border: 1px solid #000;text-align: center;line-height: 15px;font-size: 10px;">
                                            i
                                        </div>
                                    </el-tooltip>
                                </div>
                                <div v-if="productInfo.type == 1">
                                    成团要求： {{ productInfo.total_price }} 元
                                </div>
                                <div v-else>
                                    募资要求: {{ productInfo.total_price }} 元
                                </div>

                            </div>
                            <div v-if="productInfo.status == 0" style="font-size: 18px;margin-top: 20px;">
                                距开始：
                                <div style="display: inline-block;">
                                    <el-statistic :value="productInfo.start_time" format="DD天HH小时mm分ss秒" time-indices
                                        :value-style="{ 'color': '#C40303 !important;', 'fontSize': '18px !important' }">
                                    </el-statistic>
                                </div>
                            </div>
                            <div v-if="productInfo.status == 1" style="font-size: 18px;margin-top: 20px;">
                                距结束：
                                <div style="display: inline-block;">
                                    <el-statistic :value="productInfo.end_time" format="DD天HH小时mm分ss秒" time-indices
                                        :value-style="{ 'color': '#C40303 !important;', 'fontSize': '18px !important' }">
                                    </el-statistic>
                                </div>
                            </div>
                            <el-progress :percentage="percentage" :format="format" color="#999999"></el-progress>
                            <div class="information">
                                <div class="informationItem" v-if="productInfo.type == 1">
                                    <div class="informationItem_num">
                                        {{ productInfo.order }}
                                    </div>
                                    <div class="informationItem_title">
                                        已团金额
                                    </div>
                                </div>
                                <div class="informationItem" v-if="productInfo.type == 2">
                                    <div class="informationItem_num">
                                        {{ productInfo.order }}
                                    </div>
                                    <div class="informationItem_title">
                                        已筹金额
                                    </div>
                                </div>
                                <div class="informationItem">
                                    <div class="informationItem_num">
                                        {{ productInfo.AllWatch }}
                                    </div>
                                    <div class="informationItem_title">
                                        浏览数量
                                    </div>
                                </div>
                                <div class="informationItem">
                                    <div class="informationItem_num">
                                        {{ productInfo.Collect }}
                                    </div>
                                    <div class="informationItem_title">
                                        加入收藏
                                    </div>
                                </div>
                            </div>

                            <div class="tip">
                                <div class="tipPrice" v-if="productInfo.type == 1">团购单价：
                                    <span class="tipNum"> {{ productInfo.price }} 元</span>
                                </div>
                                <div class="tipPrice" v-else>众筹单价：
                                    <span class="tipNum"> {{ productInfo.price }} 元</span>
                                </div>
                                <div class="tipPrice" style="margin-top: 10px;">
                                    发货提示：
                                    <span style="font-size: 18px;color: #333333;"
                                        v-if="productInfo.type == 1">拼团成功72小时内发出</span>
                                    <span style="font-size: 18px;color: #333333;" v-else>根据实际进口报关通知发货</span>
                                </div>
                            </div>

                            <div class="submitBtn" @click="placeOrder" v-if="productInfo.status == 1">
                                立即下单
                            </div>
                            <div class="submitBtn" @click="subscribe(productInfo)"
                                v-if="productInfo.status == 0 && productInfo.is_subscribe == 0">
                                订阅通知
                            </div>
                            <div class="submitBtn" @click="NoSubscribe(productInfo)"
                                v-if="productInfo.status == 0 && productInfo.is_subscribe == 1">
                                取消订阅
                            </div>
                            <div class="submitBtn" v-if="productInfo.status == 2 || productInfo.status == 3">
                                已结束
                            </div>
                            <div class="assure">
                                <div class="assureItem" v-for="(item, index) in assureList" :key="index">
                                    <img src="https://chat.gdpaimaihui.com/img/保障图标.png" style="width: 23px;">
                                    <div style="margin-left: 5px;">{{ item }}</div>
                                </div>
                            </div>
                            <div class="container" ref="container" v-if="dataList != ''">
                                <div v-for="(item, index) in displayList" :key="index" class="data-item btcenter">
                                    <!-- 渲染数据项的内容 -->
                                    <div class="alcenter">
                                        <div><img :src="item.user.avatar_cdn"
                                                style="width: 50px;height: 50px;border-radius: 50%;"></div>
                                        <div style="margin-left: 5px;">{{ item.user.nm }}</div>
                                    </div>
                                    <div>
                                        <div class="hosPay">消费了：{{ item.amount }} 元</div>
                                        <div class="hosTime">{{ item.createtime }}</div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="line"></div>

                        <div class="productDetail" v-if="productInfo.detail_img != ''">
                            <el-image style="width: 100%" :src="src" :preview-src-list="srcList" draggable="false">
                            </el-image>

                        </div>
                    </div>
                </div>
                <div class="drawerOrder" style="padding: 30px;" v-if="drawerStatus == 'order'">
                    <div class="orderInfoTitle">
                        确认订单
                    </div>
                    <div class="orderProductInfo alcenter">
                        <div><img :src="productInfo.img_first" style="width: 170px;height: 170px;"></div>
                        <div>
                            <div>
                                <div class="orderInfoName">
                                    <div v-if="productInfo.type == 1">团购</div>
                                    <div v-else>众筹</div>
                                    <div style="width: 2px;height: 28px;background-color: #000;"></div>
                                    <div
                                        style="width: 80%;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;">
                                        {{ productInfo.store_name }}</div>
                                </div>
                            </div>
                            <div class="orderInfoDetail">
                                {{ productInfo.place }} | {{ productInfo.weight }} | {{ productInfo.alcohol }} | {{
                                    productInfo.wine_type }}
                            </div>

                            <div class="orderInfoNum btcenter">
                                <div> {{ productInfo.price }} 元</div>
                                <div><el-input-number v-model="orderNum" size="mini" :min="1"
                                        :max="productInfo.residue_stock" label="商品数量"></el-input-number></div>
                            </div>
                        </div>
                    </div>
                    <div class="orderUserAddress">
                        <div class="btcenter" v-if="userAddress != ''">
                            <div>
                                <div>默认收货信息：</div>
                                <div style="margin-top: 10px;">{{ userAddress.name }} &nbsp; {{ userAddress.mobile }}</div>
                                <div style="margin-top: 10px;">{{ userAddress.allAddress }} {{ userAddress.detail }}</div>
                            </div>
                            <div class="changeAddress" @click="goAddAddress">
                                修改
                            </div>
                        </div>
                        <div v-else class="center">
                            <div class="goAddAddressBtn center" @click="goAddAddress">前往添加收货地址</div>
                        </div>
                    </div>

                    <div class="freight btcenter">
                        <div>运费</div>
                        <div>0 元</div>
                    </div>

                    <div class="orderTotal btcenter">
                        <div>实付款</div>
                        <div v-if="productInfo.type == 1">{{ orderTotal }} 元</div>
                        <div v-if="productInfo.type == 2">
                            <div class="alcenter" style="font-size: 14px;color: #999;">
                                <div>
                                    (预付{{ (productInfo.bond * 100) }}%定金)
                                </div>
                                <div style="margin-left: 5px;">
                                    <el-tooltip class="item" effect="dark" content="定制类商品,预付定金不予退款。" placement="top-start">
                                        <div
                                            style="width: 15px;height: 15px;border-radius: 50%;border: 1px solid #000;text-align: center;line-height: 15px;font-size: 10px;">
                                            i
                                        </div>
                                    </el-tooltip>
                                </div>
                            </div>
                            <div> {{ orderTotal }} 元</div>
                        </div>
                    </div>

                    <div class="kf center">
                        如有疑问，请联系客服
                    </div>

                    <div class="submitOrder center">
                        <div class="submitOrderBtn center" @click="submitOrder">
                            提交订单
                        </div>

                    </div>
                </div>
                <div class="drawerOrder" style="padding: 30px;" v-if="drawerStatus == 'orderInfo'">
                    <div class="orderInfoTitle">
                        订单详情
                    </div>
                    <div class="orderProductInfo alcenter">
                        <div><img :src="productInfo.img_first" style="width: 170px;height: 170px;"></div>
                        <div>
                            <div>
                                <div class="orderInfoName">
                                    <div v-if="productInfo.type == 1">团购</div>
                                    <div v-else>众筹</div>
                                    <div style="width: 2px;height: 28px;background-color: #000;"></div>
                                    <div
                                        style="width: 80%;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;">
                                        {{ productInfo.store_name }}</div>
                                </div>
                            </div>
                            <div class="orderInfoDetail">
                                {{ productInfo.place }} | {{ productInfo.weight }} | {{ productInfo.alcohol }} | {{
                                    productInfo.wine_type }}
                            </div>

                            <div class="orderInfoNum btcenter">
                                <div> {{ productInfo.price }} 元</div>
                                <div>x {{ orderInfo.num }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="orderUserAddress">
                        <div class="btcenter">
                            <div>
                                <div>收货信息：</div>
                                <div style="margin-top: 10px;">{{ orderInfo.address.name }} &nbsp; {{
                                    orderInfo.address.mobile }}</div>
                                <div style="margin-top: 10px;">{{ orderInfo.address.allAddress }} {{
                                    orderInfo.address.detail }}</div>
                            </div>

                        </div>

                    </div>

                    <div class="freight btcenter">
                        <div>运费</div>
                        <div>0 元</div>
                    </div>

                    <div class="orderTotal btcenter">
                        <div>实付款</div>
                        <div v-if="productInfo.type == 1">{{ orderInfo.amount }} 元</div>
                        <div v-if="productInfo.type == 2">
                            <div>{{ orderInfo.deposit }} 元</div>
                        </div>
                    </div>
                    <div class="prompt btcenter">
                        <div>提示语</div>
                        <div>付款成功后将不支持退款</div>
                    </div>

                    <div class="orderProductData">
                        <div>订单编号：{{ orderInfo.code }}</div>
                        <div>提交订单时间：{{ orderInfo.createtime }}</div>
                    </div>
                    <div class="kf center">
                        如有疑问，请联系客服
                    </div>

                    <div class="submitOrder center">
                        <!-- <div class="submitOrderBtn center" @click="payOrder">
                            支付
                        </div> -->
                        <div class="btcenter" style="width: 100%;">
                            <div class="changePay" @click="changeNativePay" :class="{ 'changePay_cl': changePay == 0 }">
                                扫码支付
                            </div>
                            <div class="changePay" @click="changeScanPay" :class="{ 'changePay_cl': changePay == 1 }">
                                余额支付
                            </div>
                        </div>
                    </div>
                    <div v-if="changePay == 1">
                        <div>当前余额：<span style="color: red;"> {{ userInfo.available_money }}</span></div>
                        <div class="alcenter" style="margin-top: 10px;">
                            <div style="width: 200px;">
                                <el-input placeholder="请输入支付密码" type="password" v-model="payPassword">
                            </el-input>
                            </div>
                            
                            <div class="changePay changePay_cl" @click="balancePay" v-if="payPassword != ''">
                                支付
                            </div>
                            <div class="changePay changePay_cl2" v-else>
                                支付
                            </div>
                        </div>

                    </div>
                    <div>
                        <!-- 订单支付二维码 -->
                        <canvas id="qrcode_order" ref="qrcode_order"></canvas>
                    </div>
                </div>
                <div class="drawerOrder" v-if="drawerStatus == 'completeOrder'">
                    <div class="orderInfoTitle">
                        支付结果
                    </div>
                    <div class="center" style="margin-top: 100px;">
                        <img src="https://chat.gdpaimaihui.com/img/完成支付.png" style="height: 60px;width: 60px;">
                    </div>
                    <div class="completeText">
                        支付成功！
                    </div>
                    <div class="completeContent">
                        支付成功，您可以去我的订单里查看订单详情
                    </div>

                    <div class="backHome center" @click="goHome">
                        返回首页
                    </div>


                </div>

            </div>
        </el-drawer>
    </div>
</template>

<script>
import { readonly } from "vue";
import request from "../request/request";
import QRCode from 'qrcode';
export default {
    data() {
        return {
            userInfo: '',
            bannar: '',
            searchText: '',
            productList: [],
            filterList: [{
                name: '正在进行'
            }, {
                name: '预展中'
            }, {
                name: '往期回顾'
            }],
            filterCurrent: 0,
            status: 1,
            drawer: false,
            direction: 'ltr',
            productInfo: {},
            percentage: 0,
            assureList: ['破损退换', '顺丰速递', '正品保障', '不支持退换'],
            src: '',
            srcList: [],
            drawerStatus: 'productInfo',
            orderInfo: {},
            userAddress: '',
            orderNum: 1,
            orderTotalPrice: 0,
            deposit: 0,
            orderCode: 0,
            orderInfo: {},
            payTimes: '',
            displayTimes: '',
            changePay: 0,
            dataList: [], // 存储数据列表的数组
            currentIndex: 0, // 当前显示的数据索引
            disList: [],
            payPassword: ''
        }
    },
    created() {
        let userInfo = localStorage.getItem('userInfo')

        if (userInfo) {
            console.log('localStorage', userInfo)
            let data = JSON.parse(userInfo)
            this.userInfo = data
        }

        this.getBannar()
        this.getStatusProduct()
        if (this.$route.query.pid) {
            console.log('query.pid', this.$route.query.pid)
            this.pitch(this.$route.query.pid)
        }
    },
    computed: {
        // 计算订单总价
        orderTotal: function () {
            var totalprice = 0
            if (this.productInfo.type == 1) {
                // 团购总价 单价*数量
                totalprice = this.productInfo.price * this.orderNum
            } else if (this.productInfo.type == 2) {
                // 众筹总价 单价*数量*定金百分比
                totalprice = this.productInfo.price * this.productInfo.bond * this.orderNum
                this.deposit = totalprice
            }
            // 订单总价
            this.orderTotalPrice = this.productInfo.price * this.orderNum

            // 返回不算订单总价，而是当前数额
            return totalprice
        },
        displayList() {
            // 根据当前索引计算要显示的3条数据
            const startIndex = this.currentIndex;
            const endIndex = startIndex + 3;

            // 处理循环滚动的情况
            if (this.dataList.length<=3) {
                return this.dataList;
            }else if (endIndex <= this.dataList.length) {
                return this.dataList.slice(startIndex, endIndex);
            } else {
                const restData = this.dataList.slice(startIndex);
                const loopData = this.dataList.slice(0, endIndex - this.dataList.length);
                return restData.concat(loopData);
            }

        },
    },
    filters: {
        TGStatus(data) {
            if (data == 0) {
                return "预展中"
            }
            if (data == 1) {
                return "正在团"
            }
            if (data == 2 || data == 3) {
                return "已结束"
            }
        },
        ZCStatus(data) {
            if (data == 0) {
                return "预展中"
            }
            if (data == 1) {
                return "筹款中"
            }
            if (data == 2 || data == 3) {
                return "已结束"
            }
        },
    },
    methods: {
        getBannar() {
            request.post('/api/pc/pc/getBannar').then(res => {
                console.log('res', res)
                if (res.code == 1) {
                    this.bannar = res.data.img_first
                }

            })
        },

        getStatusProduct() {
            let data = {
                'status': this.status,
                'searchText': this.searchText
            }
            request.post('/api/pc/pc/getStatusProduct', data).then(res => {
                if (res.code == 1) {

                    if (this.userInfo != '') {
                        this.productList = res.data.map(item => {
                            if (item.collect.indexOf(this.userInfo.id) > -1) {
                                item.is_collect = 1
                            } else {
                                item.is_collect = 0
                            }
                            return item
                        })
                    } else {
                        this.productList = res.data
                    }
                }
            })
        },
        search(value) {
            if (value) {
                console.log('搜索', value)
                this.getStatusProduct()
            }

        },
        // 筛选
        handleFilter(index) {
            this.filterCurrent = index
            if (index == 0) {
                this.status = 1
            } else if (index == 1) {
                this.status = 0
            } else if (index == 2) {
                this.status = 2
            }
            this.getStatusProduct()
        },
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    this.drawerStatus = 'productInfo'
                    this.orderNum = 1
                    this.deposit = 0
                    this.orderInfo = {}
                    clearInterval(this.payTimes)
                    clearInterval(this.displayTimes)
                    done();
                })
                .catch(_ => { });
        },
        pitch(id) {
            let _that = this
            let data = {
                'id': id
            }
            request.post('/api/pc/pc/getProductInfo', data).then(res => {
                if (res.code == 1) {
                    this.src = res.data.detail_img
                    this.srcList = []
                    this.srcList.push(res.data.detail_img)
                    this.productInfo = res.data
                    this.dataList = res.data.orderList
                    this.displayTimes = setInterval(() => {
                        _that.scrollData();
                    }, 2000); // 每2秒滚动一次，可以根据需求进行调整
                    if (this.userInfo != '') {
                        if (res.data.subscribe.indexOf(this.userInfo.id) > -1) {
                            this.productInfo.is_subscribe = 1
                        } else {
                            this.productInfo.is_subscribe = 0
                        }
                    } else {
                        this.productInfo.is_subscribe = 0
                    }
                    this.percentage = parseInt((res.data.order / res.data.total_price) * 100)<=100?parseInt((res.data.order / res.data.total_price) * 100):100
                }
            })
            this.drawer = true
        },
        format(percentage) {
            return percentage >= 100 ? '100%' : `${percentage}%`;
        },
        placeOrder() {
            if (this.userInfo == '') {
                this.$message({
                    message: '请登录！',
                    type: 'error',
                    offset: '80'
                });
                return
            }
            this.getUserAddress()
            this.drawerStatus = 'order'
        },
        getUserAddress() {
            let data = {
                'id': this.userInfo.id
            }
            request.post('/api/pc/pc/getUserDefaultAddress', data).then(res => {
                if (res.code == 1) {
                    this.userAddress = res.data
                }
            })
        },
        submitOrder() {
            console.log('orderNum', this.orderNum)
            if (this.userInfo == '') {
                this.$message({
                    message: '请登录！',
                    type: 'error',
                    offset: '80'
                });
                return
            }
            if (this.userAddress == '') {
                this.$message({
                    message: '请选择收货地址！',
                    type: 'error',
                    offset: '80'
                });
                return
            }
            var data = data = {
                'pid': this.productInfo.id,
                'busid': this.userInfo.id,
                'type': this.productInfo.type,
                'num': this.orderNum,
                'businessaddrid': this.userAddress.id,
                'amount': this.orderTotalPrice,
                'deposit': this.deposit,
            }

            request.post('/api/pc/pc/submitOrder', data).then(res => {
                if (res.code == 1) {
                    this.$message({
                        message: '下单成功！',
                        type: 'success',
                        offset: '80'
                    });
                    this.orderCode = res.data
                    this.updateUserInfo()
                    this.getOrderInfo()
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'error',
                        offset: '80'
                    });
                }
            })

        },
        updateUserInfo() {
            let data = {
                'phone': this.userInfo.mobile
            }
            request.post('/api/pc/pc/getUserInfo', data).then(res => {
                if (res.code == 1) {
                    this.userInfo = res.data
                    localStorage.setItem('userInfo', JSON.stringify(res.data))
                }
            })
        },
        getOrderInfo() {
            // 获取订单详情
            let data = {
                'orderCode': this.orderCode
            }
            request.post('/api/pc/pc/getOrderInfo', data).then(res => {
                if (res.code == 1) {
                    this.orderInfo = res.data
                    this.drawerStatus = 'orderInfo'
                    this.payOrder()
                }
            })
        },
        payOrder() {
            // 支付
            request.post('/api/pc/pc/payOrder', this.orderInfo).then(res => {
                if (res.code == 1) {
                    let qrcodeUrl = res.data.code_url
                    const canvas = this.$refs.qrcode_order
                    this.payTimes = setInterval(() => {
                        this.getOrderStatus('order')
                    }, 2000);
                    QRCode.toCanvas(canvas, qrcodeUrl, function (error) {
                        if (error) console.error(error)
                        console.log('QR code generated successfully.')
                    })
                }
            })
        },
        // 轮询
        getOrderStatus(type) {
            let data = {
                'orderID': this.orderInfo.code,
                'type': type
            }
            console.log('data', data)
            request.post('/api/pc/pc/getOrderStatus', data).then(res => {
                if (res.data.status == 1) {
                    this.$message({
                        message: '支付成功！',
                        type: 'success',
                        offset: '80'
                    });
                    this.drawerStatus = 'completeOrder'
                    clearInterval(this.payTimes)
                } else if (res.data.status == -1) {
                    // 支付过期
                    clearInterval(this.payTimes)
                    this.handleFilter('会员中心')

                }
            })
        },
        goHome() {
            this.drawerStatus = 'productInfo'
            this.orderNum = 1
            this.deposit = 0
            this.orderInfo = {}
            this.pitch(this.productInfo.id)
            this.getStatusProduct()

        },
        timeEnd() {
            request.post('/api/pc/pc/updateProduct').then(res => {
                if (res.code == 1) {
                    this.getStatusProduct()
                }
            })
            console.log('时间已经到了')
        },
        changeScanPay() {
            // 获取Canvas元素
            var canvas = document.getElementById('qrcode_order');

            // 隐藏Canvas
            canvas.style.display = 'none';

            // 选择元素
            this.changePay = 1
        },
        changeNativePay() {
            // 获取Canvas元素
            var canvas = document.getElementById('qrcode_order');

            // 显示Canvas
            canvas.style.display = 'block';

            // 选择元素
            this.changePay = 0
        },
        balancePay() {
            let _that = this
            let data = {
                'orderID': this.orderInfo.code,
                'busid': this.userInfo.id
            }
            this.$confirm('是否使用余额支付?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let payData = {
                    'busid': this.userInfo.id,
                    'pay_pwd': this.payPassword
                }
                // 验证支付密码
                request.post('/api/pc/pc/validatePayPassword', payData).then(res => {
                    if (res.code == 1) {
                        // 验证支付密码正确后进行支付
                        request.post('/api/pc/pc/balancePay', data).then(res => {
                            if (res.code == 1) {
                                console.log('支付成功！')
                                _that.changePay = 0
                            } else {
                                _that.$message({
                                    message: res.msg,
                                    type: 'error',
                                    offset: '80'
                                });
                            }
                        })
                    } else {
                        _that.$message({
                            message: res.msg,
                            type: 'error',
                            offset: '80'
                        });
                    }
                })


            }).catch(() => {

            });
        },
        subscribe(item) {
            if (this.userInfo == '') {
                this.$message({
                    message: '请先登录！',
                    type: 'error',
                    offset: '80'
                });
                return
            }
            let data = {
                'busid': this.userInfo.id,
                'pid': item.id,
                'phone': this.userInfo.mobile
            }
            this.$confirm('是否要订阅该商品通知?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                request.post('/api/pc/pc/subscribe', data).then(res => {
                    if (res.code == 1) {
                        this.$message({
                            message: res.msg,
                            type: 'success',
                            offset: '80'
                        });
                        this.pitch(item.id)
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error',
                            offset: '80'
                        });
                    }
                })
            }).catch(() => {

            });
        },
        NoSubscribe(item) {
            if (this.userInfo == '') {
                this.$message({
                    message: '请先登录！',
                    type: 'error',
                    offset: '80'
                });
                return
            }
            let data = {
                'busid': this.userInfo.id,
                'pid': item.id,
                'phone': this.userInfo.mobile
            }
            this.$confirm('是否要取消订阅该商品通知?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                request.post('/api/pc/pc/NoSubscribe', data).then(res => {
                    if (res.code == 1) {
                        this.$message({
                            message: res.msg,
                            type: 'success',
                            offset: '80'
                        });
                        this.pitch(item.id)
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error',
                            offset: '80'
                        });
                    }
                })
            }).catch(() => {

            });
        },
        collect(item) {
            if (this.userInfo == '') {
                this.$message({
                    message: '请先登录！',
                    type: 'error',
                    offset: '80'
                });
                return
            }
            let type = '';
            if (item.type == 1) {
                type = '团购'
            } else if (item.type == 2) {
                type = '众筹'
            }
            let data = {
                'busid': this.userInfo.id,
                'pid': item.id,
                'type': type
            }
            this.$confirm('是否要将商品加入收藏?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                request.post('/api/pc/pc/collect', data).then(res => {
                    if (res.code == 1) {
                        this.$message({
                            message: res.msg,
                            type: 'success',
                            offset: '80'
                        });
                        this.getStatusProduct()
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error',
                            offset: '80'
                        });
                    }
                })
            }).catch(() => {

            });
        },
        NoCollect(item) {
            if (this.userInfo == '') {
                this.$message({
                    message: '请先登录！',
                    type: 'error',
                    offset: '80'
                });
                return
            }

            let data = {
                'busid': this.userInfo.id,
                'pid': item.id
            }
            this.$confirm('是否取消收藏该商品?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                request.post('/api/pc/pc/NoCollect', data).then(res => {
                    if (res.code == 1) {
                        this.$message({
                            message: res.msg,
                            type: 'success',
                            offset: '80'
                        });
                        this.getStatusProduct()
                    } else {
                        this.$message({
                            message: res.msg,
                            type: 'error',
                            offset: '80'
                        });
                    }
                })
            }).catch(() => {

            });
        },
        goAddAddress() {
            this.$router.push({
                path: '/my',
                query: {
                    name: 'address'
                }
            })
        },
        scrollData() {
            // 滚动数据
            this.currentIndex++;
            if (this.currentIndex >= this.dataList.length) {
                this.currentIndex = 0; // 循环滚动
            }

        },
    }
}
</script>

<style>
/* 更改 ElementUI 样式 */

/* elementUI input框样式 */
.el-input__inner {
    /* height: 50px; */
    border-color: #000;
}


.el-input__inner:hover {
    border-color: #409eff;
}

/* 倒计时样式 */
.el-statistic .con .number {
    font-size: 12px;
    color: red;
}

/* .con{
    justify-content: flex-start !important;
} */

/* 从左到右抽屉 */
.el-drawer.ltr {
    background-color: rgba(0, 0, 0, 0.8);
}

/* 抽屉遮罩层 */
.v-modal {
    opacity: 0.1;
}

/* 进度条样式 */
.el-progress__text {
    margin-left: 5px;
    padding: 8px 5px;
    background-color: #999999;
    color: #fff !important;
}

/* 图片预览关闭按钮 */
.el-image-viewer__close {
    top: 60px;
}



/* 页面公共样式 */

.center {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
}

.evenly {
    display: flex;
    align-items: center;
    align-content: center;
    /* justify-content: space-evenly; */
    flex-wrap: wrap;
}

.ellipsis_1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* 页面其他样式 */
.bannarimg {
    width: 66%;
}

.search {
    width: 30%;
}

.productList {
    width: 66%;

}

.productItem {
    width: 23%;
    margin: 0 2% 30px 0;
}



.storeName {
    font-size: 16px;
}

.times {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.status {
    background-color: #333333;
    color: #fff;
    padding: 2px 4px;
    font-size: 10px;
    text-align: center;
}

.total {
    background-color: #efefef;
    padding: 5px 10px;
    color: #888888;
    border-radius: 30px;
    font-size: 14px;
}

.pitch {
    /* background-color: #000; */

    color: #000;
    font-size: 16px;
    /* border-radius: 30px; */
    letter-spacing: 3px;
    /* font-weight: 600; */
    /* border: 1px solid #000; */
    text-align: center;
    width: 50%;
    border-right: 1px solid #999999;
    padding: 5px 0;
}

.pitch:hover,
.attention:hover {
    background-color: #333333;
    color: #fff;
}

.attention {
    width: 50%;
    font-size: 16px;
    color: #000;
    background-color: #fff;
    /* border: 1px solid #000; */
    /* border-radius: 30px; */
    padding: 5px 0px;
    text-align: center;
    /* font-weight: 600; */
    letter-spacing: 3px;
}

.attention:hover,
.pitch:hover,
.zzcBtn:hover,
.submitOrderBtn,
.changePay,
.filter-item,
.goAddAddressBtn,
.changeAddress,
.submitBtn {
    cursor: pointer;
}

.storeBannar {
    background-color: #fff;
}

.store {
    padding: 30px;
}

.storeImg:hover {
    .zzc {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.zzcBtn {
    border: 1px solid #fff;
    padding: 5px 10px;
}

.zzc {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    display: none;
    color: #fff;
}

.storeInfo {
    /* width: 100%; */
    background-color: #fff;
    padding: 30px;
}

.storeInfoName {
    font-size: 24px;
    font-weight: bold;
    color: #333333;
    line-height: 46px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

}

.storeInfoTotal {
    font-size: 28px;

    font-weight: 400;
    color: #333333;
    line-height: 46px;
}

.condition {
    font-size: 20px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #333333;
    margin-top: 20px;
}

.information {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.informationItem {
    text-align: center;
    margin-top: 20px;
}

.informationItem_num {
    font-size: 20px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #333333;
}

.informationItem_title {
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #999999;
}

.tip {
    margin-top: 20px;
}

.tipPrice {
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #999999;
}

.tipNum {
    font-size: 22px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #333333;
}

.submitBtn {
    width: 100%;
    height: 60px;
    text-align: center;
    line-height: 60px;
    background-color: #000;
    font-size: 24px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #FFFFFF;
    margin-top: 40px;


}

.assure {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;
}

.assureItem {
    width: 50%;
    display: flex;
    align-items: center;
    margin-top: 5px;
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #999999;
}

.line {
    width: 100%;
    height: 2px;
    background-color: #fff;
    margin: 30px 0;
}

.productDetail img {
    width: 100%;
}

/* 适配 */

@media (max-width:1550px) {
    .productItem {
        width: 31%;
        margin: 0 2% 30px 0;
    }
}

@media (max-width:1150px) {
    .productItem {
        width: 48%;
        margin: 0 2% 30px 0;
    }
}

@media (max-width:640px) {
    .filter-item {
        margin-right: 10px;
        padding: 8px 10px;
        border-radius: 30px;
    }

    .productItem {
        width: 100%;
        margin: 0 2% 30px 0;
    }

    .search {
        width: 50%;
    }

    .bannarimg {
        width: 100%;
    }

    .bannar {
        padding: 0;
    }
}
</style>

<style scoped>
.filter {
    width: 100%;
    font-size: 14px;
    margin-top: 30px;
}

.filter-list {
    width: 66%;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    background-color: #fff;
}

.filter-item {
    margin-right: 50px;
    display: flex;
    align-items: center;
    color: #333333;
    padding: 5px 15px;
    border-radius: 30px;
}

.filter-icon {
    display: flex;
    flex-direction: column;
    margin-left: 5rpx;
}

.filter-active {
    color: #fff;
    background-color: #000;
}

.drawerOrder {
    margin: 30px;
    background-color: #fff;
    min-height: 1080px;
}

.orderInfoTitle {
    padding: 20px 30px;
    border-bottom: 1px solid #999;
    font-size: 20px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #333333;
}

.orderInfoName {
    font-size: 20px;
    font-weight: bold;
    color: #333333;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

}

.orderProductInfo {
    padding: 30px 0;
    border-bottom: 1px solid #999;
}

.goAddAddressBtn {
    border: 1px solid #999;
    padding: 0 5px;
}

.orderUserAddress {
    padding: 30px;
    border-bottom: 1px solid #999;
    font-size: 20px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #333333;
}

.changeAddress {
    width: 62px;
    /* padding: 0 10px; */
    border: 1px solid #999;
    font-size: 20px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #999999;
    text-align: center;
}


.freight {
    padding: 20px 30px;
    font-size: 20px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #333333;
    border-bottom: 1px solid #999;
}

.orderTotal {
    padding: 20px 30px;
    font-size: 26px;
    font-family: Source Han Sans SC;
    font-weight: bold;
    color: #333333;
    border-bottom: 1px solid #999;
}


.kf {
    padding: 20px;
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #999999;
}

.submitOrder {
    padding: 30px;
}

.submitOrderBtn {
    width: 100%;
    height: 60px;
    background: #000000;
    font-size: 24px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #FFFFFF;
}

.orderInfoDetail {
    margin-top: 10px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
}

.orderInfoNum {
    margin-top: 50px;
    font-size: 22px;
    font-family: Source Han Sans SC;
    font-weight: bold;
    color: #333333;
}

.prompt {
    padding: 20px 30px;
    border-bottom: 1px solid #999;
    font-size: 20px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #333333;
}

.orderProductData {
    padding: 20px 30px;
    border-bottom: 1px solid #999;
    font-size: 20px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #333333;
}


.backHome {
    width: 100%;
    height: 60px;
    background: #000000;
    font-size: 24px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #FFFFFF;
    margin-top: 100px;
}

.completeText {
    margin-top: 50px;
    font-size: 26px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #333333;
    text-align: center;
}

.completeContent {
    margin-top: 50px;
    font-size: 22px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #666666;
    text-align: center;
}

.changePay {
    width: 45%;
    border: 1px solid #000;
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 18px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #333333;
}

.changePay_cl {
    color: #fff;
    background-color: #000;
}

.changePay_cl2 {
    color: #fff;
    background-color: #999999;
    cursor: not-allowed;
    border: none;
}

.container {
    height: 250px;
    /* 设置容器的高度 */
    overflow-y: auto;
    margin-top: 20px;
}

.data-item {
    height: 60px;
    padding: 10px;
    border-bottom: 1px solid #999;
}

.hosPay {
    font-size: 20px;
    color: #000;
}

.hosTime {
    font-size: 14px;
    color: #999;
    margin-top: 5px;
}
</style>