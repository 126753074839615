
let data = [
  {
    "label": "北京市",
    "value": "北京市",
    "children": [
      {
        "label": "北京市",
        "value": "北京市",
        "cityType": "直辖市"
      }
    ]
  },
  {
    "label": "天津市",
    "value": "天津市",
    "children": [
      {
        "label": "天津市",
        "value": "天津市",
        "cityType": "直辖市"
      }
    ]
  },
  {
    "label": "上海市",
    "value": "上海市",
    "children": [
      {
        "label": "上海市",
        "value": "上海市",
        "cityType": "直辖市"
      }
    ]
  },
  {
    "label": "重庆市",
    "value": "重庆市",
    "children": [
      {
        "label": "重庆市",
        "value": "重庆市",
        "cityType": "直辖市"
      }
    ]
  },
  {
    "label": "香港特别行政区",
    "value": "香港特别行政区",
    "children": [
      {
        "label": "香港特别行政区",
        "value": "香港特别行政区",
        "cityType": "特别行政区"
      }
    ]
  },
  {
    "label": "澳门特别行政区",
    "value": "澳门特别行政区",
    "children": [
      {
        "label": "澳门特别行政区",
        "value": "澳门特别行政区",
        "cityType": "特别行政区"
      }
    ]
  },
  {
    "label": "河北省",
    "value": "河北省",
    "children": [
      {
        "label": "石家庄市",
        "value": "石家庄市",
        "cityType": "地级市"
      },
      {
        "label": "唐山市",
        "value": "唐山市",
        "cityType": "地级市"
      },
      {
        "label": "秦皇岛市",
        "value": "秦皇岛市",
        "cityType": "地级市"
      },
      {
        "label": "邯郸市",
        "value": "邯郸市",
        "cityType": "地级市"
      },
      {
        "label": "邢台市",
        "value": "邢台市",
        "cityType": "地级市"
      },
      {
        "label": "保定市",
        "value": "保定市",
        "cityType": "地级市"
      },
      {
        "label": "张家口市",
        "value": "张家口市",
        "cityType": "地级市"
      },
      {
        "label": "承德市",
        "value": "承德市",
        "cityType": "地级市"
      },
      {
        "label": "沧州市",
        "value": "沧州市",
        "cityType": "地级市"
      },
      {
        "label": "廊坊市",
        "value": "廊坊市",
        "cityType": "地级市"
      },
      {
        "label": "衡水市",
        "value": "衡水市",
        "cityType": "地级市"
      },
      {
        "label": "辛集市",
        "value": "辛集市",
        "cityType": "县级市"
      },
      {
        "label": "晋州市",
        "value": "晋州市",
        "cityType": "县级市"
      },
      {
        "label": "新乐市",
        "value": "新乐市",
        "cityType": "县级市"
      },
      {
        "label": "遵化市",
        "value": "遵化市",
        "cityType": "县级市"
      },
      {
        "label": "迁安市",
        "value": "迁安市",
        "cityType": "县级市"
      },
      {
        "label": "武安市",
        "value": "武安市",
        "cityType": "县级市"
      },
      {
        "label": "南宫市",
        "value": "南宫市",
        "cityType": "县级市"
      },
      {
        "label": "沙河市",
        "value": "沙河市",
        "cityType": "县级市"
      },
      {
        "label": "涿州市",
        "value": "涿州市",
        "cityType": "县级市"
      },
      {
        "label": "定州市",
        "value": "定州市",
        "cityType": "县级市"
      },
      {
        "label": "安国市",
        "value": "安国市",
        "cityType": "县级市"
      },
      {
        "label": "高碑店市",
        "value": "高碑店市",
        "cityType": "县级市"
      },
      {
        "label": "平泉市",
        "value": "平泉市",
        "cityType": "县级市"
      },
      {
        "label": "泊头市",
        "value": "泊头市",
        "cityType": "县级市"
      },
      {
        "label": "任丘市",
        "value": "任丘市",
        "cityType": "县级市"
      },
      {
        "label": "黄骅市",
        "value": "黄骅市",
        "cityType": "县级市"
      },
      {
        "label": "河间市",
        "value": "河间市",
        "cityType": "县级市"
      },
      {
        "label": "霸州市",
        "value": "霸州市",
        "cityType": "县级市"
      },
      {
        "label": "三河市",
        "value": "三河市",
        "cityType": "县级市"
      },
      {
        "label": "深州市",
        "value": "深州市",
        "cityType": "县级市"
      }
    ]
  },
  {
    "label": "山西省",
    "value": "山西省",
    "children": [
      {
        "label": "太原市",
        "value": "太原市",
        "cityType": "地级市"
      },
      {
        "label": "大同市",
        "value": "大同市",
        "cityType": "地级市"
      },
      {
        "label": "阳泉市",
        "value": "阳泉市",
        "cityType": "地级市"
      },
      {
        "label": "长治市",
        "value": "长治市",
        "cityType": "地级市"
      },
      {
        "label": "晋城市",
        "value": "晋城市",
        "cityType": "地级市"
      },
      {
        "label": "朔州市",
        "value": "朔州市",
        "cityType": "地级市"
      },
      {
        "label": "晋中市",
        "value": "晋中市",
        "cityType": "地级市"
      },
      {
        "label": "运城市",
        "value": "运城市",
        "cityType": "地级市"
      },
      {
        "label": "忻州市",
        "value": "忻州市",
        "cityType": "地级市"
      },
      {
        "label": "临汾市",
        "value": "临汾市",
        "cityType": "地级市"
      },
      {
        "label": "吕梁市",
        "value": "吕梁市",
        "cityType": "地级市"
      },
      {
        "label": "古交市",
        "value": "古交市",
        "cityType": "县级市"
      },
      {
        "label": "高平市",
        "value": "高平市",
        "cityType": "县级市"
      },
      {
        "label": "介休市",
        "value": "介休市",
        "cityType": "县级市"
      },
      {
        "label": "永济市",
        "value": "永济市",
        "cityType": "县级市"
      },
      {
        "label": "河津市",
        "value": "河津市",
        "cityType": "县级市"
      },
      {
        "label": "原平市",
        "value": "原平市",
        "cityType": "县级市"
      },
      {
        "label": "侯马市",
        "value": "侯马市",
        "cityType": "县级市"
      },
      {
        "label": "霍州市",
        "value": "霍州市",
        "cityType": "县级市"
      },
      {
        "label": "孝义市",
        "value": "孝义市",
        "cityType": "县级市"
      },
      {
        "label": "汾阳市",
        "value": "汾阳市",
        "cityType": "县级市"
      },
      {
        "label": "怀仁市",
        "value": "怀仁市",
        "cityType": "县级市"
      }
    ]
  },
  {
    "label": "内蒙古自治区",
    "value": "内蒙古自治区",
    "children": [
      {
        "label": "呼和浩特市",
        "value": "呼和浩特市",
        "cityType": "地级市"
      },
      {
        "label": "包头市",
        "value": "包头市",
        "cityType": "地级市"
      },
      {
        "label": "乌海市",
        "value": "乌海市",
        "cityType": "地级市"
      },
      {
        "label": "赤峰市",
        "value": "赤峰市",
        "cityType": "地级市"
      },
      {
        "label": "通辽市",
        "value": "通辽市",
        "cityType": "地级市"
      },
      {
        "label": "鄂尔多斯市",
        "value": "鄂尔多斯市",
        "cityType": "地级市"
      },
      {
        "label": "呼伦贝尔市",
        "value": "呼伦贝尔市",
        "cityType": "地级市"
      },
      {
        "label": "巴彦淖尔市",
        "value": "巴彦淖尔市",
        "cityType": "地级市"
      },
      {
        "label": "乌兰察布市",
        "value": "乌兰察布市",
        "cityType": "地级市"
      },
      {
        "label": "霍林郭勒市",
        "value": "霍林郭勒市",
        "cityType": "县级市"
      },
      {
        "label": "满洲里市",
        "value": "满洲里市",
        "cityType": "县级市"
      },
      {
        "label": "牙克石市",
        "value": "牙克石市",
        "cityType": "县级市"
      },
      {
        "label": "扎兰屯市",
        "value": "扎兰屯市",
        "cityType": "县级市"
      },
      {
        "label": "额尔古纳市",
        "value": "额尔古纳市",
        "cityType": "县级市"
      },
      {
        "label": "根河市",
        "value": "根河市",
        "cityType": "县级市"
      },
      {
        "label": "丰镇市",
        "value": "丰镇市",
        "cityType": "县级市"
      },
      {
        "label": "乌兰浩特市",
        "value": "乌兰浩特市",
        "cityType": "县级市"
      },
      {
        "label": "阿尔山市",
        "value": "阿尔山市",
        "cityType": "县级市"
      },
      {
        "label": "二连浩特市",
        "value": "二连浩特市",
        "cityType": "县级市"
      },
      {
        "label": "锡林浩特市",
        "value": "锡林浩特市",
        "cityType": "县级市"
      }
    ]
  },
  {
    "label": "辽宁省",
    "value": "辽宁省",
    "children": [
      {
        "label": "沈阳市",
        "value": "沈阳市",
        "cityType": "地级市"
      },
      {
        "label": "大连市",
        "value": "大连市",
        "cityType": "地级市"
      },
      {
        "label": "鞍山市",
        "value": "鞍山市",
        "cityType": "县级市"
      },
      {
        "label": "抚顺市",
        "value": "抚顺市",
        "cityType": "县级市"
      },
      {
        "label": "本溪市",
        "value": "本溪市",
        "cityType": "县级市"
      },
      {
        "label": "丹东市",
        "value": "丹东市",
        "cityType": "县级市"
      },
      {
        "label": "锦州市",
        "value": "锦州市",
        "cityType": "县级市"
      },
      {
        "label": "营口市",
        "value": "营口市",
        "cityType": "县级市"
      },
      {
        "label": "阜新市",
        "value": "阜新市",
        "cityType": "县级市"
      },
      {
        "label": "辽阳市",
        "value": "辽阳市",
        "cityType": "县级市"
      },
      {
        "label": "盘锦市",
        "value": "盘锦市",
        "cityType": "县级市"
      },
      {
        "label": "铁岭市",
        "value": "铁岭市",
        "cityType": "县级市"
      },
      {
        "label": "朝阳市",
        "value": "朝阳市",
        "cityType": "县级市"
      },
      {
        "label": "葫芦岛市",
        "value": "葫芦岛市",
        "cityType": "县级市"
      },
      {
        "label": "新民市",
        "value": "新民市",
        "cityType": "县级市"
      },
      {
        "label": "瓦房店市",
        "value": "瓦房店市",
        "cityType": "县级市"
      },
      {
        "label": "庄河市",
        "value": "庄河市",
        "cityType": "县级市"
      },
      {
        "label": "海城市",
        "value": "海城市",
        "cityType": "县级市"
      },
      {
        "label": "东港市",
        "value": "东港市",
        "cityType": "县级市"
      },
      {
        "label": "凤城市",
        "value": "凤城市",
        "cityType": "县级市"
      },
      {
        "label": "凌海市",
        "value": "凌海市",
        "cityType": "县级市"
      },
      {
        "label": "北镇市",
        "value": "北镇市",
        "cityType": "县级市"
      },
      {
        "label": "盖州市",
        "value": "盖州市",
        "cityType": "县级市"
      },
      {
        "label": "大石桥市",
        "value": "大石桥市",
        "cityType": "县级市"
      },
      {
        "label": "灯塔市",
        "value": "灯塔市",
        "cityType": "县级市"
      },
      {
        "label": "调兵山市",
        "value": "调兵山市",
        "cityType": "县级市"
      },
      {
        "label": "开原市",
        "value": "开原市",
        "cityType": "县级市"
      },
      {
        "label": "北票市",
        "value": "北票市",
        "cityType": "县级市"
      },
      {
        "label": "凌源市",
        "value": "凌源市",
        "cityType": "县级市"
      },
      {
        "label": "兴城市",
        "value": "兴城市",
        "cityType": "县级市"
      }
    ]
  },
  {
    "label": "吉林省",
    "value": "吉林省",
    "children": [
      {
        "label": "长春市",
        "value": "长春市",
        "cityType": "地级市"
      },
      {
        "label": "吉林市",
        "value": "吉林市",
        "cityType": "县级市"
      },
      {
        "label": "四平市",
        "value": "四平市",
        "cityType": "县级市"
      },
      {
        "label": "辽源市",
        "value": "辽源市",
        "cityType": "县级市"
      },
      {
        "label": "通化市",
        "value": "通化市",
        "cityType": "县级市"
      },
      {
        "label": "白山市",
        "value": "白山市",
        "cityType": "县级市"
      },
      {
        "label": "松原市",
        "value": "松原市",
        "cityType": "县级市"
      },
      {
        "label": "白城市",
        "value": "白城市",
        "cityType": "县级市"
      },
      {
        "label": "榆树市",
        "value": "榆树市",
        "cityType": "县级市"
      },
      {
        "label": "德惠市",
        "value": "德惠市",
        "cityType": "县级市"
      },
      {
        "label": "蛟河市",
        "value": "蛟河市",
        "cityType": "县级市"
      },
      {
        "label": "桦甸市",
        "value": "桦甸市",
        "cityType": "县级市"
      },
      {
        "label": "舒兰市",
        "value": "舒兰市",
        "cityType": "县级市"
      },
      {
        "label": "磐石市",
        "value": "磐石市",
        "cityType": "县级市"
      },
      {
        "label": "公主岭市",
        "value": "公主岭市",
        "cityType": "县级市"
      },
      {
        "label": "双辽市",
        "value": "双辽市",
        "cityType": "县级市"
      },
      {
        "label": "梅河口市",
        "value": "梅河口市",
        "cityType": "县级市"
      },
      {
        "label": "集安市",
        "value": "集安市",
        "cityType": "县级市"
      },
      {
        "label": "洮南市",
        "value": "洮南市",
        "cityType": "县级市"
      },
      {
        "label": "大安市",
        "value": "大安市",
        "cityType": "县级市"
      },
      {
        "label": "临江市",
        "value": "临江市",
        "cityType": "县级市"
      },
      {
        "label": "延吉市",
        "value": "延吉市",
        "cityType": "县级市"
      },
      {
        "label": "图们市",
        "value": "图们市",
        "cityType": "县级市"
      },
      {
        "label": "敦化市",
        "value": "敦化市",
        "cityType": "县级市"
      },
      {
        "label": "珲春市",
        "value": "珲春市",
        "cityType": "县级市"
      },
      {
        "label": "龙井市",
        "value": "龙井市",
        "cityType": "县级市"
      },
      {
        "label": "和龙市",
        "value": "和龙市",
        "cityType": "县级市"
      },
      {
        "label": "扶余市",
        "value": "扶余市",
        "cityType": "县级市"
      }
    ]
  },
  {
    "label": "黑龙江省",
    "value": "黑龙江省",
    "children": [
      {
        "label": "哈尔滨市",
        "value": "哈尔滨市",
        "cityType": "地级市"
      },
      {
        "label": "齐齐哈尔市",
        "value": "齐齐哈尔市",
        "cityType": "县级市"
      },
      {
        "label": "黑河市",
        "value": "黑河市",
        "cityType": "县级市"
      },
      {
        "label": "大庆市",
        "value": "大庆市",
        "cityType": "县级市"
      },
      {
        "label": "伊春市",
        "value": "伊春市",
        "cityType": "县级市"
      },
      {
        "label": "鹤岗市",
        "value": "鹤岗市",
        "cityType": "县级市"
      },
      {
        "label": "佳木斯市",
        "value": "佳木斯市",
        "cityType": "县级市"
      },
      {
        "label": "双鸭山市",
        "value": "双鸭山市",
        "cityType": "县级市"
      },
      {
        "label": "七台河市",
        "value": "七台河市",
        "cityType": "县级市"
      },
      {
        "label": "鸡西市",
        "value": "鸡西市",
        "cityType": "县级市"
      },
      {
        "label": "牡丹江市",
        "value": "牡丹江市",
        "cityType": "县级市"
      },
      {
        "label": "绥化市",
        "value": "绥化市",
        "cityType": "县级市"
      },
      {
        "label": "尚志市",
        "value": "尚志市",
        "cityType": "县级市"
      },
      {
        "label": "五常市",
        "value": "五常市",
        "cityType": "县级市"
      },
      {
        "label": "讷河市",
        "value": "讷河市",
        "cityType": "县级市"
      },
      {
        "label": "北安市",
        "value": "北安市",
        "cityType": "县级市"
      },
      {
        "label": "五大连池市",
        "value": "五大连池市",
        "cityType": "县级市"
      },
      {
        "label": "嫩江市",
        "value": "嫩江市",
        "cityType": "县级市"
      },
      {
        "label": "铁力市",
        "value": "铁力市",
        "cityType": "县级市"
      },
      {
        "label": "同江市",
        "value": "同江市",
        "cityType": "县级市"
      },
      {
        "label": "富锦市",
        "value": "富锦市",
        "cityType": "县级市"
      },
      {
        "label": "虎林市",
        "value": "虎林市",
        "cityType": "县级市"
      },
      {
        "label": "密山市",
        "value": "密山市",
        "cityType": "县级市"
      },
      {
        "label": "绥芬河市",
        "value": "绥芬河市",
        "cityType": "县级市"
      },
      {
        "label": "海林市",
        "value": "海林市",
        "cityType": "县级市"
      },
      {
        "label": "宁安市",
        "value": "宁安市",
        "cityType": "县级市"
      },
      {
        "label": "安达市",
        "value": "安达市",
        "cityType": "县级市"
      },
      {
        "label": "肇东市",
        "value": "肇东市",
        "cityType": "县级市"
      },
      {
        "label": "海伦市",
        "value": "海伦市",
        "cityType": "县级市"
      },
      {
        "label": "穆棱市",
        "value": "穆棱市",
        "cityType": "县级市"
      },
      {
        "label": "东宁市",
        "value": "东宁市",
        "cityType": "县级市"
      },
      {
        "label": "抚远市",
        "value": "抚远市",
        "cityType": "县级市"
      },
      {
        "label": "漠河市",
        "value": "漠河市",
        "cityType": "县级市"
      }
    ]
  },
  {
    "label": "江苏省",
    "value": "江苏省",
    "children": [
      {
        "label": "南京市",
        "value": "南京市",
        "cityType": "地级市"
      },
      {
        "label": "徐州市",
        "value": "徐州市",
        "cityType": "县级市"
      },
      {
        "label": "连云港市",
        "value": "连云港市",
        "cityType": "县级市"
      },
      {
        "label": "宿迁市",
        "value": "宿迁市",
        "cityType": "县级市"
      },
      {
        "label": "淮安市",
        "value": "淮安市",
        "cityType": "县级市"
      },
      {
        "label": "盐城市",
        "value": "盐城市",
        "cityType": "县级市"
      },
      {
        "label": "扬州市",
        "value": "扬州市",
        "cityType": "县级市"
      },
      {
        "label": "泰州市",
        "value": "泰州市",
        "cityType": "县级市"
      },
      {
        "label": "南通市",
        "value": "南通市",
        "cityType": "县级市"
      },
      {
        "label": "镇江市",
        "value": "镇江市",
        "cityType": "县级市"
      },
      {
        "label": "常州市",
        "value": "常州市",
        "cityType": "县级市"
      },
      {
        "label": "无锡市",
        "value": "无锡市",
        "cityType": "县级市"
      },
      {
        "label": "苏州市",
        "value": "苏州市",
        "cityType": "县级市"
      },
      {
        "label": "常熟市",
        "value": "常熟市",
        "cityType": "县级市"
      },
      {
        "label": "张家港市",
        "value": "张家港市",
        "cityType": "县级市"
      },
      {
        "label": "太仓市",
        "value": "太仓市",
        "cityType": "县级市"
      },
      {
        "label": "昆山市",
        "value": "昆山市",
        "cityType": "县级市"
      },
      {
        "label": "江阴市",
        "value": "江阴市",
        "cityType": "县级市"
      },
      {
        "label": "宜兴市",
        "value": "宜兴市",
        "cityType": "县级市"
      },
      {
        "label": "溧阳市",
        "value": "溧阳市",
        "cityType": "县级市"
      },
      {
        "label": "扬中市",
        "value": "扬中市",
        "cityType": "县级市"
      },
      {
        "label": "句容市",
        "value": "句容市",
        "cityType": "县级市"
      },
      {
        "label": "丹阳市",
        "value": "丹阳市",
        "cityType": "县级市"
      },
      {
        "label": "如皋市",
        "value": "如皋市",
        "cityType": "县级市"
      },
      {
        "label": "启东市",
        "value": "启东市",
        "cityType": "县级市"
      },
      {
        "label": "海安市",
        "value": "海安市",
        "cityType": "县级市"
      },
      {
        "label": "高邮市",
        "value": "高邮市",
        "cityType": "县级市"
      },
      {
        "label": "仪征市",
        "value": "仪征市",
        "cityType": "县级市"
      },
      {
        "label": "兴化市",
        "value": "兴化市",
        "cityType": "县级市"
      },
      {
        "label": "泰兴市",
        "value": "泰兴市",
        "cityType": "县级市"
      },
      {
        "label": "靖江市",
        "value": "靖江市",
        "cityType": "县级市"
      },
      {
        "label": "东台市",
        "value": "东台市",
        "cityType": "县级市"
      },
      {
        "label": "邳州市",
        "value": "邳州市",
        "cityType": "县级市"
      },
      {
        "label": "新沂市",
        "value": "新沂市",
        "cityType": "县级市"
      }
    ]
  },
  {
    "label": "浙江省",
    "value": "浙江省",
    "children": [
      {
        "label": "杭州市",
        "value": "杭州市",
        "cityType": "地级市"
      },
      {
        "label": "宁波市",
        "value": "宁波市",
        "cityType": "地级市"
      },
      {
        "label": "湖州市",
        "value": "湖州市",
        "cityType": "县级市"
      },
      {
        "label": "嘉兴市",
        "value": "嘉兴市",
        "cityType": "县级市"
      },
      {
        "label": "舟山市",
        "value": "舟山市",
        "cityType": "县级市"
      },
      {
        "label": "绍兴市",
        "value": "绍兴市",
        "cityType": "县级市"
      },
      {
        "label": "衢州市",
        "value": "衢州市",
        "cityType": "县级市"
      },
      {
        "label": "金华市",
        "value": "金华市",
        "cityType": "县级市"
      },
      {
        "label": "台州市",
        "value": "台州市",
        "cityType": "县级市"
      },
      {
        "label": "温州市",
        "value": "温州市",
        "cityType": "县级市"
      },
      {
        "label": "丽水市",
        "value": "丽水市",
        "cityType": "县级市"
      },
      {
        "label": "建德市",
        "value": "建德市",
        "cityType": "县级市"
      },
      {
        "label": "慈溪市",
        "value": "慈溪市",
        "cityType": "县级市"
      },
      {
        "label": "余姚市",
        "value": "余姚市",
        "cityType": "县级市"
      },
      {
        "label": "平湖市",
        "value": "平湖市",
        "cityType": "县级市"
      },
      {
        "label": "海宁市",
        "value": "海宁市",
        "cityType": "县级市"
      },
      {
        "label": "桐乡市",
        "value": "桐乡市",
        "cityType": "县级市"
      },
      {
        "label": "诸暨市",
        "value": "诸暨市",
        "cityType": "县级市"
      },
      {
        "label": "嵊州市",
        "value": "嵊州市",
        "cityType": "县级市"
      },
      {
        "label": "江山市",
        "value": "江山市",
        "cityType": "县级市"
      },
      {
        "label": "兰溪市",
        "value": "兰溪市",
        "cityType": "县级市"
      },
      {
        "label": "永康市",
        "value": "永康市",
        "cityType": "县级市"
      },
      {
        "label": "义乌市",
        "value": "义乌市",
        "cityType": "县级市"
      },
      {
        "label": "东阳市",
        "value": "东阳市",
        "cityType": "县级市"
      },
      {
        "label": "临海市",
        "value": "临海市",
        "cityType": "县级市"
      },
      {
        "label": "温岭市",
        "value": "温岭市",
        "cityType": "县级市"
      },
      {
        "label": "瑞安市",
        "value": "瑞安市",
        "cityType": "县级市"
      },
      {
        "label": "乐清市",
        "value": "乐清市",
        "cityType": "县级市"
      },
      {
        "label": "龙港市",
        "value": "龙港市",
        "cityType": "县级市"
      },
      {
        "label": "龙泉市",
        "value": "龙泉市",
        "cityType": "县级市"
      },
      {
        "label": "玉环市",
        "value": "玉环市",
        "cityType": "县级市"
      }
    ]
  },
  {
    "label": "安徽省",
    "value": "安徽省",
    "children": [
      {
        "label": "合肥市",
        "value": "合肥市",
        "cityType": "地级市"
      },
      {
        "label": "芜湖市",
        "value": "芜湖市",
        "cityType": "地级市"
      },
      {
        "label": "蚌埠市",
        "value": "蚌埠市",
        "cityType": "地级市"
      },
      {
        "label": "淮南市",
        "value": "淮南市",
        "cityType": "地级市"
      },
      {
        "label": "马鞍山市",
        "value": "马鞍山市",
        "cityType": "地级市"
      },
      {
        "label": "淮北市",
        "value": "淮北市",
        "cityType": "地级市"
      },
      {
        "label": "铜陵市",
        "value": "铜陵市",
        "cityType": "地级市"
      },
      {
        "label": "安庆市",
        "value": "安庆市",
        "cityType": "地级市"
      },
      {
        "label": "黄山市",
        "value": "黄山市",
        "cityType": "地级市"
      },
      {
        "label": "滁州市",
        "value": "滁州市",
        "cityType": "地级市"
      },
      {
        "label": "阜阳市",
        "value": "阜阳市",
        "cityType": "地级市"
      },
      {
        "label": "宿州市",
        "value": "宿州市",
        "cityType": "地级市"
      },
      {
        "label": "六安市",
        "value": "六安市",
        "cityType": "地级市"
      },
      {
        "label": "亳州市",
        "value": "亳州市",
        "cityType": "地级市"
      },
      {
        "label": "池州市",
        "value": "池州市",
        "cityType": "地级市"
      },
      {
        "label": "宣城市",
        "value": "宣城市",
        "cityType": "地级市"
      },
      {
        "label": "巢湖市",
        "value": "巢湖市",
        "cityType": "县级市"
      },
      {
        "label": "桐城市",
        "value": "桐城市",
        "cityType": "县级市"
      },
      {
        "label": "天长市",
        "value": "天长市",
        "cityType": "县级市"
      },
      {
        "label": "明光市",
        "value": "明光市",
        "cityType": "县级市"
      },
      {
        "label": "界首市",
        "value": "界首市",
        "cityType": "县级市"
      },
      {
        "label": "宁国市",
        "value": "宁国市",
        "cityType": "县级市"
      },
      {
        "label": "广德市",
        "value": "广德市",
        "cityType": "县级市"
      },
      {
        "label": "潜山市",
        "value": "潜山市",
        "cityType": "县级市"
      },
      {
        "label": "无为市",
        "value": "无为市",
        "cityType": "县级市"
      }
    ]
  },
  {
    "label": "福建省",
    "value": "福建省",
    "children": [
      {
        "label": "厦门市",
        "value": "厦门市",
        "cityType": "地级市"
      },
      {
        "label": "福州市",
        "value": "福州市",
        "cityType": "县级市"
      },
      {
        "label": "南平市",
        "value": "南平市",
        "cityType": "县级市"
      },
      {
        "label": "三明市",
        "value": "三明市",
        "cityType": "县级市"
      },
      {
        "label": "莆田市",
        "value": "莆田市",
        "cityType": "县级市"
      },
      {
        "label": "泉州市",
        "value": "泉州市",
        "cityType": "县级市"
      },
      {
        "label": "漳州市",
        "value": "漳州市",
        "cityType": "县级市"
      },
      {
        "label": "龙岩市",
        "value": "龙岩市",
        "cityType": "县级市"
      },
      {
        "label": "宁德市",
        "value": "宁德市",
        "cityType": "县级市"
      },
      {
        "label": "福清市",
        "value": "福清市",
        "cityType": "县级市"
      },
      {
        "label": "邵武市",
        "value": "邵武市",
        "cityType": "县级市"
      },
      {
        "label": "武夷山市",
        "value": "武夷山市",
        "cityType": "县级市"
      },
      {
        "label": "建瓯市",
        "value": "建瓯市",
        "cityType": "县级市"
      },
      {
        "label": "永安市",
        "value": "永安市",
        "cityType": "县级市"
      },
      {
        "label": "石狮市",
        "value": "石狮市",
        "cityType": "县级市"
      },
      {
        "label": "晋江市",
        "value": "晋江市",
        "cityType": "县级市"
      },
      {
        "label": "南安市",
        "value": "南安市",
        "cityType": "县级市"
      },
      {
        "label": "龙海市",
        "value": "龙海市",
        "cityType": "县级市"
      },
      {
        "label": "漳平市",
        "value": "漳平市",
        "cityType": "县级市"
      },
      {
        "label": "福安市",
        "value": "福安市",
        "cityType": "县级市"
      },
      {
        "label": "福鼎市",
        "value": "福鼎市",
        "cityType": "县级市"
      }
    ]
  },
  {
    "label": "江西省",
    "value": "江西省",
    "children": [
      {
        "label": "南昌市",
        "value": "南昌市",
        "cityType": "地级市"
      },
      {
        "label": "九江市",
        "value": "九江市",
        "cityType": "地级市"
      },
      {
        "label": "景德镇市",
        "value": "景德镇市",
        "cityType": "地级市"
      },
      {
        "label": "鹰潭市",
        "value": "鹰潭市",
        "cityType": "地级市"
      },
      {
        "label": "新余市",
        "value": "新余市",
        "cityType": "地级市"
      },
      {
        "label": "萍乡市",
        "value": "萍乡市",
        "cityType": "地级市"
      },
      {
        "label": "赣州市",
        "value": "赣州市",
        "cityType": "地级市"
      },
      {
        "label": "上饶市",
        "value": "上饶市",
        "cityType": "地级市"
      },
      {
        "label": "抚州市",
        "value": "抚州市",
        "cityType": "地级市"
      },
      {
        "label": "宜春市",
        "value": "宜春市",
        "cityType": "地级市"
      },
      {
        "label": "吉安市",
        "value": "吉安市",
        "cityType": "地级市"
      },
      {
        "label": "瑞昌市",
        "value": "瑞昌市",
        "cityType": "县级市"
      },
      {
        "label": "共青城市",
        "value": "共青城市",
        "cityType": "县级市"
      },
      {
        "label": "庐山市",
        "value": "庐山市",
        "cityType": "县级市"
      },
      {
        "label": "乐平市",
        "value": "乐平市",
        "cityType": "县级市"
      },
      {
        "label": "瑞金市",
        "value": "瑞金市",
        "cityType": "县级市"
      },
      {
        "label": "德兴市",
        "value": "德兴市",
        "cityType": "县级市"
      },
      {
        "label": "丰城市",
        "value": "丰城市",
        "cityType": "县级市"
      },
      {
        "label": "樟树市",
        "value": "樟树市",
        "cityType": "县级市"
      },
      {
        "label": "高安市",
        "value": "高安市",
        "cityType": "县级市"
      },
      {
        "label": "井冈山市",
        "value": "井冈山市",
        "cityType": "县级市"
      },
      {
        "label": "贵溪市",
        "value": "贵溪市",
        "cityType": "县级市"
      }
    ]
  },
  {
    "label": "山东省",
    "value": "山东省",
    "children": [
      {
        "label": "济南市",
        "value": "济南市",
        "cityType": "地级市"
      },
      {
        "label": "青岛市",
        "value": "青岛市",
        "cityType": "地级市"
      },
      {
        "label": "聊城市",
        "value": "聊城市",
        "cityType": "县级市"
      },
      {
        "label": "德州市",
        "value": "德州市",
        "cityType": "县级市"
      },
      {
        "label": "东营市",
        "value": "东营市",
        "cityType": "县级市"
      },
      {
        "label": "淄博市",
        "value": "淄博市",
        "cityType": "县级市"
      },
      {
        "label": "潍坊市",
        "value": "潍坊市",
        "cityType": "县级市"
      },
      {
        "label": "烟台市",
        "value": "烟台市",
        "cityType": "县级市"
      },
      {
        "label": "威海市",
        "value": "威海市",
        "cityType": "县级市"
      },
      {
        "label": "日照市",
        "value": "日照市",
        "cityType": "县级市"
      },
      {
        "label": "临沂市",
        "value": "临沂市",
        "cityType": "县级市"
      },
      {
        "label": "枣庄市",
        "value": "枣庄市",
        "cityType": "县级市"
      },
      {
        "label": "济宁市",
        "value": "济宁市",
        "cityType": "县级市"
      },
      {
        "label": "泰安市",
        "value": "泰安市",
        "cityType": "县级市"
      },
      {
        "label": "滨州市",
        "value": "滨州市",
        "cityType": "县级市"
      },
      {
        "label": "菏泽市",
        "value": "菏泽市",
        "cityType": "县级市"
      },
      {
        "label": "胶州市",
        "value": "胶州市",
        "cityType": "县级市"
      },
      {
        "label": "平度市",
        "value": "平度市",
        "cityType": "县级市"
      },
      {
        "label": "莱西市",
        "value": "莱西市",
        "cityType": "县级市"
      },
      {
        "label": "临清市",
        "value": "临清市",
        "cityType": "县级市"
      },
      {
        "label": "乐陵市",
        "value": "乐陵市",
        "cityType": "县级市"
      },
      {
        "label": "禹城市",
        "value": "禹城市",
        "cityType": "县级市"
      },
      {
        "label": "安丘市",
        "value": "安丘市",
        "cityType": "县级市"
      },
      {
        "label": "昌邑市",
        "value": "昌邑市",
        "cityType": "县级市"
      },
      {
        "label": "高密市",
        "value": "高密市",
        "cityType": "县级市"
      },
      {
        "label": "青州市",
        "value": "青州市",
        "cityType": "县级市"
      },
      {
        "label": "诸城市",
        "value": "诸城市",
        "cityType": "县级市"
      },
      {
        "label": "寿光市",
        "value": "寿光市",
        "cityType": "县级市"
      },
      {
        "label": "栖霞市",
        "value": "栖霞市",
        "cityType": "县级市"
      },
      {
        "label": "海阳市",
        "value": "海阳市",
        "cityType": "县级市"
      },
      {
        "label": "龙口市",
        "value": "龙口市",
        "cityType": "县级市"
      },
      {
        "label": "莱阳市",
        "value": "莱阳市",
        "cityType": "县级市"
      },
      {
        "label": "莱州市",
        "value": "莱州市",
        "cityType": "县级市"
      },
      {
        "label": "蓬莱市",
        "value": "蓬莱市",
        "cityType": "县级市"
      },
      {
        "label": "招远市",
        "value": "招远市",
        "cityType": "县级市"
      },
      {
        "label": "荣成市",
        "value": "荣成市",
        "cityType": "县级市"
      },
      {
        "label": "乳山市",
        "value": "乳山市",
        "cityType": "县级市"
      },
      {
        "label": "滕州市",
        "value": "滕州市",
        "cityType": "县级市"
      },
      {
        "label": "曲阜市",
        "value": "曲阜市",
        "cityType": "县级市"
      },
      {
        "label": "邹城市",
        "value": "邹城市",
        "cityType": "县级市"
      },
      {
        "label": "新泰市",
        "value": "新泰市",
        "cityType": "县级市"
      },
      {
        "label": "肥城市",
        "value": "肥城市",
        "cityType": "县级市"
      },
      {
        "label": "邹平市",
        "value": "邹平市",
        "cityType": "县级市"
      }
    ]
  },
  {
    "label": "河南省",
    "value": "河南省",
    "children": [
      {
        "label": "郑州市",
        "value": "郑州市",
        "cityType": "地级市"
      },
      {
        "label": "开封市",
        "value": "开封市",
        "cityType": "地级市"
      },
      {
        "label": "洛阳市",
        "value": "洛阳市",
        "cityType": "地级市"
      },
      {
        "label": "平顶山市",
        "value": "平顶山市",
        "cityType": "地级市"
      },
      {
        "label": "安阳市",
        "value": "安阳市",
        "cityType": "地级市"
      },
      {
        "label": "鹤壁市",
        "value": "鹤壁市",
        "cityType": "地级市"
      },
      {
        "label": "新乡市",
        "value": "新乡市",
        "cityType": "地级市"
      },
      {
        "label": "焦作市",
        "value": "焦作市",
        "cityType": "地级市"
      },
      {
        "label": "濮阳市",
        "value": "濮阳市",
        "cityType": "地级市"
      },
      {
        "label": "许昌市",
        "value": "许昌市",
        "cityType": "地级市"
      },
      {
        "label": "漯河市",
        "value": "漯河市",
        "cityType": "地级市"
      },
      {
        "label": "三门峡市",
        "value": "三门峡市",
        "cityType": "地级市"
      },
      {
        "label": "南阳市",
        "value": "南阳市",
        "cityType": "地级市"
      },
      {
        "label": "商丘市",
        "value": "商丘市",
        "cityType": "地级市"
      },
      {
        "label": "周口市",
        "value": "周口市",
        "cityType": "地级市"
      },
      {
        "label": "驻马店市",
        "value": "驻马店市",
        "cityType": "地级市"
      },
      {
        "label": "信阳市",
        "value": "信阳市",
        "cityType": "地级市"
      },
      {
        "label": "荥阳市",
        "value": "荥阳市",
        "cityType": "县级市"
      },
      {
        "label": "新郑市",
        "value": "新郑市",
        "cityType": "县级市"
      },
      {
        "label": "登封市",
        "value": "登封市",
        "cityType": "县级市"
      },
      {
        "label": "新密市",
        "value": "新密市",
        "cityType": "县级市"
      },
      {
        "label": "偃师市",
        "value": "偃师市",
        "cityType": "县级市"
      },
      {
        "label": "孟州市",
        "value": "孟州市",
        "cityType": "县级市"
      },
      {
        "label": "沁阳市",
        "value": "沁阳市",
        "cityType": "县级市"
      },
      {
        "label": "卫辉市",
        "value": "卫辉市",
        "cityType": "县级市"
      },
      {
        "label": "辉县市",
        "value": "辉县市",
        "cityType": "县级市"
      },
      {
        "label": "长垣市",
        "value": "长垣市",
        "cityType": "县级市"
      },
      {
        "label": "林州市",
        "value": "林州市",
        "cityType": "县级市"
      },
      {
        "label": "禹州市",
        "value": "禹州市",
        "cityType": "县级市"
      },
      {
        "label": "长葛市",
        "value": "长葛市",
        "cityType": "县级市"
      },
      {
        "label": "舞钢市",
        "value": "舞钢市",
        "cityType": "县级市"
      },
      {
        "label": "义马市",
        "value": "义马市",
        "cityType": "县级市"
      },
      {
        "label": "灵宝市",
        "value": "灵宝市",
        "cityType": "县级市"
      },
      {
        "label": "项城市",
        "value": "项城市",
        "cityType": "县级市"
      },
      {
        "label": "巩义市",
        "value": "巩义市",
        "cityType": "县级市"
      },
      {
        "label": "邓州市",
        "value": "邓州市",
        "cityType": "县级市"
      },
      {
        "label": "永城市",
        "value": "永城市",
        "cityType": "县级市"
      },
      {
        "label": "汝州市",
        "value": "汝州市",
        "cityType": "县级市"
      },
      {
        "label": "济源市",
        "value": "济源市",
        "cityType": "县级市"
      }
    ]
  },
  {
    "label": "湖北省",
    "value": "湖北省",
    "children": [
      {
        "label": "武汉市",
        "value": "武汉市",
        "cityType": "地级市"
      },
      {
        "label": "十堰市",
        "value": "十堰市",
        "cityType": "县级市"
      },
      {
        "label": "襄阳市",
        "value": "襄阳市",
        "cityType": "县级市"
      },
      {
        "label": "荆门市",
        "value": "荆门市",
        "cityType": "县级市"
      },
      {
        "label": "孝感市",
        "value": "孝感市",
        "cityType": "县级市"
      },
      {
        "label": "黄冈市",
        "value": "黄冈市",
        "cityType": "县级市"
      },
      {
        "label": "鄂州市",
        "value": "鄂州市",
        "cityType": "县级市"
      },
      {
        "label": "黄石市",
        "value": "黄石市",
        "cityType": "县级市"
      },
      {
        "label": "咸宁市",
        "value": "咸宁市",
        "cityType": "县级市"
      },
      {
        "label": "荆州市",
        "value": "荆州市",
        "cityType": "县级市"
      },
      {
        "label": "宜昌市",
        "value": "宜昌市",
        "cityType": "县级市"
      },
      {
        "label": "随州市",
        "value": "随州市",
        "cityType": "县级市"
      },
      {
        "label": "丹江口市",
        "value": "丹江口市",
        "cityType": "县级市"
      },
      {
        "label": "老河口市",
        "value": "老河口市",
        "cityType": "县级市"
      },
      {
        "label": "枣阳市",
        "value": "枣阳市",
        "cityType": "县级市"
      },
      {
        "label": "宜城市",
        "value": "宜城市",
        "cityType": "县级市"
      },
      {
        "label": "钟祥市",
        "value": "钟祥市",
        "cityType": "县级市"
      },
      {
        "label": "京山市",
        "value": "京山市",
        "cityType": "县级市"
      },
      {
        "label": "汉川市",
        "value": "汉川市",
        "cityType": "县级市"
      },
      {
        "label": "应城市",
        "value": "应城市",
        "cityType": "县级市"
      },
      {
        "label": "安陆市",
        "value": "安陆市",
        "cityType": "县级市"
      },
      {
        "label": "广水市",
        "value": "广水市",
        "cityType": "县级市"
      },
      {
        "label": "麻城市",
        "value": "麻城市",
        "cityType": "县级市"
      },
      {
        "label": "武穴市",
        "value": "武穴市",
        "cityType": "县级市"
      },
      {
        "label": "大冶市",
        "value": "大冶市",
        "cityType": "县级市"
      },
      {
        "label": "赤壁市",
        "value": "赤壁市",
        "cityType": "县级市"
      },
      {
        "label": "石首市",
        "value": "石首市",
        "cityType": "县级市"
      },
      {
        "label": "洪湖市",
        "value": "洪湖市",
        "cityType": "县级市"
      },
      {
        "label": "松滋市",
        "value": "松滋市",
        "cityType": "县级市"
      },
      {
        "label": "宜都市",
        "value": "宜都市",
        "cityType": "县级市"
      },
      {
        "label": "枝江市",
        "value": "枝江市",
        "cityType": "县级市"
      },
      {
        "label": "当阳市",
        "value": "当阳市",
        "cityType": "县级市"
      },
      {
        "label": "恩施市",
        "value": "恩施市",
        "cityType": "县级市"
      },
      {
        "label": "利川市",
        "value": "利川市",
        "cityType": "县级市"
      },
      {
        "label": "仙桃市",
        "value": "仙桃市",
        "cityType": "县级市"
      },
      {
        "label": "天门市",
        "value": "天门市",
        "cityType": "县级市"
      },
      {
        "label": "潜江市",
        "value": "潜江市",
        "cityType": "县级市"
      }
    ]
  },
  {
    "label": "湖南省",
    "value": "湖南省",
    "children": [
      {
        "label": "长沙市",
        "value": "长沙市",
        "cityType": "地级市"
      },
      {
        "label": "衡阳市",
        "value": "衡阳市",
        "cityType": "地级市"
      },
      {
        "label": "张家界市",
        "value": "张家界市",
        "cityType": "地级市"
      },
      {
        "label": "常德市",
        "value": "常德市",
        "cityType": "地级市"
      },
      {
        "label": "益阳市",
        "value": "益阳市",
        "cityType": "地级市"
      },
      {
        "label": "岳阳市",
        "value": "岳阳市",
        "cityType": "地级市"
      },
      {
        "label": "株洲市",
        "value": "株洲市",
        "cityType": "地级市"
      },
      {
        "label": "湘潭市",
        "value": "湘潭市",
        "cityType": "地级市"
      },
      {
        "label": "郴州市",
        "value": "郴州市",
        "cityType": "地级市"
      },
      {
        "label": "永州市",
        "value": "永州市",
        "cityType": "地级市"
      },
      {
        "label": "邵阳市",
        "value": "邵阳市",
        "cityType": "地级市"
      },
      {
        "label": "怀化市",
        "value": "怀化市",
        "cityType": "地级市"
      },
      {
        "label": "娄底市",
        "value": "娄底市",
        "cityType": "地级市"
      },
      {
        "label": "耒阳市",
        "value": "耒阳市",
        "cityType": "县级市"
      },
      {
        "label": "常宁市",
        "value": "常宁市",
        "cityType": "县级市"
      },
      {
        "label": "浏阳市",
        "value": "浏阳市",
        "cityType": "县级市"
      },
      {
        "label": "津市市",
        "value": "津市市",
        "cityType": "县级市"
      },
      {
        "label": "沅江市",
        "value": "沅江市",
        "cityType": "县级市"
      },
      {
        "label": "汨罗市",
        "value": "汨罗市",
        "cityType": "县级市"
      },
      {
        "label": "临湘市",
        "value": "临湘市",
        "cityType": "县级市"
      },
      {
        "label": "醴陵市",
        "value": "醴陵市",
        "cityType": "县级市"
      },
      {
        "label": "湘乡市",
        "value": "湘乡市",
        "cityType": "县级市"
      },
      {
        "label": "韶山市",
        "value": "韶山市",
        "cityType": "县级市"
      },
      {
        "label": "资兴市",
        "value": "资兴市",
        "cityType": "县级市"
      },
      {
        "label": "武冈市",
        "value": "武冈市",
        "cityType": "县级市"
      },
      {
        "label": "邵东市",
        "value": "邵东市",
        "cityType": "县级市"
      },
      {
        "label": "洪江市",
        "value": "洪江市",
        "cityType": "县级市"
      },
      {
        "label": "冷水江市",
        "value": "冷水江市",
        "cityType": "县级市"
      },
      {
        "label": "涟源市",
        "value": "涟源市",
        "cityType": "县级市"
      },
      {
        "label": "吉首市",
        "value": "吉首市",
        "cityType": "县级市"
      },
      {
        "label": "宁乡市",
        "value": "宁乡市",
        "cityType": "县级市"
      }
    ]
  },
  {
    "label": "广东省",
    "value": "广东省",
    "children": [
      {
        "label": "广州市",
        "value": "广州市",
        "cityType": "地级市"
      },
      {
        "label": "深圳市",
        "value": "深圳市",
        "cityType": "地级市"
      },
      {
        "label": "清远市",
        "value": "清远市",
        "cityType": "县级市"
      },
      {
        "label": "韶关市",
        "value": "韶关市",
        "cityType": "县级市"
      },
      {
        "label": "河源市",
        "value": "河源市",
        "cityType": "县级市"
      },
      {
        "label": "梅州市",
        "value": "梅州市",
        "cityType": "县级市"
      },
      {
        "label": "潮州市",
        "value": "潮州市",
        "cityType": "县级市"
      },
      {
        "label": "汕头市",
        "value": "汕头市",
        "cityType": "县级市"
      },
      {
        "label": "揭阳市",
        "value": "揭阳市",
        "cityType": "县级市"
      },
      {
        "label": "汕尾市",
        "value": "汕尾市",
        "cityType": "县级市"
      },
      {
        "label": "惠州市",
        "value": "惠州市",
        "cityType": "县级市"
      },
      {
        "label": "东莞市",
        "value": "东莞市",
        "cityType": "县级市"
      },
      {
        "label": "珠海市",
        "value": "珠海市",
        "cityType": "县级市"
      },
      {
        "label": "中山市",
        "value": "中山市",
        "cityType": "县级市"
      },
      {
        "label": "江门市",
        "value": "江门市",
        "cityType": "县级市"
      },
      {
        "label": "佛山市",
        "value": "佛山市",
        "cityType": "县级市"
      },
      {
        "label": "肇庆市",
        "value": "肇庆市",
        "cityType": "县级市"
      },
      {
        "label": "云浮市",
        "value": "云浮市",
        "cityType": "县级市"
      },
      {
        "label": "阳江市",
        "value": "阳江市",
        "cityType": "县级市"
      },
      {
        "label": "茂名市",
        "value": "茂名市",
        "cityType": "县级市"
      },
      {
        "label": "湛江市",
        "value": "湛江市",
        "cityType": "县级市"
      },
      {
        "label": "英德市",
        "value": "英德市",
        "cityType": "县级市"
      },
      {
        "label": "连州市",
        "value": "连州市",
        "cityType": "县级市"
      },
      {
        "label": "乐昌市",
        "value": "乐昌市",
        "cityType": "县级市"
      },
      {
        "label": "南雄市",
        "value": "南雄市",
        "cityType": "县级市"
      },
      {
        "label": "兴宁市",
        "value": "兴宁市",
        "cityType": "县级市"
      },
      {
        "label": "普宁市",
        "value": "普宁市",
        "cityType": "县级市"
      },
      {
        "label": "陆丰市",
        "value": "陆丰市",
        "cityType": "县级市"
      },
      {
        "label": "恩平市",
        "value": "恩平市",
        "cityType": "县级市"
      },
      {
        "label": "台山市",
        "value": "台山市",
        "cityType": "县级市"
      },
      {
        "label": "开平市",
        "value": "开平市",
        "cityType": "县级市"
      },
      {
        "label": "鹤山市",
        "value": "鹤山市",
        "cityType": "县级市"
      },
      {
        "label": "四会市",
        "value": "四会市",
        "cityType": "县级市"
      },
      {
        "label": "罗定市",
        "value": "罗定市",
        "cityType": "县级市"
      },
      {
        "label": "阳春市",
        "value": "阳春市",
        "cityType": "县级市"
      },
      {
        "label": "化州市",
        "value": "化州市",
        "cityType": "县级市"
      },
      {
        "label": "信宜市",
        "value": "信宜市",
        "cityType": "县级市"
      },
      {
        "label": "高州市",
        "value": "高州市",
        "cityType": "县级市"
      },
      {
        "label": "吴川市",
        "value": "吴川市",
        "cityType": "县级市"
      },
      {
        "label": "廉江市",
        "value": "廉江市",
        "cityType": "县级市"
      },
      {
        "label": "雷州市",
        "value": "雷州市",
        "cityType": "县级市"
      }
    ]
  },
  {
    "label": "广西壮族自治区",
    "value": "广西壮族自治区",
    "children": [
      {
        "label": "南宁市",
        "value": "南宁市",
        "cityType": "地级市"
      },
      {
        "label": "桂林市",
        "value": "桂林市",
        "cityType": "地级市"
      },
      {
        "label": "柳州市",
        "value": "柳州市",
        "cityType": "地级市"
      },
      {
        "label": "梧州市",
        "value": "梧州市",
        "cityType": "地级市"
      },
      {
        "label": "贵港市",
        "value": "贵港市",
        "cityType": "地级市"
      },
      {
        "label": "玉林市",
        "value": "玉林市",
        "cityType": "地级市"
      },
      {
        "label": "钦州市",
        "value": "钦州市",
        "cityType": "地级市"
      },
      {
        "label": "北海市",
        "value": "北海市",
        "cityType": "地级市"
      },
      {
        "label": "防城港市",
        "value": "防城港市",
        "cityType": "地级市"
      },
      {
        "label": "崇左市",
        "value": "崇左市",
        "cityType": "地级市"
      },
      {
        "label": "百色市",
        "value": "百色市",
        "cityType": "地级市"
      },
      {
        "label": "河池市",
        "value": "河池市",
        "cityType": "地级市"
      },
      {
        "label": "来宾市",
        "value": "来宾市",
        "cityType": "地级市"
      },
      {
        "label": "贺州市",
        "value": "贺州市",
        "cityType": "地级市"
      },
      {
        "label": "岑溪市",
        "value": "岑溪市",
        "cityType": "县级市"
      },
      {
        "label": "桂平市",
        "value": "桂平市",
        "cityType": "县级市"
      },
      {
        "label": "北流市",
        "value": "北流市",
        "cityType": "县级市"
      },
      {
        "label": "东兴市",
        "value": "东兴市",
        "cityType": "县级市"
      },
      {
        "label": "凭祥市",
        "value": "凭祥市",
        "cityType": "县级市"
      },
      {
        "label": "合山市",
        "value": "合山市",
        "cityType": "县级市"
      },
      {
        "label": "靖西市",
        "value": "靖西市",
        "cityType": "县级市"
      },
      {
        "label": "平果市",
        "value": "平果市",
        "cityType": "县级市"
      },
      {
        "label": "荔浦市",
        "value": "荔浦市",
        "cityType": "县级市"
      }
    ]
  },
  {
    "label": "海南省",
    "value": "海南省",
    "children": [
      {
        "label": "海口市",
        "value": "海口市",
        "cityType": "地级市"
      },
      {
        "label": "三亚市",
        "value": "三亚市",
        "cityType": "地级市"
      },
      {
        "label": "三沙市",
        "value": "三沙市",
        "cityType": "地级市"
      },
      {
        "label": "儋州市",
        "value": "儋州市",
        "cityType": "地级市"
      },
      {
        "label": "文昌市",
        "value": "文昌市",
        "cityType": "县级市"
      },
      {
        "label": "琼海市",
        "value": "琼海市",
        "cityType": "县级市"
      },
      {
        "label": "万宁市",
        "value": "万宁市",
        "cityType": "县级市"
      },
      {
        "label": "东方市",
        "value": "东方市",
        "cityType": "县级市"
      },
      {
        "label": "五指山市",
        "value": "五指山市",
        "cityType": "县级市"
      }
    ]
  },
  {
    "label": "四川省",
    "value": "四川省",
    "children": [
      {
        "label": "成都市",
        "value": "成都市",
        "cityType": "地级市"
      },
      {
        "label": "广元市",
        "value": "广元市",
        "cityType": "县级市"
      },
      {
        "label": "绵阳市",
        "value": "绵阳市",
        "cityType": "县级市"
      },
      {
        "label": "德阳市",
        "value": "德阳市",
        "cityType": "县级市"
      },
      {
        "label": "南充市",
        "value": "南充市",
        "cityType": "县级市"
      },
      {
        "label": "广安市",
        "value": "广安市",
        "cityType": "县级市"
      },
      {
        "label": "遂宁市",
        "value": "遂宁市",
        "cityType": "县级市"
      },
      {
        "label": "内江市",
        "value": "内江市",
        "cityType": "县级市"
      },
      {
        "label": "乐山市",
        "value": "乐山市",
        "cityType": "县级市"
      },
      {
        "label": "自贡市",
        "value": "自贡市",
        "cityType": "县级市"
      },
      {
        "label": "泸州市",
        "value": "泸州市",
        "cityType": "县级市"
      },
      {
        "label": "宜宾市",
        "value": "宜宾市",
        "cityType": "县级市"
      },
      {
        "label": "攀枝花市",
        "value": "攀枝花市",
        "cityType": "县级市"
      },
      {
        "label": "巴中市",
        "value": "巴中市",
        "cityType": "县级市"
      },
      {
        "label": "达州市",
        "value": "达州市",
        "cityType": "县级市"
      },
      {
        "label": "资阳市",
        "value": "资阳市",
        "cityType": "县级市"
      },
      {
        "label": "眉山市",
        "value": "眉山市",
        "cityType": "县级市"
      },
      {
        "label": "雅安市",
        "value": "雅安市",
        "cityType": "县级市"
      },
      {
        "label": "崇州市",
        "value": "崇州市",
        "cityType": "县级市"
      },
      {
        "label": "邛崃市",
        "value": "邛崃市",
        "cityType": "县级市"
      },
      {
        "label": "都江堰市",
        "value": "都江堰市",
        "cityType": "县级市"
      },
      {
        "label": "彭州市",
        "value": "彭州市",
        "cityType": "县级市"
      },
      {
        "label": "江油市",
        "value": "江油市",
        "cityType": "县级市"
      },
      {
        "label": "什邡市",
        "value": "什邡市",
        "cityType": "县级市"
      },
      {
        "label": "广汉市",
        "value": "广汉市",
        "cityType": "县级市"
      },
      {
        "label": "绵竹市",
        "value": "绵竹市",
        "cityType": "县级市"
      },
      {
        "label": "阆中市",
        "value": "阆中市",
        "cityType": "县级市"
      },
      {
        "label": "华蓥市",
        "value": "华蓥市",
        "cityType": "县级市"
      },
      {
        "label": "峨眉山市",
        "value": "峨眉山市",
        "cityType": "县级市"
      },
      {
        "label": "万源市",
        "value": "万源市",
        "cityType": "县级市"
      },
      {
        "label": "简阳市",
        "value": "简阳市",
        "cityType": "县级市"
      },
      {
        "label": "西昌市",
        "value": "西昌市",
        "cityType": "县级市"
      },
      {
        "label": "康定市",
        "value": "康定市",
        "cityType": "县级市"
      },
      {
        "label": "马尔康市",
        "value": "马尔康市",
        "cityType": "县级市"
      },
      {
        "label": "隆昌市",
        "value": "隆昌市",
        "cityType": "县级市"
      },
      {
        "label": "射洪市",
        "value": "射洪市",
        "cityType": "县级市"
      },
      {
        "label": "会理市",
        "value": "会理市",
        "cityType": "县级市"
      }
    ]
  },
  {
    "label": "贵州省",
    "value": "贵州省",
    "children": [
      {
        "label": "贵阳市",
        "value": "贵阳市",
        "cityType": "地级市"
      },
      {
        "label": "六盘水市",
        "value": "六盘水市",
        "cityType": "地级市"
      },
      {
        "label": "遵义市",
        "value": "遵义市",
        "cityType": "地级市"
      },
      {
        "label": "安顺市",
        "value": "安顺市",
        "cityType": "地级市"
      },
      {
        "label": "毕节市",
        "value": "毕节市",
        "cityType": "地级市"
      },
      {
        "label": "铜仁市",
        "value": "铜仁市",
        "cityType": "地级市"
      },
      {
        "label": "清镇市",
        "value": "清镇市",
        "cityType": "县级市"
      },
      {
        "label": "赤水市",
        "value": "赤水市",
        "cityType": "县级市"
      },
      {
        "label": "仁怀市",
        "value": "仁怀市",
        "cityType": "县级市"
      },
      {
        "label": "凯里市",
        "value": "凯里市",
        "cityType": "县级市"
      },
      {
        "label": "都匀市",
        "value": "都匀市",
        "cityType": "县级市"
      },
      {
        "label": "兴义市",
        "value": "兴义市",
        "cityType": "县级市"
      },
      {
        "label": "福泉市",
        "value": "福泉市",
        "cityType": "县级市"
      },
      {
        "label": "盘州市",
        "value": "盘州市",
        "cityType": "县级市"
      },
      {
        "label": "兴仁市",
        "value": "兴仁市",
        "cityType": "县级市"
      }
    ]
  },
  {
    "label": "云南省",
    "value": "云南省",
    "children": [
      {
        "label": "昆明市",
        "value": "昆明市",
        "cityType": "地级市"
      },
      {
        "label": "曲靖市",
        "value": "曲靖市",
        "cityType": "地级市"
      },
      {
        "label": "玉溪市",
        "value": "玉溪市",
        "cityType": "地级市"
      },
      {
        "label": "丽江市",
        "value": "丽江市",
        "cityType": "地级市"
      },
      {
        "label": "昭通市",
        "value": "昭通市",
        "cityType": "地级市"
      },
      {
        "label": "普洱市",
        "value": "普洱市",
        "cityType": "地级市"
      },
      {
        "label": "临沧市",
        "value": "临沧市",
        "cityType": "地级市"
      },
      {
        "label": "保山市",
        "value": "保山市",
        "cityType": "地级市"
      },
      {
        "label": "安宁市",
        "value": "安宁市",
        "cityType": "县级市"
      },
      {
        "label": "宣威市",
        "value": "宣威市",
        "cityType": "县级市"
      },
      {
        "label": "芒市",
        "value": "芒市",
        "cityType": "县级市"
      },
      {
        "label": "瑞丽市",
        "value": "瑞丽市",
        "cityType": "县级市"
      },
      {
        "label": "大理市",
        "value": "大理市",
        "cityType": "县级市"
      },
      {
        "label": "楚雄市",
        "value": "楚雄市",
        "cityType": "县级市"
      },
      {
        "label": "个旧市",
        "value": "个旧市",
        "cityType": "县级市"
      },
      {
        "label": "开远市",
        "value": "开远市",
        "cityType": "县级市"
      },
      {
        "label": "蒙自市",
        "value": "蒙自市",
        "cityType": "县级市"
      },
      {
        "label": "弥勒市",
        "value": "弥勒市",
        "cityType": "县级市"
      },
      {
        "label": "景洪市",
        "value": "景洪市",
        "cityType": "县级市"
      },
      {
        "label": "文山市",
        "value": "文山市",
        "cityType": "县级市"
      },
      {
        "label": "香格里拉市",
        "value": "香格里拉市",
        "cityType": "县级市"
      },
      {
        "label": "腾冲市",
        "value": "腾冲市",
        "cityType": "县级市"
      },
      {
        "label": "水富市",
        "value": "水富市",
        "cityType": "县级市"
      },
      {
        "label": "澄江市",
        "value": "澄江市",
        "cityType": "县级市"
      },
      {
        "label": "泸水市",
        "value": "泸水市",
        "cityType": "县级市"
      }
    ]
  },
  {
    "label": "陕西省",
    "value": "陕西省",
    "children": [
      {
        "label": "西安市",
        "value": "西安市",
        "cityType": "地级市"
      },
      {
        "label": "延安市",
        "value": "延安市",
        "cityType": "县级市"
      },
      {
        "label": "铜川市",
        "value": "铜川市",
        "cityType": "县级市"
      },
      {
        "label": "渭南市",
        "value": "渭南市",
        "cityType": "县级市"
      },
      {
        "label": "咸阳市",
        "value": "咸阳市",
        "cityType": "县级市"
      },
      {
        "label": "宝鸡市",
        "value": "宝鸡市",
        "cityType": "县级市"
      },
      {
        "label": "汉中市",
        "value": "汉中市",
        "cityType": "县级市"
      },
      {
        "label": "榆林市",
        "value": "榆林市",
        "cityType": "县级市"
      },
      {
        "label": "商洛市",
        "value": "商洛市",
        "cityType": "县级市"
      },
      {
        "label": "安康市",
        "value": "安康市",
        "cityType": "县级市"
      },
      {
        "label": "韩城市",
        "value": "韩城市",
        "cityType": "县级市"
      },
      {
        "label": "华阴市",
        "value": "华阴市",
        "cityType": "县级市"
      },
      {
        "label": "兴平市",
        "value": "兴平市",
        "cityType": "县级市"
      },
      {
        "label": "彬州市",
        "value": "彬州市",
        "cityType": "县级市"
      },
      {
        "label": "神木市",
        "value": "神木市",
        "cityType": "县级市"
      },
      {
        "label": "子长市",
        "value": "子长市",
        "cityType": "县级市"
      }
    ]
  },
  {
    "label": "甘肃省",
    "value": "甘肃省",
    "children": [
      {
        "label": "兰州市",
        "value": "兰州市",
        "cityType": "地级市"
      },
      {
        "label": "嘉峪关市",
        "value": "嘉峪关市",
        "cityType": "地级市"
      },
      {
        "label": "金昌市",
        "value": "金昌市",
        "cityType": "地级市"
      },
      {
        "label": "白银市",
        "value": "白银市",
        "cityType": "地级市"
      },
      {
        "label": "天水市",
        "value": "天水市",
        "cityType": "地级市"
      },
      {
        "label": "酒泉市",
        "value": "酒泉市",
        "cityType": "地级市"
      },
      {
        "label": "张掖市",
        "value": "张掖市",
        "cityType": "地级市"
      },
      {
        "label": "武威市",
        "value": "武威市",
        "cityType": "地级市"
      },
      {
        "label": "庆阳市",
        "value": "庆阳市",
        "cityType": "地级市"
      },
      {
        "label": "平凉市",
        "value": "平凉市",
        "cityType": "地级市"
      },
      {
        "label": "定西市",
        "value": "定西市",
        "cityType": "地级市"
      },
      {
        "label": "陇南市",
        "value": "陇南市",
        "cityType": "地级市"
      },
      {
        "label": "玉门市",
        "value": "玉门市",
        "cityType": "县级市"
      },
      {
        "label": "敦煌市",
        "value": "敦煌市",
        "cityType": "县级市"
      },
      {
        "label": "临夏市",
        "value": "临夏市",
        "cityType": "县级市"
      },
      {
        "label": "合作市",
        "value": "合作市",
        "cityType": "县级市"
      },
      {
        "label": "华亭市",
        "value": "华亭市",
        "cityType": "县级市"
      }
    ]
  },
  {
    "label": "青海省",
    "value": "青海省",
    "children": [
      {
        "label": "西宁市",
        "value": "西宁市",
        "cityType": "地级市"
      },
      {
        "label": "海东市",
        "value": "海东市",
        "cityType": "地级市"
      },
      {
        "label": "格尔木市",
        "value": "格尔木市",
        "cityType": "县级市"
      },
      {
        "label": "德令哈市",
        "value": "德令哈市",
        "cityType": "县级市"
      },
      {
        "label": "玉树市",
        "value": "玉树市",
        "cityType": "县级市"
      },
      {
        "label": "茫崖市",
        "value": "茫崖市",
        "cityType": "县级市"
      }
    ]
  },
  {
    "label": "西藏自治区",
    "value": "西藏自治区",
    "children": [
      {
        "label": "拉萨市",
        "value": "拉萨市",
        "cityType": "地级市"
      },
      {
        "label": "日喀则市",
        "value": "日喀则市",
        "cityType": "地级市"
      },
      {
        "label": "昌都市",
        "value": "昌都市",
        "cityType": "地级市"
      },
      {
        "label": "林芝市",
        "value": "林芝市",
        "cityType": "地级市"
      },
      {
        "label": "山南市",
        "value": "山南市",
        "cityType": "地级市"
      },
      {
        "label": "那曲市",
        "value": "那曲市",
        "cityType": "地级市"
      }
    ]
  },
  {
    "label": "宁夏回族自治区",
    "value": "宁夏回族自治区",
    "children": [
      {
        "label": "银川市",
        "value": "银川市",
        "cityType": "地级市"
      },
      {
        "label": "石嘴山市",
        "value": "石嘴山市",
        "cityType": "地级市"
      },
      {
        "label": "吴忠市",
        "value": "吴忠市",
        "cityType": "地级市"
      },
      {
        "label": "中卫市",
        "value": "中卫市",
        "cityType": "地级市"
      },
      {
        "label": "固原市",
        "value": "固原市",
        "cityType": "地级市"
      },
      {
        "label": "灵武市",
        "value": "灵武市",
        "cityType": "县级市"
      },
      {
        "label": "青铜峡市",
        "value": "青铜峡市",
        "cityType": "县级市"
      }
    ]
  },
  {
    "label": "新疆维吾尔自治区",
    "value": "新疆维吾尔自治区",
    "children": [
      {
        "label": "乌鲁木齐市",
        "value": "乌鲁木齐市",
        "cityType": "地级市"
      },
      {
        "label": "克拉玛依市",
        "value": "克拉玛依市",
        "cityType": "地级市"
      },
      {
        "label": "吐鲁番市",
        "value": "吐鲁番市",
        "cityType": "地级市"
      },
      {
        "label": "哈密市",
        "value": "哈密市",
        "cityType": "地级市"
      },
      {
        "label": "喀什市",
        "value": "喀什市",
        "cityType": "县级市"
      },
      {
        "label": "阿克苏市",
        "value": "阿克苏市",
        "cityType": "县级市"
      },
      {
        "label": "库车市",
        "value": "库车市",
        "cityType": "县级市"
      },
      {
        "label": "和田市",
        "value": "和田市",
        "cityType": "县级市"
      },
      {
        "label": "阿图什市",
        "value": "阿图什市",
        "cityType": "县级市"
      },
      {
        "label": "阿拉山口市",
        "value": "阿拉山口市",
        "cityType": "县级市"
      },
      {
        "label": "博乐市",
        "value": "博乐市",
        "cityType": "县级市"
      },
      {
        "label": "昌吉市",
        "value": "昌吉市",
        "cityType": "县级市"
      },
      {
        "label": "阜康市",
        "value": "阜康市",
        "cityType": "县级市"
      },
      {
        "label": "库尔勒市",
        "value": "库尔勒市",
        "cityType": "县级市"
      },
      {
        "label": "伊宁市",
        "value": "伊宁市",
        "cityType": "县级市"
      },
      {
        "label": "奎屯市",
        "value": "奎屯市",
        "cityType": "县级市"
      },
      {
        "label": "塔城市",
        "value": "塔城市",
        "cityType": "县级市"
      },
      {
        "label": "乌苏市",
        "value": "乌苏市",
        "cityType": "县级市"
      },
      {
        "label": "阿勒泰市",
        "value": "阿勒泰市",
        "cityType": "县级市"
      },
      {
        "label": "霍尔果斯市",
        "value": "霍尔果斯市",
        "cityType": "县级市"
      },
      {
        "label": "石河子市",
        "value": "石河子市",
        "cityType": "县级市"
      },
      {
        "label": "阿拉尔市",
        "value": "阿拉尔市",
        "cityType": "县级市"
      },
      {
        "label": "图木舒克市",
        "value": "图木舒克市",
        "cityType": "县级市"
      },
      {
        "label": "五家渠市",
        "value": "五家渠市",
        "cityType": "县级市"
      },
      {
        "label": "北屯市",
        "value": "北屯市",
        "cityType": "县级市"
      },
      {
        "label": "铁门关市",
        "value": "铁门关市",
        "cityType": "县级市"
      },
      {
        "label": "双河市",
        "value": "双河市",
        "cityType": "县级市"
      },
      {
        "label": "可克达拉市",
        "value": "可克达拉市",
        "cityType": "县级市"
      },
      {
        "label": "昆玉市",
        "value": "昆玉市",
        "cityType": "县级市"
      },
      {
        "label": "胡杨河市",
        "value": "胡杨河市",
        "cityType": "县级市"
      }
    ]
  },
  {
    "children": [
      {
        "label": "台北市",
        "value": "台北市",
        "cityType": "地级市"
      },
      {
        "label": "新北市",
        "value": "新北市",
        "cityType": "地级市"
      },
      {
        "label": "桃园市",
        "value": "桃园市",
        "cityType": "地级市"
      },
      {
        "label": "台中市",
        "value": "台中市",
        "cityType": "地级市"
      },
      {
        "label": "台南市",
        "value": "台南市",
        "cityType": "地级市"
      },
      {
        "label": "高雄市",
        "value": "高雄市",
        "cityType": "地级市"
      },
      {
        "label": "基隆市",
        "value": "基隆市",
        "cityType": "县级市"
      },
      {
        "label": "新竹市",
        "value": "新竹市",
        "cityType": "县级市"
      },
      {
        "label": "嘉义市",
        "value": "嘉义市",
        "cityType": "县级市"
      }
    ],
    "label": "台湾",
    "value": "台湾"
  }
]


export default data;